import React from "react";
import { VMProvider } from "../../../commons/base/providers/provider";
import AdjustmentFormsSoViewModel from "./view_models/adjustment_forms_so_view_model";
import Scaffold from "../../../commons/scaffold/scaffold";
import AdjustmentFormsSoTableWidget from "./widgets/adjustment_forms_so_table_widget";
import AdjustmentFormsFilterWidget from "./widgets/adjustment_forms_so_filter_widget";
import AdjustmentFormsSOModalWidget from "./widgets/adjustment_forms_so_modal_widget";

export default class AdjustmentFormsSoScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new AdjustmentFormsSoViewModel()}>
                {(vm) => (
                    <Scaffold
                        isShowLoading={vm.isLoading}
                        loadingBackgroundType="semi_transparent"
                        showErrorView={vm.showErrorView}
                        showSnackbar={vm.showSnackbar}
                    >
                        <div className=" bg-slate-500 overflow-x-auto min-h-screen p-5 mt-10 relative">
                            <h1 className="text-white text-3xl text-center font-bold flex-grow m-5">
                                Adjust Form SO
                            </h1>
                            <AdjustmentFormsFilterWidget />
                            {vm.isShowModal && <AdjustmentFormsSOModalWidget />}
                            <AdjustmentFormsSoTableWidget />
                        </div>
                    </Scaffold>
                )}
            </VMProvider>
        );
    }
}
