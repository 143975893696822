export enum MenuCategory {
    CHILI_OIL = "CHILI OIL",
    B1G1 = "B1G1",
    GEPREK = "GEPREK",
    MAIN_COURSE = "MAIN",
}

export const soldOutColors: {
    [key in MenuCategory]: { backgroundColor: string; textColor: string };
} = {
    [MenuCategory.CHILI_OIL]: {
        backgroundColor: "#FF6961",
        textColor: "#FFFFFF",
    },
    [MenuCategory.B1G1]: {
        backgroundColor: "#FFA500",
        textColor: "#000000",
    },
    [MenuCategory.GEPREK]: {
        backgroundColor: "#FDFD96",
        textColor: "#000000",
    },
    [MenuCategory.MAIN_COURSE]: {
        backgroundColor: "#77DD77",
        textColor: "#000000",
    },
};
