import React from "react";
import { VMBuilder } from "../../commons/base/providers/builder";
import AllOutletStockDashboardVM from '../view_models/all_outlet_stock_dashboard_vm';

export default class OutletStockDisplay extends React.Component {

    renderTables(vm: AllOutletStockDashboardVM) {
        return vm.filteredData.map((data) => {
            const tableHeader = (
                <div className="table-header-group bg-slate-700 text-lg font-bold text-slate-50">
                    <div className="table-row">
                        <div className="table-cell">#</div>
                        <div className="table-cell">Mat. Code</div>
                        <div className="table-cell">Name</div>
                        <div className="table-cell">Qty</div>
                    </div>
                </div>
            );
            
            const tableBody = data.items
            .sort((A, B) => A.matCode ? A.matCode.localeCompare(B.matCode) : -1)
            .map((item, i) => (
                <div className="table-row-group">
                    <div className={"table-row" + (i%2!==0?" bg-gray-100":"")}>
                        <div className="table-cell p-1">{i+1}</div>
                        <div className="table-cell">{item.matCode}</div>
                        <div className="table-cell">{item.name}</div>
                        <div className="table-cell">{item.qty}</div>
                    </div>
                </div>
            ));

            const table = (
                <div className="bg-slate-50 mx-12 my-8 drop-shadow-xl shadow-black">
                    <h2 className="py-4 bg-[#6B2B90] text-lg text-white font-bold">{data.location.name}</h2>
                    <div className="table w-full">
                        {tableHeader}
                        {tableBody}
                    </div>
                </div>
            );
            return table;
        });
    }

    render(): React.ReactNode {
        return(
        <VMBuilder<AllOutletStockDashboardVM>>
            {(vm) => (
            <div className="grid grid-cols-2 xl:grid-cols-3 px-6">
                {this.renderTables(vm)}
            </div>
            )}
        </VMBuilder>
        );
    }
}