import React from "react";

interface NavigateButtonProps {
    title: string;
    path: string;
}

export default class NavigateButton extends React.Component<NavigateButtonProps> {
    render() {
        return (
            <button
                className="bg-purple-400 hover:bg-purple-500 text-white font-bold p-12 rounded-lg shadow-md transition duration-300 ease-in-out"
                onClick={() => (window.location.href = this.props.path)}
            >
                {this.props.title}
            </button>
        );
    }
}
