import React from "react";
import { VMProvider } from "../../commons/base/providers/provider";
import Scaffold from "../../commons/scaffold/scaffold";
import MasterItemDataTableWidget from "./widgets/master_item_data_table";
import MasterItemDashboardPaginationWidget from "./widgets/master_item_pagination";
import MasterItemDashboardViewModel from "./view_models/master_item_dashboard_view_model";
import MasterItemModalWidget from "./widgets/master_item_modal";
import MasterItemSearchInputWidget from "./widgets/master_item_search_widget";

export default class MasterItemDashboardScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new MasterItemDashboardViewModel()}>
                {(vm) => (
                    <Scaffold
                        isShowLoading={vm.isLoading}
                        loadingBackgroundType="semi_transparent"
                        showErrorView={vm.showErrorView}
                        showSnackbar={vm.showSnackbar}
                    >
                        <div className=" bg-slate-500 overflow-x-auto min-h-screen p-5 mt-10 relative">
                            <h1 className="text-white text-3xl text-center font-bold flex-grow m-5">
                                Master Item
                            </h1>

                            <MasterItemSearchInputWidget />
                            {vm.isShowModal && <MasterItemModalWidget />}
                            <MasterItemDataTableWidget />
                            <MasterItemDashboardPaginationWidget />

                            {/* Create New Button */}
                            <div className="fixed bottom-4 right-4">
                                <button
                                    className="px-4 py-4 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                    onClick={() => {
                                        vm.openModal();
                                        vm.setModalIsActive(true);
                                    }}
                                >
                                    Create New
                                </button>
                            </div>
                        </div>
                    </Scaffold>
                )}
            </VMProvider>
        );
    }
}
