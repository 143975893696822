import React from "react";
import Scaffold from "../../commons/scaffold/scaffold";
import UploadMapWeeklyCsvViewModel from "./view_models/upload_map_csv_view_model";
import { VMProvider } from "../../commons/base/providers/provider";
import UploadMapCsvWidget from "./widgets/upload_map_csv_widget";

export default class UploadMapCsvScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new UploadMapWeeklyCsvViewModel()}>
                {(vm) => (
                    <Scaffold
                        isShowLoading={vm.isLoading}
                        loadingBackgroundType="semi_transparent"
                        showErrorView={vm.showErrorView}
                        showSnackbar={vm.showSnackbar}
                    >
                        <UploadMapCsvWidget />
                    </Scaffold>
                )}
            </VMProvider>
        )
    }
}
