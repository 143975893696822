import ApiService from "../../commons/api/api_service";
import { Endpoints } from "../../commons/api/endpoints";
import ViewModel from "../../commons/base/view_model";
import { ShowSnackbarModel } from "../../commons/scaffold/scaffold";
import CoverageOutletsResponse from "../models/coverage_outlets_response";
import ServiceAreaMapState from "./service_areas_map_state";
import PointInPolygon from "point-in-polygon";
import LocationUtils from "../../commons/utils/location_utils";
import MapsLinkToCoordinateRequest from "../models/maps_link_to_coordinate_request";
import MapsLinkToCoordinateResponse from "../models/maps_link_to_coordinate_response";

interface CoordinateModel {
    longitude: number | string;
    latitude: number | string;
}

export default class ServiceAreaMapViewModel extends ViewModel<ServiceAreaMapState> {
    constructor() {
        super(new ServiceAreaMapState());

        this.loadPolygons();
    }

    loadPolygons() {
        this.emit((state) => state.isLoading = true);

        ApiService.fetch<{}, CoverageOutletsResponse>(Endpoints.coverageAreaPolygons, {
            onSuccess: (response) => {
                this.emit((state) => {
                    state.outlets = response.outlets;
                    state.isLoading = false;
                });
            },
            onFailure: (_) => {
                this.emit((state) => {
                    state.isLoading = false;
                    state.errorSnackbar = new ShowSnackbarModel({
                        message: "Terjadi kesalahan, silahkan muat kembali halaman ini untuk mencoba lagi.",
                        type: "error"
                    });
                });
            },
        });
    }

    onAnyTextChanged(args: {
        gmapsLinkEvent?: React.ChangeEvent<HTMLInputElement>,
        longitudeEvent?: React.ChangeEvent<HTMLInputElement>,
        latitudeEvent?: React.ChangeEvent<HTMLInputElement>
    }) {
        this.emit((state) => {
            state.nearestLocation = undefined;
            state.coordinate = undefined;

            if (args.gmapsLinkEvent?.target.value) {
                state.gmapLink = args.gmapsLinkEvent?.target.value ?? state.gmapLink;
                state.longitude = "";
                state.latitude = "";
            } else {
                state.gmapLink = "";
                state.longitude = args.longitudeEvent?.target.value ?? state.longitude;
                state.latitude = args.latitudeEvent?.target.value ?? state.latitude;
            }

        });
    }

    async checkGmapLink() {
        this.emit((state) => {
            state.errorSnackbar = undefined;
            state.isCheckGmapLinkLoading = true;
        });

        this.getCoordinate({
            url: this.state.gmapLink,
            onDone: (coordinate) => this.checkCoordinate(coordinate)
        });
    }

    checkCoordinate(coordinate: CoordinateModel) {
        const latitudeString = coordinate.latitude.toString();
        const longitudeString = coordinate.longitude.toString();

        if (isNaN(+latitudeString) || isNaN(+longitudeString)) {
            return this.emit((state) => {
                state.isCheckGmapLinkLoading = false;
                state.isCoordinateCheckLoading = false;
                state.errorSnackbar = new ShowSnackbarModel({
                    message: "Mohon cek kembali koordinat yang di masukkan, ada kesalahan di format longitude latitude."
                });
            });
        }

        let nearestOutletName = "";
        let nearestOutletDistance: number | undefined;
        let isOnCoverage = false;

        for (const outlet of this.state.outlets) {
            const distance = LocationUtils.calculateDistance({
                lat1: outlet.coordinate.latitude,
                lat2: +coordinate.latitude,
                long1: outlet.coordinate.longitude,
                long2: +coordinate.longitude
            });
            const outletPolygon = LocationUtils.convertToCoordinate(outlet.polygon);
            const isOnServiceArea = PointInPolygon(
                [+coordinate.longitude, +coordinate.latitude],
                outletPolygon.map((coordinate) => [coordinate.lng, coordinate.lat])
            );

            if (isOnServiceArea) {
                nearestOutletName = outlet.name;
                nearestOutletDistance = distance;
                isOnCoverage = true;
                break;
            }

            if (!nearestOutletDistance || nearestOutletDistance > distance) {
                nearestOutletName = outlet.name;
                nearestOutletDistance = distance;
            }
        }

        this.emit((state) => {
            state.isCheckGmapLinkLoading = false;
            state.isCoordinateCheckLoading = false;
            state.nearestLocation = {
                name: nearestOutletName,
                distance: nearestOutletDistance ?? 999999999,
                isOnCoverage: isOnCoverage
            };
            state.coordinate = {
                longitude: +coordinate.longitude,
                latitude: +coordinate.latitude
            };
        });
    }

    private getCoordinate(args: {
        url: string,
        onDone: (coordinate: CoordinateModel) => void
    }) {
        let urlString = `${args.url}`;

        if (urlString.includes("maps.google.com/?q=")) {
            urlString = urlString.replace("https://", "");
            urlString = urlString.replace("maps.google.com/?q=", "");

            const coordinateStrings = urlString.split(",");

            return {
                longitude: +coordinateStrings[0],
                latitude: +coordinateStrings[1]
            }
        } else {
            ApiService.fetch<MapsLinkToCoordinateRequest, MapsLinkToCoordinateResponse>(
                Endpoints.coverageAreaMapsLinkToCoordinate, {
                parameters: {
                    url: args.url
                },
                onSuccess: (response) => {
                    args.onDone(response);
                },
                onFailure: (_) => {
                    return this.emit((state) => {
                        state.isCheckGmapLinkLoading = false;
                        state.isCoordinateCheckLoading = false;
                        state.errorSnackbar = new ShowSnackbarModel({
                            message: "Terjadi masalah saat mendapatkan koordinat lokasi"
                        });
                    });
                }
            }
            );
        }
    }
}