import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import VendorReceiptHistoryVM from "../view_models/vendor_receipt_history_vm";
import StringUtils from "../../../commons/utils/string_utils";

export default class VendorReceiptHistoryDetail extends React.Component {
    tableDetail(vm: VendorReceiptHistoryVM) {
        const form = vm.selectedForm!;
        if (form) {
            const receiveDate = new Date(form.received_at);
            const formattedDate = StringUtils.toIndonesianDate(receiveDate);
            return (
                <Grid container gap={5}>
                    <Grid>
                        <Grid>
                            <p className="text-slate-500">Vendor Pengirim: </p>
                        </Grid>
                        <Grid>
                            <p>{form.vendor}</p>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid>
                            <p className="text-slate-500">Lokasi Penerima: </p>
                        </Grid>
                        <Grid>
                            <p>{form.location_received}</p>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid>
                            <p className="text-slate-500">Waktu Penerimaan: </p>
                        </Grid>
                        <Grid>{formattedDate}</Grid>
                    </Grid>
                </Grid>
            );
        }
    }

    tableItems(vm: VendorReceiptHistoryVM) {
        const items = vm.formItems;
        if (items) {
            return items.map((item, index) => {
                return (
                    <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.item_name}</TableCell>
                        <TableCell>
                            {item.big_qty} {item.big_unit}
                        </TableCell>
                        <TableCell>
                            {item.small_qty} {item.small_unit}
                        </TableCell>
                        <TableCell>
                            {item.total_qty} {item.small_unit}
                        </TableCell>
                    </TableRow>
                );
            });
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={6}>Data tidak tersedia</TableCell>
                </TableRow>
            );
        }
    }

    handleClose(vm: VendorReceiptHistoryVM) {
        vm.emit(() => {
            vm.isShowModal = false;
        });
    }

    render(): React.ReactNode {
        return (
            <VMBuilder<VendorReceiptHistoryVM>>
                {(vm) => {
                    return (
                        <Dialog open={vm.isShowModal}>
                            <DialogTitle>Detail Form Penerimaan</DialogTitle>
                            <DialogContent>
                                {this.tableDetail(vm)}
                                <Divider className="p-3" />
                                <Table>
                                    <TableHead className="bg-slate-100">
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Nama Item</TableCell>
                                            <TableCell>Big Qty</TableCell>
                                            <TableCell>Small Qty</TableCell>
                                            <TableCell>Total Qty</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{this.tableItems(vm)}</TableBody>
                                </Table>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.handleClose(vm)}
                                    style={{
                                        backgroundColor: "#8a8a8a",
                                        color: "#EAEAEA",
                                        fontWeight: 700,
                                        margin: 5,
                                    }}
                                >
                                    Tutup
                                </Button>
                            </DialogActions>
                        </Dialog>
                    );
                }}
            </VMBuilder>
        );
    }
}
