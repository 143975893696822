import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import TransferHistoryViewModel from "../view_models/transfer_history_view_model";

export default class TransferHistoryDetailModalWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<TransferHistoryViewModel>>
                {(vm) => (
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
                        <div className="bg-white p-5 rounded-lg max-h-[80%] w-10/12 overflow-auto relative">
                            <span
                                className="absolute top-0 right-2 cursor-pointer text-red-500 font-semibold text-3xl"
                                onClick={() => vm.closeDetail()}
                            >
                                &#10007;
                            </span>
                            <h1 className="text-2xl font-bold mb-5">
                                Detail Transfer "{vm.selectedDetail?.code}"
                            </h1>
                            {vm.isLoadingDetail ? (
                                <div className="text-center">Loading...</div>
                            ) : (
                                <div className="overflow-y-auto mb-2">
                                    <table className="min-w-full overflow-auto text-left">
                                        <tbody>
                                            <tr>
                                                <td className="font-bold">
                                                    Nama Pengirim
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    {
                                                        vm.selectedDetail
                                                            ?.sender_name
                                                    }
                                                </td>
                                                <td className="font-bold text-left">
                                                    Lokasi Tujuan
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    {
                                                        vm.selectedDetail
                                                            ?.to_location_name
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-bold">
                                                    Waktu Pengiriman
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    {new Date(
                                                        vm.selectedDetail
                                                            ?.created_at || ""
                                                    ).toLocaleDateString([], {
                                                        hour12: false,
                                                    })}{" "}
                                                    {new Date(
                                                        vm.selectedDetail
                                                            ?.created_at || ""
                                                    ).toLocaleTimeString([], {
                                                        hour12: false,
                                                    })}
                                                </td>
                                                <td className="font-bold text-left">
                                                    Nama Penerima
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    {vm.selectedDetail
                                                        ?.receiver_name ?? "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-bold">
                                                    Lokasi Asal
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    {
                                                        vm.selectedDetail
                                                            ?.from_location_name
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="text-black border-separate border border-slate-500 w-full">
                                        <thead>
                                            <tr>
                                                <th className="border border-slate-600 p-2">
                                                    Item
                                                </th>
                                                <th className="border border-slate-600 p-2">
                                                    Small Qty
                                                </th>
                                                <th className="border border-slate-600 p-2">
                                                    Big Qty
                                                </th>
                                                <th className="border border-slate-600 p-2">
                                                    Total
                                                </th>
                                                <th className="border border-slate-600 p-2">
                                                    Ada Revisi?
                                                </th>
                                                <th className="border border-slate-600 p-2">
                                                    Small Qty
                                                </th>
                                                <th className="border border-slate-600 p-2">
                                                    Big Qty
                                                </th>
                                                <th className="border border-slate-600 p-2">
                                                    Total
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vm.itemsWithFixItems.map(
                                                (item) => (
                                                    <tr key={item?.id}>
                                                        <td className="border border-slate-700 p-2">
                                                            {item?.item}
                                                        </td>
                                                        <td className="border border-slate-700 p-2">
                                                            {
                                                                item?.qty_small_unit
                                                            }{" "}
                                                            {item?.small_unit}
                                                        </td>
                                                        <td className="border border-slate-700 p-2">
                                                            {item?.qty_big_unit}{" "}
                                                            {item?.big_unit}
                                                        </td>
                                                        <td className="border border-slate-700 p-2">
                                                            {item?.quantity}{" "}
                                                            {item?.unit}
                                                        </td>
                                                        <th className="border border-slate-600 p-2">
                                                            {item?.fixItem ? (
                                                                <span className="text-green-500 font-semibold">
                                                                    &#10003;
                                                                </span>
                                                            ) : (
                                                                <span className="text-red-500 font-semibold">
                                                                    &#10007;
                                                                </span>
                                                            )}
                                                        </th>
                                                        <th className="border border-slate-600 p-2 text-red-500">
                                                            {item?.fixItem
                                                                ? `${item?.fixItem?.qty_small_unit} ${item?.small_unit}`
                                                                : ""}
                                                        </th>
                                                        <th className="border border-slate-600 p-2 text-red-500">
                                                            {item?.fixItem
                                                                ? `${item?.fixItem?.qty_big_unit} ${item?.big_unit}`
                                                                : ""}
                                                        </th>
                                                        <th className="border border-slate-600 p-2 text-red-500">
                                                            {item?.fixItem &&
                                                                `${item?.fixItem.quantity} ${item.unit}`}
                                                        </th>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
