import LabelAndIdModel from "../../../commons/models/LabelIdModel";
import { ShowErrorViewModel, ShowSnackbarModel } from "../../../commons/scaffold/scaffold";
import StringUtils from "../../../commons/utils/string_utils";
import OrderFormContentModel from "../../models/order_form_content_model";
import OrderFormProductModel from "../../models/order_form_product_model";
import OrderFormValidationResponse from "../../models/responses/order_form_validation_response";

export type GerobakOrderQuantityAndPriceModel = {
    quantity: number,
    model: OrderFormProductModel
}

export type GerobakOrderPopUpModel = {
    action: () => void
}

export default class GerobakOrderState {
    gerobakId: string;
    isLoading: boolean = true;
    isResetLoading: boolean = false;
    isVoucherButtonLoading: boolean = false;
    isSubmitButtonLoading: boolean = false;

    contentModel?: OrderFormContentModel;
    orderVerificationResponse?: OrderFormValidationResponse;

    selectedPaymentMethodId?: string;
    isPaymentMethodError: boolean = true;

    name: string = "";

    orderNote: string = "";

    selectedFilter: boolean[] = [];

    refreshId: number = new Date().getTime();

    get isVoucherCodeRequired(): boolean {
        return this.productIdsThatRequireVoucher.length > 0;
    };

    // Snackbar
    showSnackbarModel?: ShowSnackbarModel;

    // Error View
    showErrorViewModel?: ShowErrorViewModel;

    // PopUp
    popUpModel?: GerobakOrderPopUpModel;

    productAndQuantity: Map<string, GerobakOrderQuantityAndPriceModel> = new Map();
    productIdsThatRequireVoucher: string[] = [];

    get selectedPaymentMethod(): (LabelAndIdModel & { isPaid?: boolean | undefined }) | undefined {
        return this.contentModel?.paymentMethods.find((value) => { return value.id === this.selectedPaymentMethodId });
    }

    get totalPurchase(): string {
        let totalAmount = 0;
        let totalItems = 0;

        this.productAndQuantity.forEach((item) => {
            totalAmount += item.model.price * item.quantity;
            totalItems += item.quantity;
        });

        return `${StringUtils.getCurrencyStringFormat(totalAmount)} (${totalItems} Items)`;
    }

    get productList(): GerobakOrderQuantityAndPriceModel[] {
        let result: GerobakOrderQuantityAndPriceModel[] = [];

        this.productAndQuantity.forEach((value) => result.push(value));

        return result;
    }

    constructor(args: {
        gerobakId: string
    }) {
        this.gerobakId = args.gerobakId;
    }
}