import Scaffold from "../commons/scaffold/scaffold";

import "./completion_order.css";
import ViewController from "../commons/base/view_controller";
import CompletionViewModel, { CompletionState } from "./view_models/completion_view_model";

export default class CompletionOrderScreen extends ViewController<{}, CompletionState, CompletionViewModel> {
    constructor(props: {}) {
        super(props, new CompletionViewModel());
    }

    render() {
        return <Scaffold isShowLoading={(this.vmState.response?.data.length ?? 0) === 0} showSnackbar={this.vmState.errorSnackbar}>
            <div id="CompletionOrderScreen">
                <table>
                    <tr>
                        <th>Outlet Name</th>
                        <th>Kitchen</th>
                        <th>Max Cooking Time</th>
                        <th>Driver</th>
                        <th>To Customer</th>
                        <th>Max Time</th>
                    </tr>
                    {
                        this.vmState.response?.data?.map((model) => <tr>
                            <td>{model.outlet_name}</td>
                            <td>{model.kitchen_record_qty}</td>
                            <td>{model.max_cooking_time}</td>
                            <td>{model.driver_record_qty}</td>
                            <td>{model.to_customer}</td>
                            <td>{model.max_time}</td>
                        </tr>)
                    }
                </table>
            </div>
        </Scaffold>;
    }
}