import { ShowSnackbarModel } from "../../commons/scaffold/scaffold";
import NearestOutletServiceModel from "../models/nearest_outlet_service_model";
import OutletDetailModel from "../models/outlet_detail_model";

export default class ServiceAreaMapState {
    isLoading: boolean = true;
    isCheckGmapLinkLoading: boolean = false;
    isCoordinateCheckLoading: boolean = false;
    outlets: OutletDetailModel[] = [];

    coordinate?: { longitude: number; latitude: number };
    errorSnackbar?: ShowSnackbarModel;
    gmapLink: string = "";
    longitude: string = "";
    latitude: string = "";
    nearestLocation?: NearestOutletServiceModel;

    public get polygons(): string[] {
        return this.outlets.map((outlet) => outlet.polygon);
    }
}

// Outlet Models Mock
/**
    [
        {
            name: "Grogol",
            polygon: "106.7945046 -6.1491395, 106.7946426 -6.1511476, 106.7951146 -6.1521823, 106.7953667 -6.1524836, 106.7953184 -6.1528703, 106.7950395 -6.153185, 106.7942564 -6.1538571, 106.7930118 -6.1567478, 106.7895211 -6.1641212, 106.7883195 -6.1671932, 106.7884482 -6.1700092, 106.7898215 -6.1725266, 106.7937697 -6.1760679, 106.7964734 -6.1790972, 106.8085004 -6.1713479, 106.8082322 -6.1648465, 106.8080712 -6.157449, 106.8080122 -6.1552596, 106.807921 -6.1543369, 106.8077816 -6.1533263, 106.8071539 -6.147582, 106.7945046 -6.1491395",
            coordinate: {
                latitude: -6.162353322845055,
                longitude: 106.79423739798156
            }
        },
        {
            name: "Tanjung Duren",
            polygon: "106.7731449 -6.1627599, 106.7724368 -6.1647173, 106.7715463 -6.1643333, 106.7710099 -6.1652826, 106.7673835 -6.1652719, 106.7673836 -6.1697626, 106.7673192 -6.1774159, 106.7668257 -6.1775599, 106.7669544 -6.1784559, 106.7670403 -6.1801732, 106.7675981 -6.1824345, 106.7725548 -6.1853358, 106.7752907 -6.1859225, 106.7779622 -6.1861891, 106.7797646 -6.1858477, 106.7837128 -6.1849944, 106.7887393 -6.1847918, 106.7897961 -6.1844291, 106.7908582 -6.1840345, 106.7920384 -6.1833945, 106.7924783 -6.1819225, 106.7931757 -6.1801732, 106.7931864 -6.1793732, 106.7928967 -6.1786586, 106.793755 -6.1772719, 106.7918667 -6.1754373, 106.7887768 -6.1721093, 106.7881438 -6.1707866, 106.7878327 -6.1694639, 106.7875818 -6.1672226, 106.7874632 -6.1669793, 106.7869127 -6.1668453, 106.7867898 -6.1667593, 106.7861868 -6.1666119, 106.7855065 -6.1662133, 106.783985 -6.1656026, 106.7792496 -6.1650266, 106.7731449 -6.1627599",
            coordinate: {
                latitude: -6.17335759514,
                longitude: 106.78354552681752
            }
        },
        {
            name: "Kemanggisan",
            polygon: "106.7705961 -6.2086731, 106.7738577 -6.2088438, 106.7772909 -6.2092704, 106.7802092 -6.2098677, 106.785874 -6.2115743, 106.7888781 -6.2130248, 106.7929979 -6.2161819, 106.7943712 -6.2149873, 106.7957445 -6.2137074, 106.7959162 -6.2125129, 106.7955729 -6.2108916, 106.7962595 -6.2090998, 106.7972895 -6.2068813, 106.7985769 -6.2049187, 106.8002936 -6.2039801, 106.7994352 -6.2019322, 106.7991617 -6.2011269, 106.7987352 -6.2004656, 106.798459 -6.1999483, 106.7981371 -6.1993724, 106.7979788 -6.1986898, 106.7973485 -6.1989031, 106.7969918 -6.1979698, 106.7963025 -6.1980551, 106.7955461 -6.1971139, 106.7957311 -6.1960779, 106.795019 -6.1952293, 106.7937704 -6.1949566, 106.7933198 -6.194242, 106.7930248 -6.1941886, 106.7921718 -6.1941993, 106.7922308 -6.1933567, 106.791968 -6.19261, 106.7919626 -6.191826, 106.7911955 -6.19189, 106.7906537 -6.1918661, 106.7906349 -6.1912354, 106.7902071 -6.1911654, 106.7892475 -6.1911198, 106.7894866 -6.1903077, 106.7896183 -6.1896395, 106.7892697 -6.1890982, 106.7888808 -6.1876222, 106.7888794 -6.1866709, 106.7888137 -6.1858049, 106.7888137 -6.1852342, 106.7874619 -6.1852609, 106.7852732 -6.1853729, 106.7834707 -6.1855489, 106.7802092 -6.1864023, 106.7770334 -6.1873409, 106.7737719 -6.1887062, 106.7708536 -6.1895595, 106.7689654 -6.1901568, 106.7688795 -6.1936553, 106.7687937 -6.1963005, 106.7688795 -6.1996284, 106.7692228 -6.2021029, 106.7693087 -6.2049187, 106.7697378 -6.2070519, 106.7705961 -6.2086731",
            coordinate: {
                latitude: -6.200483002712273,
                longitude: 106.78333096861384
            }
        },
        {
            name: "Jelambar",
            polygon: "106.7933016 -6.1432286, 106.7913906 -6.1432606, 106.7896552 -6.1434473, 106.7863454 -6.1438046, 106.7823274 -6.1441192, 106.7788889 -6.1443912, 106.7772044 -6.1445832, 106.7758848 -6.1453726, 106.7751445 -6.146002, 106.774533 -6.147058, 106.7712285 -6.1530849, 106.7695548 -6.155821, 106.7688252 -6.1571464, 106.7684818 -6.157745, 106.768278 -6.1580551, 106.7687608 -6.1584931, 106.7691792 -6.1592718, 106.7694474 -6.1601571, 106.7697156 -6.1606798, 106.7699624 -6.1611491, 106.7748333 -6.1630585, 106.7767431 -6.1637519, 106.7786528 -6.1645092, 106.7793663 -6.1647812, 106.7808415 -6.1647653, 106.7827727 -6.1650746, 106.7841245 -6.1653679, 106.7852081 -6.1656293, 106.7864097 -6.1663333, 106.7875524 -6.1665466, 106.7878984 -6.1664293, 106.7880191 -6.1660773, 106.7893119 -6.1635546, 106.7926459 -6.1560424, 106.7932124 -6.1546991, 106.7939882 -6.1537131, 106.7950278 -6.1528413, 106.7942652 -6.1510736, 106.7933016 -6.1432286",
            coordinate: {
                latitude: -6.153274310959529,
                longitude: 106.78939227462848
            }
        },
        {
            name: "Kelapa Gading",
            polygon: "106.8886699 -6.1511973, 106.8873824 -6.1536721, 106.8853225 -6.1570855, 106.8833484 -6.1604989, 106.881546 -6.163571, 106.8806018 -6.1647656, 106.8807735 -6.1662163, 106.8836059 -6.1680083, 106.88661 -6.169971, 106.8900432 -6.171763, 106.8926181 -6.173555, 106.8941631 -6.1750057, 106.8950214 -6.1762857, 106.8969096 -6.177907, 106.900257 -6.1782483, 106.902832 -6.1785896, 106.905836 -6.1796989, 106.9092693 -6.1816616, 106.9115009 -6.1821736, 106.9127025 -6.1826856, 106.9154491 -6.1824296, 106.9183673 -6.1827709, 106.9187106 -6.1814056, 106.918539 -6.1788456, 106.9182815 -6.1772243, 106.9211997 -6.1765417, 106.9232597 -6.176115, 106.9230022 -6.171763, 106.922058 -6.1698004, 106.9224014 -6.1677523, 106.9212856 -6.1670697, 106.9215431 -6.165619, 106.9215431 -6.1639123, 106.9219722 -6.1624616, 106.9219722 -6.1603282, 106.9214572 -6.1594749, 106.9214572 -6.1587922, 106.9201698 -6.1575122, 106.9197406 -6.1557201, 106.9192256 -6.1540134, 106.9181098 -6.1520507, 106.9177665 -6.1500026, 106.9177665 -6.1479545, 106.9163074 -6.1470158, 106.9153632 -6.1446264, 106.9127025 -6.1436876, 106.9113292 -6.1436876, 106.9102134 -6.1421516, 106.9090118 -6.1422369, 106.9075526 -6.1431756, 106.9067802 -6.1443277, 106.9055356 -6.1438583, 106.9045486 -6.1447117, 106.9033469 -6.1443277, 106.8982829 -6.1411702, 106.8941631 -6.138866, 106.8923606 -6.1380127, 106.8918885 -6.1406155, 106.891159 -6.1436023, 106.8907298 -6.1459491, 106.8901719 -6.1481679, 106.8886699 -6.1511973",
            coordinate: {
                latitude: -6.161270141237551,
                longitude: 106.90572433600319
            }
        },
        {
            name: "Kebayoran Lama",
            polygon: "106.7807505 -6.2412292, 106.7830679 -6.2356833, 106.7851278 -6.2319291, 106.7875311 -6.2274922, 106.790106 -6.2227994, 106.7929979 -6.2161819, 106.7899344 -6.2138402, 106.7866728 -6.211963, 106.7829821 -6.2107684, 106.7792913 -6.2096592, 106.7746699 -6.2089445, 106.7705961 -6.2086731, 106.7668459 -6.2166559, 106.7640349 -6.2249538, 106.7652366 -6.2291561, 106.7665133 -6.2315984, 106.7682836 -6.2323344, 106.7685196 -6.235598, 106.7719099 -6.235342, 106.7719904 -6.2380563, 106.7745841 -6.2381976, 106.7759762 -6.237443, 106.7773173 -6.2385042, 106.7773602 -6.2393841, 106.7785189 -6.2417838, 106.7807505 -6.2412292",
            coordinate: {
                latitude: -6.221792563314586,
                longitude: 106.7818501576884
            }
        }
    ];
 */