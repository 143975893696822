import React from "react";
import Select from "react-select";
import { MenuModel } from "../models/menu_packaging_response";

interface Props {
    menuToPackagingId: number;
    label: string;
    id: number;
    selectOptionsItem: MenuModel[];
    selectId: string;
    onSelectChange: (id: number, itemId: number) => void;
}

export default class MenuPackagingSelectOptionWidget extends React.Component<Props> {
    render(): React.ReactNode {
        const options = this.props.selectOptionsItem.map((item) => ({
            value: item.id.toString(),
            label: `(${item.materialCode}) ${item.item}`,
        }));

        const handleChange = (selectedOption: any) => {
            this.props.onSelectChange(
                this.props.menuToPackagingId,
                selectedOption.value
            );
        };

        return (
            <div>
                <label>{this.props.label}</label>
                <Select
                    id={this.props.selectId}
                    className="basic-single pb-4"
                    options={options}
                    value={options.find(
                        (option) => option.value === this.props.id.toString()
                    )}
                    onChange={handleChange}
                    required={true}
                />
            </div>
        );
    }
}
