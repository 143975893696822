import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsToVendorViewModel from "../view_models/adjustment_forms_to_vendor_view_model";
import StringUtils from "../../../../commons/utils/string_utils";

export default class AdjustmentFormsToVendorTableWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsToVendorViewModel>>
                {(vm) => (
                    <div className="overflow-x-auto">
                        <table className="table-fixed min-w-full border-collapse text-center">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-1.5 border border-gray-300 w-1/6">
                                        Kode Submit
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/6">
                                        Pengirim
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/6">
                                        Vendor
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/6">
                                        Waktu Dikirim
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {vm.filteredTransferToVendorList.map(
                                    (data, index) => (
                                        <tr
                                            key={index}
                                            className="odd:bg-white even:bg-gray-100 cursor-pointer text-center hover:bg-slate-200"
                                            onClick={() => {
                                                console.log(data);
                                                vm.fetchTransferToVendorDetail(
                                                    data.formId
                                                );
                                                vm.setFormId(data.formId);
                                                vm.setFormCode(data.submitCode);
                                                vm.setFormSentAt(data.sentAt);
                                                vm.setNewFromLocationId(
                                                    data.fromLocationId
                                                );
                                                vm.setNewToLocationId(
                                                    data.toLocationId
                                                );
                                                vm.setIsShowModal(true);
                                            }}
                                        >
                                            <td className="p-2 border border-gray-300">
                                                {data.submitCode ?? " - "}
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <p className="font-bold text-center">
                                                    {data.senderLocation}
                                                </p>
                                                <p className="text-center">
                                                    {data.respondentName}
                                                </p>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                {data.vendor ?? " - "}
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                {StringUtils.toIndonesianDate(
                                                    new Date(data.sentAt)
                                                ) ?? " - "}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
