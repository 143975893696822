import React from "react";
import { VMProvider } from "../commons/base/providers/provider";
import AllOutletStockDashboardVM from './view_models/all_outlet_stock_dashboard_vm';
import FilterList from "./widgets/outlet_stock_display_filter/filter_list";
import OutletStockDisplay from "./widgets/outlet_stock_display";
import Scaffold from "../commons/scaffold/scaffold";

export default class AllOutletStockScreen extends React.Component {
    
    render(): React.ReactNode {
        return (
        <VMProvider create={() => new AllOutletStockDashboardVM()}>
            {(vm) => (
                <Scaffold
                isShowLoading={vm.isLoading}
                loadingBackgroundType="solid"
                loadingBackgroundColor="#6B2B90"
                loadingType="uena_logo">
                    <div className="mt-[52px]">
                        <h1 className="text-slate-800 text-3xl mb-5 p-4 bg-yellow-100 font-extrabold">All Outlet Stock</h1>
                        <FilterList />
                        <OutletStockDisplay />
                    </div>
                </Scaffold>
            )}
        </VMProvider>
        )    
    }
}