import ApiService, { HttpMethod } from "../../commons/api/api_service";
import BaseResponse from "../../commons/api/base_response";
import { Endpoints } from "../../commons/api/endpoints";
import { ProviderVM } from "../../commons/base/providers/view_model";
import { ShowSnackbarModel } from "../../commons/scaffold/scaffold";
import {
    SoldOutLocationCountModel,
    SoldOutPerOutletModel,
    SoldOutResponse,
} from "../models/sold_out_model";
import { MenuCategory } from "../models/menu_category_enum";

export default class SoldOutLiveDashboardViewModel extends ProviderVM {
    // UI States
    showSnackbarModel?: ShowSnackbarModel;
    isShowingModal: boolean = false;
    isShowingOtherMenus: boolean = false;
    isConnectionErrorOccured: boolean = false;

    // Data States
    soldOutPerOutletMap: Map<string, SoldOutPerOutletModel> = new Map<
        string,
        SoldOutPerOutletModel
    >();
    filteredPrioritySoldOutData: SoldOutPerOutletModel[] = [];
    filteredOtherSoldOutData: SoldOutPerOutletModel[] = [];
    soldOutLocationCountMap: SoldOutLocationCountModel[] = [];

    constructor() {
        super();
        this.fetchData();
    }

    fetchData() {
        this.emit(() => {
            this.showSnackbarModel = undefined;
        });

        ApiService.fetch<{}, BaseResponse<SoldOutResponse[]>>(
            Endpoints.soldOutDashboard,
            {
                httpMethod: HttpMethod.post,
                onSuccess: (result) => {
                    if (result?.data) {
                        const soldOutResponse = result.data.filter(
                            (data) => data.menus.length > 0
                        );

                        // Filter menu untuk ditampilkan di tabel
                        const filteredSoldOutMap = new Map<
                            string,
                            SoldOutPerOutletModel
                        >();
                        soldOutResponse.forEach((data) => {
                            if (!filteredSoldOutMap.has(data.outlet)) {
                                const chiliOilMenus: string[] = [];
                                const b1g1Menus: string[] = [];
                                const geprekMenus: string[] = [];
                                const mainCourseMenus: string[] = [];
                                const otherMenus: string[] = [];

                                data.menus.forEach((menu) => {
                                    switch (menu.category) {
                                        case MenuCategory.CHILI_OIL:
                                            chiliOilMenus.push(...menu.name);
                                            break;
                                        case MenuCategory.B1G1:
                                            b1g1Menus.push(...menu.name);
                                            break;
                                        case MenuCategory.GEPREK:
                                            geprekMenus.push(...menu.name);
                                            break;
                                        case MenuCategory.MAIN_COURSE:
                                            mainCourseMenus.push(...menu.name);
                                            break;
                                        default:
                                            otherMenus.push(...menu.name);
                                            break;
                                    }
                                });

                                filteredSoldOutMap.set(data.outlet, {
                                    outletName: data.outlet,
                                    chiliOilMenus: chiliOilMenus,
                                    b1g1Menus: b1g1Menus,
                                    geprekMenus: geprekMenus,
                                    mainCourseMenus: mainCourseMenus,
                                    otherMenus: otherMenus,
                                });
                            }
                        });

                        // Hitung jumlah lokasi setiap menu sold out
                        const soldOutLocationCountMap = new Map<
                            string,
                            SoldOutLocationCountModel
                        >();

                        soldOutResponse.forEach((location) => {
                            location.menus.forEach((menuList) => {
                                menuList.name.forEach((name) => {
                                    const oldModel =
                                        soldOutLocationCountMap.get(name);
                                    if (oldModel) {
                                        soldOutLocationCountMap.set(name, {
                                            name: oldModel.name,
                                            count: oldModel.count + 1,
                                            category: oldModel.category,
                                        });
                                    } else {
                                        soldOutLocationCountMap.set(name, {
                                            name: name,
                                            count: 1,
                                            category:
                                                menuList.category as MenuCategory,
                                        });
                                    }
                                });
                            });
                        });

                        const sortedSoldOutLocationCountMap =
                            this._sortCumulativeSoldOutDataByPriority({
                                soldOutCumulativeData: Array.from(
                                    soldOutLocationCountMap.values()
                                ),
                                priorityOrder: [
                                    MenuCategory.CHILI_OIL,
                                    MenuCategory.B1G1,
                                    MenuCategory.GEPREK,
                                    MenuCategory.MAIN_COURSE,
                                ],
                            });

                        this.emit(() => {
                            this.soldOutPerOutletMap = filteredSoldOutMap;
                            this.soldOutLocationCountMap =
                                sortedSoldOutLocationCountMap;
                            this.filteredPrioritySoldOutData = Array.from(
                                filteredSoldOutMap.values()
                            ).filter(
                                (value) =>
                                    value.b1g1Menus.length > 0 ||
                                    value.chiliOilMenus.length > 0 ||
                                    value.geprekMenus.length > 0 ||
                                    value.mainCourseMenus.length > 0
                            );
                            this.filteredOtherSoldOutData = Array.from(
                                filteredSoldOutMap.values()
                            ).filter((value) => value.otherMenus.length > 0);
                            this.isConnectionErrorOccured = false;
                        });

                        setTimeout(() => this.fetchData(), 300000);
                    } else {
                        console.log(
                            "Error in response from fetchData() : ",
                            result
                        );
                        this.emit(() => {
                            this.showSnackbarModel = new ShowSnackbarModel({
                                message:
                                    "Gagal mendapatkan data, tolong REFRESH halaman ini",
                                type: "error",
                            });
                            this.isConnectionErrorOccured = true;
                        });

                        // setTimeout(() => this.fetchData(), 300000);
                    }
                },
                onFailure: (error) => {
                    console.log("Error in fetchData() : ", error);
                    this.emit(() => {
                        this.showSnackbarModel = new ShowSnackbarModel({
                            message:
                                "Gagal mendapatkan data, tolong REFRESH halaman ini",
                            type: "error",
                        });
                        this.isConnectionErrorOccured = true;
                    });

                    // setTimeout(() => this.fetchData(), 300000);
                },
            }
        );
    }

    _sortCumulativeSoldOutDataByPriority(args: {
        soldOutCumulativeData: SoldOutLocationCountModel[];
        priorityOrder: MenuCategory[];
    }): SoldOutLocationCountModel[] {
        const { soldOutCumulativeData, priorityOrder } = args;
        const sortedData = [];

        for (const priority of priorityOrder) {
            const filteredDataByPriority = soldOutCumulativeData.filter(
                (value) => (value.category as MenuCategory) === priority
            );
            sortedData.push(...filteredDataByPriority);
        }

        const otherData = soldOutCumulativeData.filter(
            (value) => !priorityOrder.includes(value.category as MenuCategory)
        );
        sortedData.push(...otherData);

        return sortedData;
    }

    setIsShowingModal(value: boolean) {
        this.emit(() => {
            this.isShowingModal = value;
        });
    }

    setIsShowingOtherMenus(value: boolean) {
        this.emit(() => {
            this.isShowingOtherMenus = value;
        });
    }
}
