type Action = () => void;

export class ProviderVM {
    static createContext() {}
    
    renderVc?: () => void;

    emit(action: Action) {
        action();

        if (this.renderVc) this.renderVc();
    }
}