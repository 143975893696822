import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import UploadMapCsvViewModel from "../view_models/upload_map_csv_view_model";

export default class UploadMapCsvWidget extends React.Component {
  inputRef = React.createRef<HTMLInputElement>();
  weekInputRef = React.createRef<HTMLInputElement>();
  yearInputRef = React.createRef<HTMLInputElement>();
  render(): React.ReactNode {
    return (
      <VMBuilder<UploadMapCsvViewModel>>
        {(vm) => (
          <div className="bg-slate-500 w-full min-h-screen mt-12 p-6 flex flex-col items-center">
            {/* Header Section */}
            <div className="flex justify-between items-center w-full max-w-3xl mb-6">
              <button
                className="px-4 py-2 border rounded bg-indigo-400 text-white hover:bg-indigo-500 transition"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Kembali
              </button>
              <h1 className="text-white text-3xl font-bold text-center mx-auto">
                Upload MAP Weekly Csv
              </h1>
            </div>

            {/* Form Section */}
            <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-3xl">
              <form className="grid gap-4">
                <input
                  className="block w-full text-gray-700 border border-gray-300 rounded-md p-2"
                  type="file"
                  name="csv"
                  id="csv"
                  ref={this.inputRef}
                  onChange={(e) => {
                    const file = e.target.files?.item(0);
                    console.log("File : ", file);
                    if (file) {
                      vm.setFile(file);
                    }
                  }}
                />
                <input
                  className="block w-full text-gray-700 border border-gray-300 rounded-md p-2"
                  type="number"
                  name="week"
                  id="week"
                  placeholder="Week"
                  ref={this.weekInputRef}
                  onChange={(e) => {
                    const week = parseInt(e.target.value);
                    vm.setWeek(week);
                  }}
                />
                <input
                  className="block w-full text-gray-700 border border-gray-300 rounded-md p-2"
                  type="number"
                  name="year"
                  id="year"
                  placeholder="Year"
                  ref={this.yearInputRef}
                  onChange={(e) => {
                    const year = parseInt(e.target.value);
                    vm.setYear(year);
                  }}
                />
                <button
                  className="w-full bg-indigo-500 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-600 transition"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    vm.submit();
                    if (this.inputRef.current) {
                      this.inputRef.current.value = '';
                    }
                    if (this.weekInputRef.current) {
                      this.weekInputRef.current.value = '';
                    }
                    if (this.yearInputRef.current) {
                      this.yearInputRef.current.value = '';
                    }
                  }}
                >
                  Submit
                </button>
                <h1 className="text-gray-500">Notes : Data without Material ID will not be inputted.</h1>
              </form>
            </div>
          </div>
        )}
      </VMBuilder>
    );
  }
}
