import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import OutletOrderPreviewViewModel from "../view_models/outlet_order_preview_view_model";

const orderTypeCheck = (orderType: string) => {
    if (
        orderType !== "Dine In" &&
        orderType !== "Pick Up" &&
        orderType !== "Take Away"
    ) {
        return true;
    }
    return false;
};

const colorIndicator = (time: number) => {
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - time;
    // over 20 minutes
    if (timeElapsed > 2000000) {
        return "bg-red-600";
    }
    // 10 minutes
    if (timeElapsed > 600000) {
        return "bg-yellow-600";
    }

    return "bg-slate-600";
};

export default class OutletOrderDisplayWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<OutletOrderPreviewViewModel>>
                {(vm) => (
                    <div className="bg-black w-full">
                        <h1 className="text-3xl text-white font-bold self-center">
                            {vm.selectedOutlet}
                        </h1>
                        <div className="flex gap-2 justify-between">
                            <div className="flex flex-col flex-wrap border-box p-4 min-h-screen max-h-[100vh] gap-[16px] overflow-auto">
                                {vm.billData.map((data) => (
                                    <div key={data.id} className="w-[320px]">
                                        <div className="flex justify-between bg-slate-700 p-2">
                                            <div className="font-bold text-white">
                                                {data.data.orderId}
                                            </div>
                                            <div className="font-bold text-white">
                                                {data.data.orderType}
                                            </div>
                                            {orderTypeCheck(
                                                data.data.orderType
                                            ) ? (
                                                <div className="font-bold text-white">
                                                    {data.data.platformCode}
                                                </div>
                                            ) : (
                                                <div className="font-bold text-white">
                                                    NO : {data.data.queueNumber}
                                                </div>
                                            )}
                                        </div>
                                        <div className=" bg-slate-300 flex flex-col gap-2 p-2">
                                            {data.data.orderItems.map(
                                                (item) => (
                                                    <>
                                                        <div
                                                            key={item.name}
                                                            className="flex flex-col"
                                                        >
                                                            <div className="flex gap-2 text-left">
                                                                <div className="font-bold">
                                                                    {
                                                                        item.quantity
                                                                    }
                                                                </div>
                                                                <div className="font-bold ">
                                                                    {item.name}
                                                                </div>
                                                            </div>
                                                            {
                                                                //if there is a note, show it
                                                                item.note && (
                                                                    <p className="font-semibold text-left">
                                                                        NOTE :{" "}
                                                                        {
                                                                            item.note
                                                                        }
                                                                    </p>
                                                                )
                                                            }
                                                        </div>
                                                        <hr className=" bg-slate-500" />
                                                    </>
                                                )
                                            )}
                                        </div>
                                        <div
                                            className={colorIndicator(
                                                new Date(
                                                    data.created_at
                                                ).getTime()
                                            )}
                                        >
                                            <p className="text-white font-bold text-center">
                                                {new Date(
                                                    data.created_at
                                                ).toLocaleTimeString("id-ID", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    timeZone: "Asia/Jakarta",
                                                    hour12: false,
                                                })}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="w-[350px] border-l border-l-white p-2">
                                <h1 className="text-3xl text-white font-bold mb-5">
                                    Summary Order
                                </h1>
                                {vm.groupByMenuName.map((item) => (
                                    <div className="flex  w-full items-center gap-4">
                                        <p className="text-white mb-1 font-bold text-[36px]">
                                            {item.quantity}
                                            <span className=" text-xl">x</span>
                                        </p>
                                        <p className="text-white mb-1 font-bold text-xl tracking-wide">
                                            {" "}
                                            {item.name}
                                        </p>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
