import React from "react";
import { VMProvider } from "../commons/base/providers/provider";
import StockDashboardVM from "./view_models/stock_dashboard_vm";
import Scaffold from "../commons/scaffold/scaffold";
import StockDashboard from "./widgets/stock_outlet_table";
// import PageButton from "./widgets/page_button";

export default class StockDashboardScreen extends React.Component {
    
    render(): React.ReactNode {
        let outlet = document.location.href.split("?")[1].split("=")[1];
        outlet = outlet.replace("%20", " ");
        return (
        <VMProvider create={() => new StockDashboardVM(outlet)}>
            {(vm) => {
                vm.outlet = outlet;
                return (
                    <Scaffold
                    isShowLoading={vm.isLoading}
                    loadingBackgroundType="solid"
                    loadingBackgroundColor="#6B2B90"
                    loadingType="uena_logo">
                        <div className="mt-[52px] p-5 flex-col items-start">
                            <h1 className="text-3xl">{ `Stok Item di Outlet ${outlet}` }</h1>
                            <div className="mt-12 mb-4 flex justify-center">
                                {/* <p className="mr-4 text-lg">Halaman: </p> */}
                                {/* <PageButton /> */}
                            </div>
                            <div className="mt-5 w-full flex justify-center">
                                <StockDashboard outlet={outlet} />
                            </div>
                        </div>
                    </Scaffold>
                )
            }}
        </VMProvider>
        )    
    }
}