import ApiService from "../../../commons/api/api_service";
import { Endpoints } from "../../../commons/api/endpoints";
import ViewModel from "../../../commons/base/view_model";
import { ShowSnackbarModel } from "../../../commons/scaffold/scaffold";
import { getLocationQueries } from "../../../commons/utils/utils";
import OrderFormProductModel from "../../models/order_form_product_model";
import GerobakFormSubmitRequest from "../../models/requests/gerobak_order_form_submit_request";
import OrderFormGetContentRequest from "../../models/requests/order_form_get_content_request";
import OrderFormGetContentResponse from "../../models/responses/order_form_get_content_response";
import OrderFormSubmitResponse from "../../models/responses/order_form_submit_response";
import GerobakOrderState from "./gerobak_order_state";

export default class GerobakOrderViewModel extends ViewModel<GerobakOrderState> {
    constructor() {
        super(new GerobakOrderState({ gerobakId: getLocationQueries()["id"] }));

        this.loadContents();
    }

    loadContents() {
        this.emit((state) => state.isLoading = true);

        ApiService.fetch<OrderFormGetContentRequest, OrderFormGetContentResponse>(
            Endpoints.orderFormGetContent, {
            onSuccess: (response) => {
                this.emit((state) => {
                    const contents = response.contents;

                    state.contentModel = contents;
                    state.selectedFilter = this.state.selectedFilter.length > 0 ? contents.gerobakProducts.map(() => false) : state.selectedFilter;
                    state.isLoading = false;
                });
            },
            onFailure: (failure) => {
                this.emit((state) => {
                    state.showSnackbarModel = new ShowSnackbarModel({
                        message: `Terjadi kesalahan, silahkan refresh halaman ini kembali. (${failure})`,
                        type: "error"
                    });
                    state.isLoading = false;
                });
            }
        });
    }

    changeSelectedPaymentMethod(id: string) {
        this.emit((state) => {
            state.selectedPaymentMethodId = id;
            state.isPaymentMethodError = false;
        });
    }

    changeSelectedCategoryTicker(args: { index: number, isActive: boolean }) {
        this.emit((state) => state.selectedFilter[args.index] = args.isActive);
    }

    changeProductQuantity(args: { id: string, quantity: number, model: OrderFormProductModel, isVoucherRequired: boolean }) {
        this.emit((state) => {
            if (args.quantity === 0) {
                state.productAndQuantity.delete(args.id);

                if (args.isVoucherRequired) {
                    state.productIdsThatRequireVoucher.splice(state.productIdsThatRequireVoucher.indexOf(args.id), 1);
                }
            } else {
                state.productAndQuantity.set(args.id, {
                    quantity: args.quantity,
                    model: args.model
                });

                if (args.isVoucherRequired) {
                    state.productIdsThatRequireVoucher.push(args.id);
                }
            }
        });
    }

    changeName(value: string) {
        this.emit((state) => {
            state.name = value;
        });
    }

    changeOrderNote(value: string) {
        this.emit((state) => state.orderNote = value);
    }

    submitForm() {
        this.emit((state) => {
            state.isLoading = true;
            state.showSnackbarModel = undefined;
            state.showErrorViewModel = undefined;
        });

        let selectedProducts: {
            id: string;
            quantity: number;
            notes?: string;
        }[] = [];

        this.state.productAndQuantity.forEach((value, key) => {
            selectedProducts.push({
                id: key,
                quantity: value.quantity,
                notes: value.model.notes
            });
        });

        ApiService.fetch<GerobakFormSubmitRequest, OrderFormSubmitResponse>(
            Endpoints.orderFormSubmitGerobak, {
            parameters: {
                gerobakId: this.state.gerobakId,
                selectedProducts: selectedProducts,
                name: this.state.name,
                comment: this.state.orderNote,
                paymentId: this.state.selectedPaymentMethodId ?? "",
            },
            onSuccess: (response) => {
                console.log(response);
                if (response.isSuccess) {
                    this.emit((state) => {
                        state.showSnackbarModel = new ShowSnackbarModel({
                            message: response.message ?? "",
                            type: "info"
                        });
                        state.isLoading = false;
                    });

                    this.resetForm();
                } else {
                    this.emit((state) => {
                        state.showSnackbarModel = new ShowSnackbarModel({
                            message: response.message ?? "",
                            type: "error"
                        })
                        state.isLoading = false;
                    });
                }
            },
            onFailure: (error) => {
                console.log(error);
                this.emit((state) => {
                    state.showErrorViewModel = {
                        message: `Terjadi kesalahan. (${error})`,
                        action: () => this.submitForm()
                    }
                    state.isLoading = false;
                });
            }
        });
    }

    validateAbleToBeSubmitted(onValidated: () => void) {
        let message = "";

        if (!this.state.selectedPaymentMethodId) {
            message += ((message.length !== 0) ? ", " : "") + "Metode Pembayaran";
        }

        if (message.length > 0) {
            message += " WAJIB diisi. Periksa kembali.";
        }

        if (message.length === 0 && this.state.productList.length === 0) {
            message = "Belum ada produk yang dipilih, silahkan periksa kembali.";
        }

        this.emit((state) => state.showSnackbarModel = message.length > 0 ? new ShowSnackbarModel({
            message,
            type: "error"
        }) : undefined);

        if (message.length === 0) {
            onValidated();
        }
    }

    showPopUp(action: () => void) {
        this.emit((state) => {
            state.popUpModel = { action };
            state.showSnackbarModel = undefined;
        });
    }

    closePopUp() {
        this.emit((state) => state.popUpModel = undefined);
    }

    resetSelectedProduct() {
        this.emit((state) => {
            state.productAndQuantity = new Map();
            state.productIdsThatRequireVoucher = [];
            state.contentModel?.gerobakProducts.forEach(
                (productList) => productList.items.forEach(
                    (productItem) => productItem.notes = undefined
                )
            );
            state.isResetLoading = true;
        });

        setTimeout(() => this.emit((state) => state.isResetLoading = false), 200);
    }

    private resetForm() {
        this.emit((state) => {
            state.name = "";
            state.orderNote = "";
            state.productAndQuantity = new Map();
            state.contentModel?.gerobakProducts.forEach(
                (productList) => productList.items.forEach(
                    (productItem) => productItem.notes = undefined
                )
            );
            state.isResetLoading = false;
            state.refreshId = new Date().getTime();
        });

        setTimeout(() => this.emit((state) => state.isResetLoading = false), 200);
    }
}