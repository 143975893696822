import { createContext } from "react";
import { ProviderVM } from "./view_model";

export class ViewModelContext {
    static listOfContext: React.Context<any>[] = [];

    static create<VM extends ProviderVM>(vm: VM): React.Context<VM> {
        const context = createContext(vm);

        this.listOfContext.push(context);

        return context;
    }

    static read<VM extends ProviderVM>(): React.Context<VM> {
        for (let index = 0; index < this.listOfContext.length; index++) {
            const object = this.listOfContext[index];
            
            try {
                if (object as React.Context<VM>) {
                    return object;
                }
            } catch (error) { }
        }

        console.log(`make sure you already set the VMProvider with the VM`);

        throw `make sure you already set the VMProvider with the VM`;
    }
}