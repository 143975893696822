import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsWasteViewModel from "../view_models/adjustment_forms_waste_view_model";
import Select from "react-select";
import { WasteType } from "../models/adjustment_forms_waste_model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default class AdjustmentFormsWasteModalWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsWasteViewModel>>
                {(vm) => (
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 pt-4 px-4"
                        onClick={() => {
                            vm.setIsShowModal(false);
                            vm.resetFormData();
                        }}
                    >
                        <div
                            className="flex flex-col bg-white rounded-lg shadow-lg w-full max-w-6xl h-[85vh] overflow-y-auto relative"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {/* Title & Search Bar */}
                            <div className="sticky top-0 bg-white px-6 py-3 shadow-sm">
                                <h2 className="text-2xl font-bold">
                                    Adjust Waste
                                </h2>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        className="w-full border border-gray-300 rounded-lg my-2 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        onChange={(e) => {
                                            const search =
                                                e.target.value.toLowerCase();
                                            vm.setSearchQuery(search);
                                        }}
                                        value={vm.searchQuery}
                                    />
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                                    />
                                </div>
                            </div>

                            {/* Mapping data */}
                            <div className="flex-grow space-y-4 px-6 mt-3">
                                {[...vm.currentWasteDataMap.values()]
                                    .filter((waste) =>
                                        waste.itemName
                                            ?.toLowerCase()
                                            .includes(vm.searchQuery)
                                    )
                                    .map((waste) => (
                                        <div className="grid grid-cols-10 gap-4 items-center border-b pb-4">
                                            {/* Item Name */}
                                            <span className="col-span-2 text-gray-700 font-semibold">
                                                {waste.itemName}
                                            </span>

                                            {/* Big Qty */}
                                            <div className="col-span-2">
                                                <label className="block text-sm text-gray-600">
                                                    Satuan Besar{" "}
                                                    {waste.bigUnit
                                                        ? `(${waste.bigUnit})`
                                                        : ""}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={waste.bigQty}
                                                    placeholder="Big Qty"
                                                    className="w-full border rounded-lg px-3 py-2 mt-1"
                                                    onChange={(e) => {
                                                        vm.handleChangeData(
                                                            waste.uniqueId,
                                                            "bigQty",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                    // Prevent negative
                                                    onInput={(e) => {
                                                        const target =
                                                            e.target as HTMLInputElement;
                                                        if (
                                                            target.value.startsWith(
                                                                "0"
                                                            ) &&
                                                            target.value
                                                                .length > 1
                                                        ) {
                                                            target.value =
                                                                target.value.replace(
                                                                    /^0+/,
                                                                    ""
                                                                );
                                                        }
                                                    }}
                                                />
                                            </div>

                                            {/* Small Qty */}
                                            <div className="col-span-2">
                                                <label className="block text-sm text-gray-600">
                                                    Satuan Kecil{" "}
                                                    {waste.smallUnit
                                                        ? `(${waste.smallUnit})`
                                                        : ""}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={waste.smallQty}
                                                    placeholder="Small Qty"
                                                    className="w-full border rounded-lg px-3 py-2 mt-1"
                                                    onChange={(e) => {
                                                        vm.handleChangeData(
                                                            waste.uniqueId,
                                                            "smallQty",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                    // Prevent negative
                                                    onInput={(e) => {
                                                        const target =
                                                            e.target as HTMLInputElement;
                                                        if (
                                                            target.value.startsWith(
                                                                "0"
                                                            ) &&
                                                            target.value
                                                                .length > 1
                                                        ) {
                                                            target.value =
                                                                target.value.replace(
                                                                    /^0+/,
                                                                    ""
                                                                );
                                                        }
                                                    }}
                                                />
                                            </div>

                                            {/* Waste Type */}
                                            <div className="col-span-2">
                                                <label className="block text-sm text-gray-400">
                                                    Tipe Waste
                                                </label>
                                                <select
                                                    value={waste.wasteType}
                                                    className="w-full border rounded-lg px-3 py-2 mt-1"
                                                    onChange={(e) => {
                                                        vm.handleChangeData(
                                                            waste.uniqueId,
                                                            "wasteType",
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="" disabled>
                                                        Select Waste Type
                                                    </option>
                                                    {Object.entries(
                                                        WasteType
                                                    ).map(([key, value]) => (
                                                        <option value={value}>
                                                            {value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* Note */}
                                            <div className="col-span-2">
                                                <label className="block text-sm text-gray-400">
                                                    Keterangan
                                                </label>
                                                <input
                                                    type="text"
                                                    className="w-full border rounded-lg px-3 py-2 mt-1"
                                                    placeholder="Keterangan"
                                                    value={waste.note}
                                                    onChange={(e) => {
                                                        vm.handleChangeData(
                                                            waste.uniqueId,
                                                            "note",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            {/* Select Item & Add Btn */}
                            <div className="mt-6 sticky bottom-0 bg-white px-4 py-6 shadow-inner">
                                <div className="w-full flex space-x-4 items-center">
                                    <div className="flex-grow">
                                        <Select
                                            className="basic-single w-full"
                                            classNamePrefix="select"
                                            isClearable
                                            isSearchable
                                            name="item"
                                            options={vm.itemOptions
                                                .filter(
                                                    (option) =>
                                                        !vm.addedItemIds.includes(
                                                            option.id
                                                        )
                                                )
                                                .map((option) => ({
                                                    value: option.id,
                                                    label: option.item,
                                                }))}
                                            onChange={(selectedOption) => {
                                                vm.setSelectedItemId(
                                                    selectedOption?.value
                                                );
                                            }}
                                            placeholder="Tambah Item ?"
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    </div>
                                    <button
                                        onClick={() => {
                                            if (vm.selectedItemId) {
                                                vm.handleAddData(
                                                    vm.selectedItemId
                                                );
                                            }
                                        }}
                                        disabled={!vm.selectedItemId}
                                        className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 disabled:bg-gray-500"
                                    >
                                        Masukkan
                                    </button>
                                </div>
                                {/* Submit Btn */}
                                <div className="mt-4">
                                    <button
                                        onClick={() => vm.handleSubmit()} // Handle the form submission here
                                        className="w-full bg-green-500 text-white px-4 py-3 rounded-lg hover:bg-green-600"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
