import React from "react";
import { VMProvider } from "../../../commons/base/providers/provider";
import AdjustmentFormsVendorReceiptViewModel from "./view_models/adjustment_forms_vendor_receipt_view_model";
import Scaffold from "../../../commons/scaffold/scaffold";
import AdjustmentFormsVendorReceiptTableWidget from "./widgets/adjustment_forms_vendor_receipt_table_widget";
import AdjustmentFormsVendorReceiptFilterWidget from "./widgets/adjustment_forms_vendor_receipt_filter_widget";
import AdjustmentFormsVendorReceiptModalWidget from "./widgets/adjustment_forms_vendor_receipt_modal_widget";

export default class AdjustmentFormsVendorReceiptScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <VMProvider
                create={() => new AdjustmentFormsVendorReceiptViewModel()}
            >
                {(vm) => (
                    <Scaffold
                        isShowLoading={vm.isLoading}
                        loadingBackgroundType="semi_transparent"
                        showSnackbar={vm.showSnackbar}
                        showErrorView={vm.showErrorView}
                    >
                        <div className=" bg-slate-500 overflow-x-auto min-h-screen p-5 mt-10 relative">
                            <h1 className="text-white text-3xl text-center font-bold flex-grow m-5">
                                Adjust Form Penerimaan Barang dari Vendor
                            </h1>

                            {vm.isShowModal && (
                                <AdjustmentFormsVendorReceiptModalWidget />
                            )}
                            <AdjustmentFormsVendorReceiptFilterWidget />
                            <AdjustmentFormsVendorReceiptTableWidget />
                        </div>
                    </Scaffold>
                )}
            </VMProvider>
        );
    }
}
