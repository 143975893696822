import React from "react";
import CompletenessViewModel from "../view_models/completeness_view_model";
import { VMBuilder } from "../../../commons/base/providers/builder";
import { responseCompletenessDetail } from "../models/completeness_response";

export default class CompletenessInventoryWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<CompletenessViewModel>>
                {(vm) => (
                    <div className=" bg-slate-500 w-full  min-h-screen p-5">
                        <div className="flex justify-between items-center">
                            <button
                                className="justify-self-start px-4 py-2 border rounded bg-indigo-400 font-bold text-white"
                                onClick={() => {
                                    // back to root
                                    window.location.href = "/";
                                }}
                            >
                                Kembali
                            </button>
                            <h1 className="text-white text-3xl text-center font-bold flex-grow">
                                Kelengkapan Form SO dan Waste
                            </h1>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-4">
                            {vm.so.length > 0 && (
                                <div className="flex flex-col items-center w-full px-5">
                                    <h2 className="text-white text-2xl font-bold my-5">
                                        Kelengkapan Form SO
                                    </h2>
                                    <div className="bg-white w-full">
                                        <table className="text-black border-separate border border-slate-500 w-full">
                                            <thead>
                                                <tr>
                                                    <th className="border border-slate-600 p-2 w-3/12">
                                                        Outlet
                                                    </th>
                                                    {vm.soGroupDate.map(
                                                        (date) => (
                                                            <th
                                                                key={date}
                                                                className="border border-slate-600 p-2 w-3/12"
                                                            >
                                                                {date}
                                                            </th>
                                                        )
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {vm.so.map(
                                                    (
                                                        item: responseCompletenessDetail
                                                    ) => (
                                                        <tr key={item.outlet}>
                                                            <td className="border border-slate-700 p-2">
                                                                {item.outlet}
                                                            </td>
                                                            {vm.soGroupDate.map(
                                                                (date) => (
                                                                    <td
                                                                        key={
                                                                            date
                                                                        }
                                                                        className="border border-slate-700 p-2"
                                                                    >
                                                                        {item[
                                                                            date
                                                                        ] !==
                                                                        null
                                                                            ? item[
                                                                                  date
                                                                              ]
                                                                            : "N/A"}
                                                                    </td>
                                                                )
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                            {vm.waste.length > 0 && (
                                <div className="flex flex-col items-center w-full px-5">
                                    <h2 className="text-white text-2xl font-bold my-5">
                                        Kelengkapan Waste
                                    </h2>
                                    <div className="bg-white w-full">
                                        <table className="text-black border-separate border border-slate-500 w-full">
                                            <thead>
                                                <tr>
                                                    <th className="border border-slate-600 p-2 w-3/12">
                                                        Outlet
                                                    </th>
                                                    {vm.wasteGroupDate.map(
                                                        (date) => (
                                                            <th
                                                                key={date}
                                                                className="border border-slate-600 p-2 w-3/12"
                                                            >
                                                                {date}
                                                            </th>
                                                        )
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {vm.waste.map(
                                                    (
                                                        item: responseCompletenessDetail
                                                    ) => (
                                                        <tr key={item.outlet}>
                                                            <td className="border border-slate-700 p-2">
                                                                {item.outlet}
                                                            </td>
                                                            {vm.wasteGroupDate.map(
                                                                (date) => (
                                                                    <td
                                                                        key={
                                                                            date
                                                                        }
                                                                        className="border border-slate-700 p-2"
                                                                    >
                                                                        {item[
                                                                            date
                                                                        ] !==
                                                                        null
                                                                            ? item[
                                                                                  date
                                                                              ]
                                                                            : "N/A"}
                                                                    </td>
                                                                )
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
