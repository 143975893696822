import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import MenuPackagingViewModel from "../view_models/menu_packaging_view_model";
import MenuPackagingSelectOptionWidget from "./menu_packaging_select_option_widget";

export default class MenuPackagingModalWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<MenuPackagingViewModel>>
                {(vm) => (
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
                        <div className="bg-white p-5 rounded-lg max-h-[80%] w-10/12 relative overflow-visible">
                            <span
                                className="absolute top-2 right-2 cursor-pointer text-red-500 font-semibold text-3xl"
                                onClick={() => vm.closeDetail()}
                            >
                                &#10007;
                            </span>
                            <h1 className="text-2xl font-bold mb-5">
                                Detail Menu Packaging
                            </h1>

                            <form>
                                <div className="text-left">
                                    <MenuPackagingSelectOptionWidget
                                        menuToPackagingId={vm.selectedDetail?.id ?? 0}
                                        id={vm.selectedItemId ?? 0}
                                        label="Menu"
                                        selectOptionsItem={vm.itemsMenu}
                                        selectId="menuSelect"
                                        onSelectChange={vm.onSelectItemChange}
                                    />
                                    
                                    <MenuPackagingSelectOptionWidget
                                        menuToPackagingId={vm.selectedDetail?.id ?? 0}
                                        id={vm.selectedPackagingId ?? 0}
                                        label="Packaging"
                                        selectOptionsItem={vm.itemsPackaging}
                                        selectId="packagingSelect"
                                        onSelectChange={vm.onSelectPackagingChange}
                                    />

                                    <label className="relative">Quantity</label>
                                    <input
                                        id="inputQuantity"
                                        type="number"
                                        className="border rounded-md p-2 w-full"
                                        style={{
                                            appearance: "none",
                                            WebkitAppearance: "none",
                                            MozAppearance: "textfield",
                                            fontSize: "1rem",
                                            padding: "0.5rem",
                                            borderWidth: "1px",
                                            borderColor: "#ccc",
                                        }}
                                        min={0}
                                        defaultValue={vm.selectedQuantity}
                                        onChange={(e) => { vm.onInputQuantityChange(vm.selectedId, parseInt(e.target.value)) }}
                                        onKeyDown={(e) => { if (e.code === 'Minus') e.preventDefault(); }}
                                    />
                                </div>

                                <button 
                                    type="submit" 
                                    className="mt-5 px-4 py-2 bg-indigo-800 text-white rounded"
                                    onClick={
                                        (e) => { 
                                            e.preventDefault();
                                            vm.submit();
                                        }
                                    }
                                >
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
