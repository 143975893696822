import "./service_areas_map.css";
import { GoogleMap, useJsApiLoader, Polygon, Marker } from '@react-google-maps/api';
import Scaffold from '../commons/scaffold/scaffold';
import SizedDiv from '../commons/ui/sized_div';
import LocationUtils from '../commons/utils/location_utils';
import FormButton from "../commons/ui/form_button";
import ViewController from "../commons/base/view_controller";
import ServiceAreaMapState from "./view_models/service_areas_map_state";
import ServiceAreaMapViewModel from "./view_models/service_areas_map_view_model";

export default class ServiceAreasMapScreen extends ViewController<{}, ServiceAreaMapState, ServiceAreaMapViewModel> {
    constructor(props: {}) {
        super(props, new ServiceAreaMapViewModel());
    }

    render() {
        return <Scaffold
            isShowLoading={this.vmState.isLoading}
            showSnackbar={this.vmState.errorSnackbar}
            loadingType="uena_logo"
        >
            <SizedDiv height={52} />
            <div>
                <ServiceAreasMap polygons={this.vmState.polygons} coordinate={this.vmState.coordinate} />
                {
                    this.vmState.nearestLocation &&
                    <div id="ServiceAreaInfo" style={{
                        backgroundColor:this.vmState.nearestLocation.isOnCoverage ? "green" : "red"
                    }}>
                        <p>{this.vmState.nearestLocation.isOnCoverage ? "Masuk area servis" : "Tidak masuk area service, berikut outlet terdekat"}</p>
                        <p>{this.vmState.nearestLocation.name}</p>
                        <p>{Math.round(this.vmState.nearestLocation.distance) / 1000} KM</p>
                    </div>
                }
                <div id="ServiceAreasForm">
                    <p className="FormLabel">Gmaps Link</p>
                    <div id="ServiceAreasLinksTextFieldArea">
                        <input 
                            className="FormTextInput" 
                            type="text" 
                            placeholder="Masukkan Link Gmaps disini" 
                            value={this.vmState.gmapLink}
                            onChange={(event) => this.viewModel.onAnyTextChanged({gmapsLinkEvent: event})}
                        />
                        <FormButton 
                            title="Periksa"
                            isLoading={this.vmState.isCheckGmapLinkLoading}
                            action={() => this.viewModel.checkGmapLink()}
                        />
                    </div>
                    <p className="FormLabel">Coordinate</p>
                    <div id="ServiceAreasLinksTextFieldArea">
                        <input 
                            className="FormTextInput" 
                            type="text" 
                            placeholder="Longitude" 
                            value={this.vmState.longitude}
                            onChange={(event) => this.viewModel.onAnyTextChanged({longitudeEvent: event})}
                        />
                        <input 
                            className="FormTextInput" 
                            type="text" 
                            placeholder="Latitude" 
                            value={this.vmState.latitude}
                            onChange={(event) => this.viewModel.onAnyTextChanged({latitudeEvent: event})}
                        />
                        <FormButton 
                            title="Periksa"
                            action={() => this.viewModel.checkCoordinate({
                                latitude:this.vmState.latitude,
                                longitude:this.vmState.longitude
                            })}
                        />
                    </div>
                </div>
            </div>
        </Scaffold>;
    }
}

export function ServiceAreasMap(props: {
    polygons: string[],
    coordinate?: {
        longitude: number;
        latitude: number;
    }
}) {
    const center = {
        lat: -6.187761,
        lng: 106.836096
    };

    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyD74OzwkEB0bqBRHshiRE15ePUnsKKBH50"
    });

    return isLoaded ? <GoogleMap
        mapContainerStyle={{
            width: "100vw",
            height: "calc(100vh - 52px)"
        }}
        center={props.coordinate ? {
            lat: props.coordinate.latitude,
            lng: props.coordinate.longitude
        } : center}
        zoom={13}
        options={{
            clickableIcons:false,
            mapTypeControl:false,
            fullscreenControl:false,
            streetViewControl:false
        }}
    >
        {
            props.polygons.map((polygon, index) => 
                <Polygon 
                    key={index}
                    path={LocationUtils.convertToCoordinate(polygon)}
                    options={{
                        strokeColor:"green",
                        fillColor:"green",
                        strokeWeight:1
                    }}
                    editable={false}
                />
            )
        }
        {
            props.coordinate &&
            <Marker 
                position={{
                    lat: props.coordinate.latitude,
                    lng: props.coordinate.longitude
                }} 
                label={{
                    text: "Lokasi",
                    fontWeight: "700",
                    fontSize: "22px",
                }}
            />
        }
    </GoogleMap> : <></>;
}