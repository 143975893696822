import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsToVendorViewModel from "../view_models/adjustment_forms_to_vendor_view_model";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default class AdjustmentFormsToVendorFilterWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsToVendorViewModel>>
                {(vm) => (
                    <div className="flex flex-wrap items-center mb-5 space-y-3 md:space-y-0">
                        {/* Dropdown select */}
                        <div className="relative mr-2">
                            <Select
                                options={vm.locationOptions.map((option) => ({
                                    value: option.locationId,
                                    label: option.locationName,
                                }))}
                                placeholder={"Pilih Lokasi Pengirim"}
                                isClearable
                                isSearchable
                                onChange={(selectedOption) => {
                                    vm.setFilterLocationId(
                                        selectedOption?.value
                                    );
                                    vm.fetchTransferToVendorList(
                                        vm.filterLocationId
                                    );
                                }}
                            />
                        </div>
                        {/* Search Code */}
                        <div className="relative mr-2">
                            <input
                                type="text"
                                placeholder="Cari Kode Submit ..."
                                className="border border-gray-300 rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={(e) => {
                                    const submitCode = e.target.value;
                                    const filteredToVendorList =
                                        vm.transferToVendorList.filter((data) =>
                                            data.submitCode
                                                .toLowerCase()
                                                .includes(
                                                    submitCode.toLowerCase()
                                                )
                                        );
                                    vm.setFilteredTransferToVendorList(
                                        filteredToVendorList
                                    );
                                }}
                            />
                            <FontAwesomeIcon
                                icon={faSearch}
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                            />
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
