import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsVendorReceiptViewModel from "../view_models/adjustment_forms_vendor_receipt_view_model";
import StringUtils from "../../../../commons/utils/string_utils";

export default class AdjustmentFormsVendorReceiptTableWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsVendorReceiptViewModel>>
                {(vm) => (
                    <div className="overflow-x-auto">
                        <table className="table-fixed min-w-full border-collapse text-center">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-1.5 border border-gray-300 w-1/5">
                                        Kode Submit
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/5">
                                        Lokasi Penerima & Nama Crew
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/5">
                                        Vendor
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/5">
                                        Tanggal Diterima
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {vm.filteredReceiptDataList?.map(
                                    (data, index) => (
                                        <tr
                                            key={index}
                                            className="odd:bg-white even:bg-gray-100 cursor-pointer text-center hover:bg-slate-200"
                                            onClick={() => {
                                                vm.fetchReceiptDetail(
                                                    data.formId
                                                );
                                                vm.setFormId(data.formId);
                                                vm.setFormLocationId(
                                                    data.locationId
                                                );
                                                vm.setFormReceivedAt(
                                                    data.receivedAt
                                                );
                                                vm.setIsShowModal(true);
                                            }}
                                        >
                                            <td className="p-2 border border-gray-300">
                                                {data.submitCode ?? " - "}
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <p className="font-bold text-center">
                                                    {data.locationReceived ??
                                                        "-"}
                                                </p>
                                                <p className="text-center">
                                                    {data.respondentName ?? "-"}
                                                </p>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                {data.vendor ?? " - "}
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                {StringUtils.toIndonesianDate(
                                                    new Date(data.receivedAt)
                                                ) ?? " - "}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
