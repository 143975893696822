import React from "react";
import { VMProvider } from "../../commons/base/providers/provider";
import LoadingStateView from "../../commons/ui/loading/loading_state_view";
import ErrorStateView from "../../commons/ui/error/error_state_view";
import MenuPackagingViewModel from "./view_models/menu_packaging_view_model";
import MenuPackagingWidget from "./widgets/menu_packaging_widget";

export default class MenuPackagingScreen extends React.Component {
    render() {
        return (
            <VMProvider create={() => new MenuPackagingViewModel()}>
                {(vm) => {
                    if (vm.isLoading) {
                        return (
                            <LoadingStateView backgroundType="semi_transparent" />
                        );
                    } else if (vm.errorMessage?.length > 0) {
                        return (
                            <ErrorStateView
                                message={vm.errorMessage}
                                action={() => vm.retryAction()}
                            />
                        );
                    }

                    return <MenuPackagingWidget />;
                }}
            </VMProvider>
        );
    }
}
