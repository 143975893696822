import ApiService from "../../../commons/api/api_service";
import BaseResponse from "../../../commons/api/base_response";
import { Endpoints } from "../../../commons/api/endpoints";
import { ProviderVM } from "../../../commons/base/providers/view_model";
import { getLocationQueries } from "../../../commons/utils/utils";
import QueueDetailRequest from "../models/queue_detail_request";
import QueueDetailResponse from "../models/queue_detail_response";
import QueueOutletListResponse from "../models/queue_outlet_list_response";

export default class OutletQueuePreviewViewModel extends ProviderVM {
    isLoading: boolean = true;
    errorMessage: string = "";
    outletList: string[] = [];
    inProgressQueueNumbers: string[] = [];
    readyToPickupHistoryNumbers: string[] = [];
    selectedOutlet = ""

    get latestReadyToPickupNumber(): string {
        return this.readyToPickupHistoryNumbers[this.readyToPickupHistoryNumbers.length - 1];
    }

    get readyToPickupNumbersLabel(): string {
        if (this.readyToPickupHistoryNumbers.length === 0) return "-";

        let label = "";

        this.readyToPickupHistoryNumbers.forEach((value) => {
            if (label === "") {
                label += value;
            } else {
                label += `, ${value}`
            }
        });

        return label;
    }

    get onCookingNumbersLabel(): string {
        if (this.inProgressQueueNumbers.length === 0) return "-";

        let label = "";

        this.inProgressQueueNumbers.forEach((value) => {
            if (label === "") {
                label += value;
            } else {
                label += `, ${value}`
            }
        });

        return label;
    }

    constructor() {
        super();

        this.selectedOutlet = getLocationQueries()["outlet"] ?? "";

        if (this.selectedOutlet.length > 0) {
            this.loadUpdatedDetail();
        } else {
            this.loadListOfOutlet();
        }
    }

    loadListOfOutlet() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = "";
        });

        ApiService.fetch<{}, BaseResponse<QueueOutletListResponse>>(Endpoints.queueOutletList, {
            onSuccess: (response) => {
                if (response.data) {
                    this.emit(() => {
                        this.isLoading = false;
                        this.outletList = response.data?.listOfOutlets ?? [];
                    });
                } else {
                    this.emit(() => {
                        this.errorMessage = response.message;
                        this.isLoading = false;
                    });
                }
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.errorMessage = `${error}`;
                    this.isLoading = false;
                });
            }
        });
    }

    loadUpdatedDetail() {
        this.emit(() => {
            if (!(this.readyToPickupHistoryNumbers.length > 0)) {
                this.isLoading = true;
            }

            this.errorMessage = "";
        });

        ApiService.fetch<QueueDetailRequest, BaseResponse<QueueDetailResponse>>(Endpoints.queueViewer, {
            parameters: {
                outlet: this.selectedOutlet
            },
            onSuccess: (response) => {
                if (response.data) {
                    this.emit(() => {
                        const latestReadyToPickupQueueNumber = response.data?.latestReadyToPickupQueueNumber;
                        this.isLoading = false;
                        this.inProgressQueueNumbers = response.data?.inProgressQueueNumbers ?? this.inProgressQueueNumbers;

                        if (latestReadyToPickupQueueNumber && !this.readyToPickupHistoryNumbers.includes(latestReadyToPickupQueueNumber)) {
                            this.readyToPickupHistoryNumbers = this.readyToPickupHistoryNumbers.concat(latestReadyToPickupQueueNumber);
                        }
                    });
                } else {
                    this.emit(() => {
                        if (this.readyToPickupHistoryNumbers.length > 0) {
                            this.errorMessage = response.message;
                        }

                        this.isLoading = false;
                    });
                }

                setTimeout(() => this.loadUpdatedDetail(), 10000);
            },
            onFailure: (error) => {
                this.emit(() => {
                    if (this.readyToPickupHistoryNumbers.length > 0) {
                        this.errorMessage = `${error}`;
                    }

                    this.isLoading = false;
                });

                setTimeout(() => this.loadUpdatedDetail(), 10000);
            }
        });
    }

    selectOutlet(outlet: string) {
        window.location.href = window.location.href + `${Object.keys(getLocationQueries()).length === 0 ? "?" : "&"}outlet=${outlet.replaceAll(" ", "%20")}`;
    }

    retryAction() {
        this.selectedOutlet.length > 0 ? this.loadUpdatedDetail() : this.loadListOfOutlet();
    }
}