import React from "react";
import { VMProvider } from "../../../commons/base/providers/provider";
import AdjustmentFormsProductionViewModel from "./view_models/adjustment_forms_production_view_model";
import Scaffold from "../../../commons/scaffold/scaffold";
import AdjustmentFormsProductionTableWidget from "./widgets/adjustment_forms_production_table_widget";
import AdjustmentFormsProductionModalWidget from "./widgets/adjustment_forms_production_modal_widget";

export default class AdjustmentFormsProductionScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new AdjustmentFormsProductionViewModel()}>
                {(vm) => (
                    <Scaffold
                        isShowLoading={vm.isLoading}
                        loadingBackgroundType="semi_transparent"
                        showErrorView={vm.showErrorView}
                        showSnackbar={vm.showSnackbar}
                    >
                        <div className=" bg-slate-500 overflow-x-auto min-h-screen p-5 mt-10 relative">
                            <h1 className="text-white text-3xl text-center font-bold flex-grow m-5">
                                Adjust Form Produksi
                            </h1>

                            {vm.isShowModal && (
                                <AdjustmentFormsProductionModalWidget />
                            )}
                            <AdjustmentFormsProductionTableWidget />
                        </div>
                    </Scaffold>
                )}
            </VMProvider>
        );
    }
}
