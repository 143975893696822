export enum Endpoints {
    // App Related Web View
    mobileQrisCheckStatus = "/app/qris_check",
    mobileQrisDisplay = "/app/qris_display",
    mobileQrisCheckOrder = "/app/qris_order_check",
    referralDetail = "/app/referral_detail",
    // Coverage Area
    coverageAreaPolygons = "/outlet/coverage_polygons",
    coverageAreaMapsLinkToCoordinate = "/outlet/maps_link_to_coordinate",
    // CRM
    notificationWebViewCreation = "/bo/create_notification_web",
    notificationWebViewDisplay = "/bo/get_notification_web",
    pushCrm = "/crm/push",
    redirectWebCreation = "/bo/create_redirect_web",
    redirectWebGet = "/bo/get_redirect_web",
    // Driver
    driverChangeStatus = "/driver/change_status",
    driverCancelDelivery = "/driver/cancel_delivery",
    driverDeliveryDone = "/driver/delivery_done",
    driverSignIn = "/driver/sign_in",
    driverGetOrderList = "/driver/get_orders",
    driverInputOrder = "/driver/input_order",
    driverOrderDetail = "/driver/order_detail",
    // Order Form
    orderFormSubmitGerobak = "/bo/form/create_order/submit_gerobak",
    orderFormAutoComplete = "/bo/form/create_order/auto_complete",
    orderFormGetContent = "/bo/form/create_order/contents",
    orderSaveDraft = "/bo/form/create_order/save_draft",
    orderFormSubmitRequest = "/bo/form/create_order/submit",
    orderFormValidateSubmission = "/bo/form/create_order/validate_submission",
    orderFormVoucherChecker = "/bo/form/create_order/voucher_checker",
    orderSendOtp = "/bo/form/create_order/send_otp_voucher",
    queueOutletList = "/bo/ops_view/queue_outlet_list",
    queueViewer = "/bo/ops_view/queue_viewer",
    orderPreview = "/bo/ops_view/order_preview",
    // Upload Image
    uploadImage = "/upload/image",
    // Completion
    completionInfo = "/bo/dashboard/info",
    // Inventory
    getSoFormSubmitHistory = "/inventory/v2/so/form_get_history",
    getWasteFormSubmitHistory = "/inventory/v2/waste/form_get_history",
    getCompleteness = "/inventory/v2/completeness_so_waste_history",
    getTransferHistory = "/inventory/v2/transfer/history",
    getTransferFixItems = "/inventory/v2/transfer/get_fix_items",
    uploadMapWeeklyCsv = "/inventory/v2/raw_mat_csv",
    getReceiptHistory = "/inventory/v2/receipt/history",
    getReceiptHistoryLocationOptions = "/inventory/v2/receipt/history/location_options",
    getReceiptHistoryDetail = "/inventory/v2/receipt/history/detail",
    inventoryGetMenuPackaging = "/inventory/v2/menu_packaging/",
    inventoryCreateMenuPackaging = "/inventory/v2/menu_packaging/create",
    inventoryDeleteMenuPackaging = "/inventory/v2/menu_packaging/delete",
    inventoryEditMenuPackaging = "/inventory/v2/menu_packaging/edit",
    bomDashboardHomepage = "/inventory/v2/bom",
    getBomDetail = "/inventory/v2/bom/detail",
    getBomItemsAndUnits = "/inventory/v2/bom/items-and-units",
    getExistingBomResultItemIds = "/inventory/v2/bom/existing-results",
    saveBom = "/inventory/v2/bom/save",
    getGeneralDOI = "/inventory/v2/general_doi",
    getOutletStock = "/inventory/v2/stock",
    getAllOutletStock = "/inventory/v2/get_all_outlet_stock",
    getMasterItemList = "/inventory/v2/master_item_dashboard",
    getMasterItemDetail = "/inventory/v2/master_item_dashboard/detail",
    createMasterItem = "/inventory/v2/master_item_dashboard",
    updateMasterItem = "/inventory/v2/master_item_dashboard",
    adjustmentFormSubmit = "/inventory/v2/adjustment",
    adjustmentFormGetProductionList = "/inventory/v2/adjustment/list-production",
    adjustmentFormGetProductionDetail = "/inventory/v2/adjustment/detail-production",
    adjustmentFormGetSOList = "/inventory/v2/adjustment/list-so",
    adjustmentFormGetSODetail = "/inventory/v2/adjustment/detail-so",
    adjustmentFormGetWasteList = "/inventory/v2/adjustment/list-waste",
    adjustmentFormGetWasteDetail = "/inventory/v2/adjustment/detail-waste",
    adjustmentFormGetReceiptList = "/inventory/v2/adjustment/list-receipt",
    adjustmentFormGetReceiptDetail = "/inventory/v2/adjustment/detail-receipt",
    adjustmentFormGetTransferList = "/inventory/v2/adjustment/list-transfer",
    adjustmentFormGetTransferDetail = "/inventory/v2/adjustment/detail-transfer",
    adjustmentFormGetToVendorList = "/inventory/v2/adjustment/list-to-vendor",
    adjustmentFormGetToVendorDetail = "/inventory/v2/adjustment/detail-to-vendor",
    adjustmentFormGetItemOptions = "/inventory/v2/adjustment/item-options",
    adjustmentFormGetLocationOptions = "/inventory/v2/adjustment/location-options",
    adjustmentFormGetVendorsOptions = "/inventory/v2/adjustment/vendors-options",
    // sold out dashboard
    soldOutDashboard = "/bo/dashboard/info_sold_out",
    // delete account
    getOTP = "/users/login",
    deleteAccount = "/users/delete_user_by_phone",
}
