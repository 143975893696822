import React from "react";
import { VMProvider } from "../../../commons/base/providers/provider";
import AdjustmentFormsWasteViewModel from "./view_models/adjustment_forms_waste_view_model";
import Scaffold from "../../../commons/scaffold/scaffold";
import AdjustmentFormsWasteTableWidget from "./widgets/adjustment_forms_waste_table_widget";
import AdjustmentFormsWasteFilterWidget from "./widgets/adjustment_forms_waste_filter_widget";
import AdjustmentFormsWasteModalWidget from "./widgets/adjustment_forms_waste_modal_widget";

export default class AdjustmentFormsWasteScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new AdjustmentFormsWasteViewModel()}>
                {(vm) => (
                    <Scaffold
                        isShowLoading={vm.isLoading}
                        loadingBackgroundType="semi_transparent"
                        showSnackbar={vm.showSnackbar}
                        showErrorView={vm.showErrorView}
                    >
                        <div className=" bg-slate-500 overflow-x-auto min-h-screen p-5 mt-10 relative">
                            <h1 className="text-white text-3xl text-center font-bold flex-grow m-5">
                                Adjust Form Waste
                            </h1>

                            <AdjustmentFormsWasteFilterWidget />
                            {vm.isShowModal && (
                                <AdjustmentFormsWasteModalWidget />
                            )}
                            <AdjustmentFormsWasteTableWidget />
                        </div>
                    </Scaffold>
                )}
            </VMProvider>
        );
    }
}
