import { Component, ReactNode } from "react";
import { ProviderVM } from "./view_model";
import { ViewModelContext } from "./vm_context";

interface VMBuilderProps<VM extends ProviderVM> {
    children?: (viewModel: VM) => ReactNode;
}

export class VMBuilder<VM extends ProviderVM> extends Component<VMBuilderProps<VM>> {
    private vmContext: React.Context<VM>;

    constructor(props: VMBuilderProps<VM>) {
        super(props);
        this.vmContext = ViewModelContext.read<VM>();
    }

    render(): ReactNode {
        return <this.vmContext.Consumer>
            {
                (vm) => {
                    return this.props.children ? this.props.children(vm) : <></>;
                }
            }
        </this.vmContext.Consumer>;
    }
}