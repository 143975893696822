import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import MasterItemDashboardViewModel from "../view_models/master_item_dashboard_view_model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencil, faX } from "@fortawesome/free-solid-svg-icons";
import StringUtils from "../../../commons/utils/string_utils";

export default class MasterItemDataTableWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<MasterItemDashboardViewModel>>
                {(vm) => (
                    <div className="overflow-x-auto">
                        <table className="table-fixed min-w-full border-collapse text-center">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-1.5 border border-gray-300 w-2/6">
                                        Material Code
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-2/6">
                                        Material Type
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-2/6">
                                        Name
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        Small Unit
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        Big Unit
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        Big to Small Unit Conversion
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        CK Item
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        Outlet Item
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        WH Item
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        SO Supplies Item
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        Packaging Item
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        Bumbu Item
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/6">
                                        WH Storage Type
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-2/6">
                                        Item Category
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        Yield
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        Sales Price
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/12">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {vm.masterItemList
                                    .filter((masterItem) => masterItem.isActive)
                                    .map((masterItem, index) => (
                                        <tr
                                            key={index}
                                            className="bg-white text-center hover:bg-slate-200"
                                        >
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.materialCode ??
                                                    " - "}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {StringUtils.snakeCaseToNormal(
                                                    masterItem.materialType ??
                                                        ""
                                                )}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.item ?? " - "}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.smallUnit ?? " - "}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.bigUnit ?? " - "}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.bigToSmallUnitConversion ??
                                                    " - "}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.isCkItem ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="text-green-400"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faX}
                                                        className="text-red-400"
                                                    />
                                                )}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.isOutletItem ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="text-green-400"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faX}
                                                        className="text-red-400"
                                                    />
                                                )}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.isWhItem ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="text-green-400"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faX}
                                                        className="text-red-400"
                                                    />
                                                )}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.isSoSuppliesItem ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="text-green-400"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faX}
                                                        className="text-red-400"
                                                    />
                                                )}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.isPackaging ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="text-green-400"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faX}
                                                        className="text-red-400"
                                                    />
                                                )}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.isBumbuItem ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="text-green-400"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faX}
                                                        className="text-red-400"
                                                    />
                                                )}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.whStorageType ??
                                                    " - "}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.itemCategory ??
                                                    " - "}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.yieldNum ?? " - "}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                {masterItem.salesPrice ?? " - "}
                                            </td>
                                            <td className="p-3 border border-gray-300">
                                                <button
                                                    className="px-3 py-1 rounded-md bg-purple-500 hover:bg-purple-700"
                                                    onClick={() => {
                                                        vm.setModalAction(
                                                            "UPDATE"
                                                        );
                                                        vm.populateModal(
                                                            masterItem.id
                                                        );
                                                        vm.openModal();
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        className="text-white"
                                                        icon={faPencil}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
