import CompletenessViewModel from "./view_models/completeness_view_model";
import LoadingStateView from "../../commons/ui/loading/loading_state_view";
import ErrorStateView from "../../commons/ui/error/error_state_view";
import React from "react";
import { VMProvider } from "../../commons/base/providers/provider";
import CompletenessInventoryWidget from "./widgets/completeness_inventory_widget";

export default class CompletenessScreen extends React.Component {
    render() {
        return (
            <VMProvider create={() => new CompletenessViewModel()}>
                {(vm) => {
                    if (vm.isLoading) {
                        return (
                            <LoadingStateView backgroundType="semi_transparent" />
                        );
                    } else if (vm.errorMessage?.length > 0) {
                        return (
                            <ErrorStateView
                                message={vm.errorMessage}
                                action={() => vm.retryAction()}
                            />
                        );
                    }

                    return <CompletenessInventoryWidget />;
                }}
            </VMProvider>
        );
    }
}
