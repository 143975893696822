import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import MasterItemDashboardViewModel from "../view_models/master_item_dashboard_view_model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

export default class MasterItemModalWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<MasterItemDashboardViewModel>>
                {(vm) => (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 max-h-screen p-2">
                        <div className="relative bg-white rounded-lg shadow-lg mt-10 pt-5 px-5 w-full max-w-3xl max-h-[83vh] overflow-y-auto">
                            <button
                                className="text-red-500 absolute right-3 top-3"
                                onClick={() => {
                                    vm.resetModal();
                                }}
                            >
                                <FontAwesomeIcon icon={faX} />
                            </button>
                            <h2 className="text-2xl font-bold mb-4">
                                {vm.modalAction === "CREATE"
                                    ? "Add New Master Item"
                                    : "Edit Master Item"}
                            </h2>

                            {/* Item Name */}
                            <div className="mb-4">
                                <label
                                    htmlFor="item-name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Item Name{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="item-name"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    placeholder="Enter item name"
                                    onChange={(e) => {
                                        console.log("name : ", e.target.value);
                                        vm.setModalItemName(e.target.value);
                                    }}
                                    value={vm.modalItemName ?? undefined}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-2 items-center">
                                {/* Material Type */}
                                <div className="mb-4">
                                    <label htmlFor="material-type">
                                        Material Type{" "}
                                        <span className="text-red-600">*</span>
                                    </label>
                                    <select
                                        className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                        placeholder="Status"
                                        onChange={(e) =>
                                            vm.setModalItemMaterialType(
                                                e.target.value
                                            )
                                        }
                                    >
                                        {vm.materialTypeOptions.map(
                                            (option) =>
                                                option !==
                                                    "Select Material Type" && (
                                                    <option
                                                        key={option}
                                                        value={option}
                                                        selected={
                                                            option ===
                                                            vm.modalItemMaterialType
                                                        }
                                                    >
                                                        {option}
                                                    </option>
                                                )
                                        )}
                                    </select>
                                </div>

                                {/* Small Unit */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="small-unit"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Small Unit{" "}
                                        <span className="text-red-600">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="small-unit"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        placeholder="Enter small unit name"
                                        onChange={(e) => {
                                            vm.setModalSmallUnit(
                                                e.target.value
                                            );
                                        }}
                                        value={vm.modalSmallUnit ?? undefined}
                                    />
                                </div>

                                {/* Big Unit */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="big-unit"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Big Unit{" "}
                                    </label>
                                    <input
                                        type="text"
                                        id="big-unit"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        placeholder="Enter big unit name"
                                        onChange={(e) => {
                                            vm.setModalBigUnit(e.target.value);
                                        }}
                                        value={vm.modalBigUnit ?? undefined}
                                    />
                                </div>

                                {/* Big to Small Unit Conversion */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="big-to-small-unit-conv"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Big to Small Unit Conversion{" "}
                                        <span className="text-red-600">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min={0}
                                        id="big-to-small-unit-conv"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        placeholder="Enter big to small unit conversion"
                                        onInput={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            const value = e.target.value;
                                            if (value.includes("-")) {
                                                e.target.value = value.replace(
                                                    "-",
                                                    ""
                                                );
                                            }
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const numericValue = Number(value);
                                            if (numericValue >= 0 || !value) {
                                                vm.setModalBigToSmallUnitConversion(
                                                    value
                                                        ? numericValue
                                                        : undefined
                                                );
                                            }
                                        }}
                                        value={
                                            vm.modalBigToSmallUnitConversion ??
                                            undefined
                                        }
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 items-center">
                                {/* Active */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="is-active"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Active
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="is-active"
                                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => {
                                            vm.setModalIsActive(
                                                e.target.checked
                                            );
                                        }}
                                        checked={vm.modalIsActive}
                                    />
                                </div>

                                {/* CK Item */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="is-ck-item"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        CK Item
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="is-ck-item"
                                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => {
                                            vm.setModalIsCkItem(
                                                e.target.checked
                                            );
                                        }}
                                        checked={vm.modalIsCkItem}
                                    />
                                </div>

                                {/* Outlet Item */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="is-outlet-item"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Outlet Item
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="is-outlet-item"
                                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => {
                                            vm.setModalIsOutletItem(
                                                e.target.checked
                                            );
                                        }}
                                        checked={vm.modalIsOutletItem}
                                    />
                                </div>

                                {/* WH Item */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="is-wh-item"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        WH Item
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="is-wh-item"
                                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => {
                                            const value = e.target.checked;
                                            if (!value) {
                                                vm.setModalWHStorageType(
                                                    undefined
                                                );
                                            }
                                            vm.setModalIsWHItem(
                                                e.target.checked
                                            );
                                        }}
                                        checked={vm.modalIsWHItem}
                                    />
                                </div>

                                {/* SO Item */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="is-so-item"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        SO Item
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="is-so-item"
                                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => {
                                            vm.setModalIsSOSuppliesItem(
                                                e.target.checked
                                            );
                                        }}
                                        checked={vm.modalIsSOSuppliesItem}
                                    />
                                </div>

                                {/* Packaging Item */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="is-packaging-item"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Packaging Item
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="is-packaging-item"
                                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => {
                                            vm.setModalIsPackagingItem(
                                                e.target.checked
                                            );
                                        }}
                                        checked={vm.modalIsPackagingItem}
                                    />
                                </div>

                                {/* Bumbu Item */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="is-bumbu-item"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Bumbu Item
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="is-bumbu-item"
                                        className="mt-1 block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => {
                                            vm.setModalIsBumbuItem(
                                                e.target.checked
                                            );
                                        }}
                                        checked={vm.modalIsBumbuItem}
                                    />
                                </div>
                            </div>
                            {/* WH Storage */}
                            <div
                                className={`mb-4 transition ${
                                    !vm.modalIsWHItem ? "hidden" : ""
                                }`}
                            >
                                <label
                                    htmlFor="wh-storage"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    WH Storage
                                </label>
                                <select
                                    className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Status"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        vm.setModalWHStorageType(
                                            value === "None" ? undefined : value
                                        );
                                    }}
                                >
                                    {vm.whStorageTypeOptions.map((option) => (
                                        <option
                                            key={option}
                                            value={option}
                                            selected={
                                                option === vm.modalWHStorageType
                                            }
                                        >
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Item Category */}
                            <div className="mb-4">
                                <label
                                    htmlFor="item-category"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Item Category
                                </label>
                                <select
                                    className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Status"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        vm.setModalItemCategory(
                                            value === "None" ? undefined : value
                                        );
                                    }}
                                >
                                    {vm.itemCategoriesOptions.map((option) => (
                                        <option
                                            key={option}
                                            value={option}
                                            selected={
                                                option === vm.modalItemCategory
                                            }
                                        >
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Yield */}
                            <div className="mb-4">
                                <label
                                    htmlFor="yield-num"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Yield
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    placeholder="Enter yield"
                                    onInput={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const value = e.target.value;
                                        if (value.includes("-")) {
                                            e.target.value = value.replace(
                                                "-",
                                                ""
                                            );
                                        }
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const numericValue = Number(value);
                                        if (numericValue >= 0 || !value) {
                                            vm.setModalYieldNum(
                                                value ? numericValue : undefined
                                            );
                                        }
                                    }}
                                    value={vm.modalYieldNum ?? undefined}
                                />
                            </div>

                            {/* Sales Price */}
                            <div
                                className={`mb-4 ${
                                    vm.modalItemMaterialType !== "Menu"
                                        ? "hidden"
                                        : ""
                                }`}
                            >
                                <label
                                    htmlFor="sales-price"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Sales Price
                                </label>
                                <input
                                    type="number"
                                    id="sales-price"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    placeholder="Enter sales price"
                                    onInput={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const value = e.target.value;
                                        if (value.includes("-")) {
                                            e.target.value = value.replace(
                                                "-",
                                                ""
                                            );
                                        }
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const numericValue = Number(value);
                                        if (numericValue >= 0 || !value) {
                                            vm.setModalSalesPrice(
                                                value ? numericValue : undefined
                                            );
                                        }
                                    }}
                                    value={vm.modalSalesPrice ?? undefined}
                                />
                            </div>
                            {/* Buttons */}
                            <div className="flex justify-end mt-4 sticky bottom-0 bg-white py-4">
                                <button
                                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
                                    onClick={() => vm.resetModal()}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="px-4 py-2 bg-blue-500 text-white rounded"
                                    onClick={() => vm.submitMasterItemData()}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
