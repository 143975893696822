import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import VendorReceiptHistoryVM from "../view_models/vendor_receipt_history_vm";
import FormButton from "../../../commons/ui/form_button";
import StringUtils from "../../../commons/utils/string_utils";

export default class VendorReceiptHistoryTable extends React.Component {
    renderTable(vm: VendorReceiptHistoryVM) {
        const tableBodyStyle =
            "table-cell size-0 border border-slate-400 px-0 py-2";

        if (vm.filteredForms) {
            const forms = vm.filteredForms;
            const rows = forms.map((form, i) => {
                // const receiveDate = vm.formatTimestamp(new Date(form.received_at));
                const receiveDate = new Date(form.received_at);
                const formattedDate = StringUtils.toIndonesianDate(receiveDate);
                return (
                    <div className="table-row" key={i}>
                        <div className={tableBodyStyle}>{i + 1}</div>
                        <div className={tableBodyStyle}>{form.submit_code}</div>
                        <div className={tableBodyStyle}>{form.vendor}</div>
                        <div className={tableBodyStyle}>
                            {form.location_received}
                        </div>
                        <div className={tableBodyStyle}>{formattedDate}</div>
                        <div className={tableBodyStyle}>
                            {form.respondent_name}
                        </div>
                        <div className={tableBodyStyle + " w-14 md:w-16"}>
                            <div className="scale-75">
                                <FormButton
                                    title="Detail"
                                    isLoading={vm.isLoadingDetail}
                                    backgroundColor="#24A0ED"
                                    textColor="#FEFEFE"
                                    action={() => vm.loadFormDetail(form)}
                                />
                            </div>
                        </div>
                    </div>
                );
            });

            return <div className="table-row-group">{rows}</div>;
        }
    }

    render(): React.ReactNode {
        return (
            <VMBuilder<VendorReceiptHistoryVM>>
                {(vm) => {
                    const tableHeadStyle =
                        "table-cell size-auto border border-slate-400 px-0 py-2 bg-slate-700 text-slate-50";

                    return (
                        <>
                            <div className="table text-slate-800 w-full border-2 border-double text-[9px] md:text-base">
                                <div className="table-header-group">
                                    <div className="table-row font-bold">
                                        <div className={tableHeadStyle}>#</div>
                                        <div className={tableHeadStyle}>
                                            Kode
                                        </div>
                                        <div className={tableHeadStyle}>
                                            Vendor Pengirim
                                        </div>
                                        <div className={tableHeadStyle}>
                                            Lokasi Penerima
                                        </div>
                                        <div className={tableHeadStyle}>
                                            Waktu Diterima
                                        </div>
                                        <div className={tableHeadStyle}>
                                            Nama Penerima
                                        </div>
                                        <div className={tableHeadStyle}>
                                            Aksi
                                        </div>
                                    </div>
                                </div>
                                {this.renderTable(vm)}
                            </div>
                            {vm.filteredForms &&
                            vm.filteredForms.length === 0 ? (
                                <div className="w-full border-2 border-double p-2">
                                    <span>Data tidak tersedia</span>
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                    );
                }}
            </VMBuilder>
        );
    }
}
