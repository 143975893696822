import React from "react";
import { VMBuilder } from "../../commons/base/providers/builder";
import StockDashboardVM from "../view_models/stock_dashboard_vm";

interface Props {
    outlet: string
}

const DOI_THRESHOLD = 2;

export default class StockDashboard extends React.Component<Props> {

    renderTableRows(vm: StockDashboardVM) {
        const tableBodyStyle = "table-cell size-0 border border-slate-200 px-0 py-2";
        if (vm.data.length > 0) {
            return vm.data.map((item, i) => {
                const warning = (item.doi ?? DOI_THRESHOLD+1) <= DOI_THRESHOLD ? " bg-yellow-200" : "";
                return (
                    <div className={"table-row" + warning} key={i}>
                        <div className={tableBodyStyle + " w-1/4"}>{item.matCode}</div>
                        <div className={tableBodyStyle}>{item.name}</div>
                        <div className={tableBodyStyle + " w-1/8"}>
                            {item.doi?.toFixed(1)}
                        </div>
                        <div className={tableBodyStyle + " w-1/4"}>
                            <div className="w-full flex justify-evenly">
                            <span>{item.qty}</span>
                            <span>{item.smallUnit}</span>
                            </div>
                        </div>
                    </div>
                )
            });
        }
    }

    render(): React.ReactNode {
        const tableHeadStyle = `table-cell size-auto border border-slate-400 
            px-0 py-2 bg-slate-700 text-slate-50
            font-bold text-xl`;
        return (
            <VMBuilder<StockDashboardVM>>
                {(vm) => {
                    return (
                        <div className="table w-full sm:w-1/2 bg-slate-50">
                            <div className="table-header-group">
                                <div className={tableHeadStyle}>Material Code</div>
                                <div className={tableHeadStyle}>Nama Item</div>
                                <div className={tableHeadStyle}>DOI</div>
                                <div className={tableHeadStyle}>Qty Tersedia</div>
                            </div>
                            <div className="table-row-group">
                                { this.renderTableRows(vm) }
                            </div>
                        </div>
                    )
                }}
            </VMBuilder>
        )    
    }
}