import React, { Component, ReactNode } from "react";
import { ProviderVM } from "./view_model";
import { ViewModelContext } from "./vm_context";

interface VMProviderProps<VM extends ProviderVM> {
    children: (vm: VM) => React.ReactNode;
    create: () => VM;
}

export class VMProvider<VM extends ProviderVM> extends Component<VMProviderProps<VM>, {vm: VM}> {
    private vmContext: React.Context<VM>;

    constructor(props: VMProviderProps<VM>) {
        super(props);
        this.state = {
            vm: props.create()
        }

        this.state.vm.renderVc = (() => {
            this.setState((state) => {
                return {
                    vm: state.vm
                }
            });
        });

        this.vmContext = ViewModelContext.create(this.state.vm);
    }

    render(): ReactNode {
        return <this.vmContext.Provider value={this.state.vm}>
            <this.vmContext.Consumer>
                {
                    this.props.children && this.props.children
                }
            </this.vmContext.Consumer>
        </this.vmContext.Provider>;
    }
}
