import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import TransferHistoryViewModel from "../view_models/transfer_history_view_model";
import TransferHistoryDetailModalWidget from "./transfer_history_detail_modal_widget";
import TransferHistoryRow from "./transfer_history_row.widget";

export default class TransferHistoryWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<TransferHistoryViewModel>>
                {(vm) => (
                    <div className=" bg-slate-500 w-full  min-h-screen p-5 relative">
                        {vm.isShowmodal && <TransferHistoryDetailModalWidget />}
                        <div className="flex flex-col md:flex-row gap-2 justify-between items-center mb-5">
                            <button
                                className="justify-self-start px-4 py-2 border rounded bg-indigo-400 font-bold text-white"
                                onClick={() => {
                                    // back to root
                                    window.location.href = "/";
                                }}
                            >
                                Kembali
                            </button>
                            <h1 className="text-white text-3xl text-center font-bold flex-grow">
                                History Transfer
                            </h1>
                        </div>
                        <div className="mb-2">
                            <div className="flex justify-center items-center">
                                <select
                                    name="filter"
                                    className="px-2 h-9 rounded-l text-sm"
                                    onChange={(e) => {
                                        vm.setFilter(e.target.value);
                                    }}
                                    value={vm.selectedFilter}
                                >
                                    <option value="receiver">Tujuan</option>
                                    <option value="sender"> Asal</option>
                                    <option value="code">Kode</option>
                                </select>

                                <div className="relative">
                                    <input
                                        type="text"
                                        name="filter"
                                        placeholder="Search"
                                        className="px-2 h-9 pr-8"
                                        value={vm.searchQuery}
                                        onChange={(e) => {
                                            vm.setSeachQuery(e.target.value);
                                        }}
                                    />
                                    {/* X mark for reset */}
                                    {vm.searchQuery.length > 0 && (
                                        <span
                                            onClick={() => {
                                                vm.clearFilter();
                                            }}
                                            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-red-400 text-white px-2 h-6 rounded cursor-pointer"
                                        >
                                            &#10007;
                                        </span>
                                    )}
                                </div>

                                <button
                                    onClick={(e) => {
                                        vm.searchData();
                                    }}
                                    className="bg-indigo-400 text-white px-2 h-9 rounded-r"
                                >
                                    Cari
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-4 overflow-scroll bg-white">
                            <div className=" w-full">
                                <table className="text-black border-separate border border-slate-500 w-full">
                                    <thead>
                                        <tr>
                                            <th className="border border-slate-600 p-2">
                                                Created Date
                                            </th>
                                            <th className="border border-slate-600 p-2">
                                                Kode
                                            </th>
                                            <th className="border border-slate-600 p-2">
                                                Nama Pengirim
                                            </th>
                                            <th className="border border-slate-600 p-2">
                                                Lokasi Asal
                                            </th>
                                            <th className="border border-slate-600 p-2">
                                                Lokasi Tujuan
                                            </th>
                                            <th className="border border-slate-600 p-2">
                                                Sudah Diterima?
                                            </th>
                                            <th className="border border-slate-600 p-2">
                                                Diterima Oleh
                                            </th>
                                            <th className="border border-slate-600 p-2">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vm.isFilter
                                            ? vm.filteredData.map((item) => (
                                                  <TransferHistoryRow
                                                      key={item.code}
                                                      item={item}
                                                      onDetailClick={() =>
                                                          vm.loadDetail(
                                                              item.code
                                                          )
                                                      }
                                                  />
                                              ))
                                            : vm.transferHistory.map((item) => (
                                                  <TransferHistoryRow
                                                      key={item.code}
                                                      item={item}
                                                      onDetailClick={() =>
                                                          vm.loadDetail(
                                                              item.code
                                                          )
                                                      }
                                                  />
                                              ))}
                                        {vm.isFilter &&
                                            vm.filteredData.length === 0 && (
                                                <tr>
                                                    <td
                                                        colSpan={8}
                                                        className="text-center"
                                                    >
                                                        Data tidak ditemukan
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
