import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsProductionViewModel from "../view_models/adjustment_forms_production_view_model";
import Select from "react-select";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class AdjustmentFormsProductionModalWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsProductionViewModel>>
                {(vm) => (
                    <div
                        className="flex fixed inset-0 z-50 items-center justify-center bg-black bg-opacity-50 pt-4 px-4"
                        onClick={() => {
                            vm.resetModal();
                        }}
                    >
                        <div
                            className="flex flex-col bg-white rounded-lg shadow-lg w-full max-w-3xl h-[85vh] overflow-y-auto relative"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {/* Title & Search Bar */}
                            <div className="sticky top-0 bg-white px-6 pt-6 pb-3 shadow-sm">
                                <h2 className="text-2xl font-bold mb-6">
                                    Adjust Form Produksi
                                </h2>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        onChange={(e) => {
                                            const search =
                                                e.target.value.toLowerCase();
                                            vm.setSearchQuery(search);
                                        }}
                                        value={vm.searchQuery}
                                    />
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                                    />
                                </div>
                            </div>

                            {/* Mapping data */}
                            <div className="flex-grow space-y-4 px-3 mt-3">
                                {[...vm.productionDataMap.values()]
                                    .filter((item) =>
                                        item.itemName
                                            ?.toLowerCase()
                                            .includes(vm.searchQuery)
                                    )
                                    .map((item) => (
                                        <div
                                            key={item.itemId}
                                            className="grid grid-cols-12 gap-4 items-center border-b pb-4"
                                        >
                                            {/* Item Name */}
                                            <span className="col-span-4 text-gray-700 font-semibold">
                                                {
                                                    vm.itemOptions?.find(
                                                        (option) =>
                                                            option.id ===
                                                            item.itemId
                                                    )?.item
                                                }
                                            </span>

                                            {/* Big Quantity Input */}
                                            <div className="col-span-4">
                                                <label className="block text-sm text-gray-600">
                                                    Satuan Besar{" "}
                                                    {item.bigUnit
                                                        ? `(${item.bigUnit})`
                                                        : ""}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={item.bigQty}
                                                    onChange={(e) =>
                                                        vm.handleItemChange(
                                                            item.uniqueId,
                                                            "bigQty",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    // Prevent negative
                                                    onInput={(e) => {
                                                        const target =
                                                            e.target as HTMLInputElement;
                                                        if (
                                                            target.value.startsWith(
                                                                "0"
                                                            ) &&
                                                            target.value
                                                                .length > 1
                                                        ) {
                                                            target.value =
                                                                target.value.replace(
                                                                    /^0+/,
                                                                    ""
                                                                );
                                                        }
                                                    }}
                                                    placeholder="Big Quantity"
                                                    className="w-full border rounded-lg px-3 py-2 mt-1"
                                                />
                                            </div>

                                            {/* Small Quantity Input */}
                                            <div className="col-span-4">
                                                <label className="block text-sm text-gray-600">
                                                    Satuan Kecil{" "}
                                                    {item.smallUnit
                                                        ? `(${item.smallUnit})`
                                                        : ""}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={item.smallQty}
                                                    onChange={(e) =>
                                                        vm.handleItemChange(
                                                            item.uniqueId,
                                                            "smallQty",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    // Prevent negative
                                                    onInput={(e) => {
                                                        const target =
                                                            e.target as HTMLInputElement;
                                                        if (
                                                            target.value.startsWith(
                                                                "0"
                                                            ) &&
                                                            target.value
                                                                .length > 1
                                                        ) {
                                                            target.value =
                                                                target.value.replace(
                                                                    /^0+/,
                                                                    ""
                                                                );
                                                        }
                                                    }}
                                                    placeholder="Small Quantity"
                                                    className="w-full border rounded-lg px-3 py-2 mt-1"
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            {/* Dropdown to Select New Item */}
                            <div className="sticky bottom-0 mt-6 bg-white px-4 py-6">
                                {/* Container for Select and Add Button */}
                                <div className="w-full flex space-x-4 items-center">
                                    {/* React Select */}
                                    <div className="flex-grow">
                                        <Select
                                            className="basic-single w-full"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="item"
                                            options={vm.itemOptions
                                                ?.filter(
                                                    (option) =>
                                                        !vm.addedItemIds.includes(
                                                            option.id
                                                        )
                                                )
                                                .map((option) => ({
                                                    value: option.id,
                                                    label: option.item,
                                                }))}
                                            value={vm.itemOptions
                                                ?.map((option) => ({
                                                    value: option.id,
                                                    label: option.item,
                                                }))
                                                .find(
                                                    (opt) =>
                                                        opt.value ===
                                                        vm.selectedItemId
                                                )}
                                            onChange={(selectedOption) =>
                                                vm.setSelectedItemId(
                                                    selectedOption
                                                        ? selectedOption.value
                                                        : 0
                                                )
                                            }
                                            placeholder="Tambahkan Item ?"
                                            // Make react select position fixed
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    </div>

                                    {/* Add Item Button */}
                                    <button
                                        onClick={() => {
                                            if (vm.selectedItemId) {
                                                vm.handleAddItem(
                                                    vm.selectedItemId
                                                );
                                            }
                                        }}
                                        className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
                                    >
                                        Add Item
                                    </button>
                                </div>

                                {/* Submit Button */}
                                <div className="mt-4">
                                    <button
                                        onClick={() => vm.handleSubmit()} // Handle the form submission here
                                        className="w-full bg-green-500 text-white px-4 py-3 rounded-lg hover:bg-green-600"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
