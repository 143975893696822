import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import MasterItemDashboardViewModel from "../view_models/master_item_dashboard_view_model";
import StringUtils from "../../../commons/utils/string_utils";

export default class MasterItemSearchInputWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<MasterItemDashboardViewModel>>
                {(vm) => (
                    <div className="flex flex-wrap items-center mb-5 space-y-3 md:space-y-0">
                        {/* Dropdown select */}
                        <div className="relative mr-2">
                            <select
                                className="block appearance-none w-full h-9 bg-white border border-gray-300 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline px-2 pr-8"
                                style={{
                                    backgroundImage:
                                        'url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23222%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27/%3E%3C/svg%3E")',
                                    backgroundPosition: "right 0.75rem center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "1rem 1rem",
                                }}
                                onChange={(e) => {
                                    const value =
                                        e.target.value !== "All"
                                            ? StringUtils.toSnakeCase(
                                                  e.target.value
                                              )
                                            : undefined;
                                    vm.setSearchMaterialType(value);
                                    vm.getMasterItemList();
                                }}
                            >
                                {vm.materialTypeOptions.map((option) => (
                                    <option value={option}>{option}</option>
                                ))}
                            </select>
                        </div>

                        {/* Keyword input */}
                        <div className="relative flex-grow mr-2">
                            <input
                                type="text"
                                placeholder="Cari dengan Nama / Material Code"
                                className="px-2 h-9 pr-8 mx-2 block appearance-none w-full bg-white border border-gray-300 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) =>
                                    vm.setSearchKeyword(
                                        e.target.value || undefined
                                    )
                                }
                            />
                        </div>

                        {/* Search button */}
                        <div className="relative ml-2">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg w-full"
                                onClick={() => {
                                    vm.setPaging({
                                        currentPage: 1,
                                        size: 20,
                                        totalPage: vm.paging.totalPage,
                                    });
                                    vm.getMasterItemList();
                                }}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
