import React from "react";
import { VMProvider } from "../../commons/base/providers/provider";
import ErrorStateView from "../../commons/ui/error/error_state_view";
import VendorReceiptHistoryVM from './view_models/vendor_receipt_history_vm';
import VendorReceiptHistoryTable from "./widgets/vendor_receipt_history_table";
import VendorReceiptHistoryDetail from "./widgets/vendor_receipt_history_detail";
import SearchBar from "./widgets/search_bar";

export default class VendorReceiptHistoryScreen extends React.Component {
    
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new VendorReceiptHistoryVM()}>
                {(vm) => {
                    if (vm.isLoading)
                        return (<h1>Loading...</h1>);

                    if (vm.errorMessage.length > 0) {
                        return <ErrorStateView 
                            message={vm.errorMessage} 
                            action={() => vm.retryAction()}/>;
                    }

                    return (
                        <div className="bg-slate-500 min-h-screen p-5 justify-center">
                            <div className="m-1 mt-[50px] flex flex-col md:m-5">
                                <h1 className="text-white text-4xl font-bold">
                                    Riwayat Penerimaan Vendor
                                </h1>
                                <div className="flex justify-between items-center mt-[20px]">
                                    <button
                                        className="w-fit px-4 py-2 border rounded 
                                        bg-[#24A0ED] font-bold text-white text-sm"
                                        onClick={() => {
                                            // back to root
                                            window.location.href = "/";
                                        }}
                                    >
                                        Kembali
                                    </button>
                                    <SearchBar />
                                </div>
                            </div>
                            <div className="bg-slate-50 mt-5 m-1 md:m-5">
                                <VendorReceiptHistoryTable />
                            </div>
                            <VendorReceiptHistoryDetail/>
                        </div>
                    )
                }}
            </VMProvider>
        )
    }
}