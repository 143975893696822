import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import MenuPackagingViewModel from "../view_models/menu_packaging_view_model";
import MenuPackagingRow from "./menu_packaging_row_widget";
import MenuPackagingModalWidget from "./menu_packaging_detail_modal";
import Scaffold from "../../../commons/scaffold/scaffold";

export default class MenuPackagingWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<MenuPackagingViewModel>>
                {(vm) => (
                    <Scaffold showSnackbar={vm.errorSnackBar}>
                        <div className=" bg-slate-500 w-full min-h-screen p-5 mt-10 relative">
                            {vm.isShowModal && <MenuPackagingModalWidget />}

                            <h1 className="text-white text-3xl text-center font-bold flex-grow">
                                Menu Packaging
                            </h1>

                            <div className="mt-4 w-full flex">
                                <div className="relative w-full">
                                    <input
                                        type="text"
                                        name="search"
                                        placeholder="Input item name here"
                                        className="px-2 w-full h-9 pr-8 rounded flex-grow"
                                        value={vm.searchQuery}
                                        onChange={(e) => {
                                            vm.setSearchQuery(e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                vm.searchItems();
                                            }
                                        }}
                                    />

                                    {vm.searchQuery.length > 0 && (
                                        <div className="group">
                                            <span
                                                onClick={() => {
                                                    vm.clearFilter();
                                                }}
                                                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-slate-400 text-white px-2 h-7 rounded cursor-pointer flex items-center justify-center"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1"
                                                    stroke="currentColor"
                                                    className="size-4 inset-0"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                                    />
                                                </svg>

                                                <span className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">
                                                    Reset
                                                </span>
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <button
                                    className="px-2 h-9 mx-2 bg-indigo-800 text-white rounded"
                                    onClick={(e) => {
                                        vm.searchItems();
                                    }}
                                >
                                    Search
                                </button>

                                <button
                                    className="px-2 h-9 bg-indigo-400 text-white rounded"
                                    onClick={(e) => {
                                        vm.loadBlankDetail();
                                    }}
                                >
                                    Create
                                </button>
                            </div>

                            <div className="flex flex-col lg:flex-row gap-4 overflow-scroll bg-white mt-4">
                                <div className="w-full">
                                    <table className="text-black border-separate border border-slate-500 w-full">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Item Material Code</th>
                                                <th>Item Name</th>
                                                <th>Packaging</th>
                                                <th>Qty</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {vm.isFilter
                                                ? vm.filteredItems.map(
                                                      (item, index) => (
                                                          <MenuPackagingRow
                                                              key={item.id}
                                                              item={item}
                                                              index={index}
                                                              onEditClick={() => {
                                                                  vm.loadDetail(
                                                                      item.id,
                                                                      item.itemId,
                                                                      item.packagingId,
                                                                      item.quantity
                                                                  );
                                                              }}
                                                          />
                                                      )
                                                  )
                                                : vm.items.map(
                                                      (item, index) => (
                                                          <MenuPackagingRow
                                                              key={item.id}
                                                              item={item}
                                                              index={index}
                                                              onEditClick={() => {
                                                                  vm.loadDetail(
                                                                      item.id,
                                                                      item.itemId,
                                                                      item.packagingId,
                                                                      item.quantity
                                                                  );
                                                              }}
                                                          />
                                                      )
                                                  )}
                                            {vm.isFilter &&
                                                vm.filteredItems.length ===
                                                    0 && (
                                                    <tr>
                                                        <td
                                                            colSpan={8}
                                                            className="text-center"
                                                        >
                                                            Data tidak ditemukan
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Scaffold>
                )}
            </VMBuilder>
        );
    }
}
