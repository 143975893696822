export default class StringUtils {
    static getCurrencyStringFormat(amount: number): string {
        return Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
        }).format(amount);
    }

    static checkIsValidUrl(value: string): boolean {
        return !!new RegExp(
            "^(https?:\\/\\/)?" + // validate protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ).test(value);
    }

    static convertToCurrency(
        val?: number | null,
        currencyLabel: string = "Rp "
    ): string {
        let currencyNumberFormat = Intl.NumberFormat("en-US");
        let priceLabel = currencyNumberFormat.format(val ?? 0);

        return `${currencyLabel}${priceLabel}`;
    }

    static snakeCaseToNormal(str: string): string {
        return str
            .split("_")
            .map(
                (word: string) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
    }

    static toSnakeCase(str: string): string {
        return str
            .split(" ")
            .map((word: string) => word.toLowerCase())
            .join("_");
    }

    static toIndonesianDate(date: Date) {
        const formattedDate = date.toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("id-ID", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });

        return `${formattedDate}, ${formattedTime}`;
    }

    // Ini diperlukan, karena date di DB berformat UTC+7, jadi ketika dibaca Node.js dengan method toISOString() akan menjadi UTC+0
    // Jadi fungsi ini akan mengubah UTC+0 menjadi UTC+7
    static UTCtoLocale(isoString: string) {
        return new Date(
            new Date(isoString).getTime() + 7 * 60 * 60 * 1000
        ).toISOString();
    }

    // Ini diperlukan, untuk mengubah UTC+7 menjadi UTC+0 sebelum date dikirim ke backend
    // Ketika dilakukan insert ke DB, maka otomatis akan dilakukan penambahan menjadi UTC+7
    static LocaleToUTC(isoString: string) {
        return new Date(
            new Date(isoString).getTime() - 7 * 60 * 60 * 1000
        ).toISOString();
    }
}
