import OutletQueuePreviewViewModel from "./view_models/outlet_queue_preview_view_model";
import LoadingStateView from "../../commons/ui/loading/loading_state_view";
import ErrorStateView from "../../commons/ui/error/error_state_view";
import OutletQueueDisplayWidget from "./widgets/outlet_queue_display_widget";
import OutletQueueOutletSelectorWidget from "./widgets/outlet_queue_outlet_selector_widget";
import React from "react";
import { VMProvider } from "../../commons/base/providers/provider";

export default class OutletQueuePreviewScreen extends React.Component {
    render() {
        return <VMProvider create={() => new OutletQueuePreviewViewModel()}>
            {
                (vm) => {
                    if (vm.isLoading) {
                        return <LoadingStateView backgroundType="semi_transparent"/>;
                    } else if (vm.errorMessage.length > 0) {
                        return <ErrorStateView 
                            message={vm.errorMessage} 
                            action={() => vm.retryAction()} 
                        /> 
                    } 

                    return vm.selectedOutlet.length > 0 
                        ? <OutletQueueDisplayWidget />
                        : <OutletQueueOutletSelectorWidget />;
                }
            }
        </VMProvider>;
    }
}