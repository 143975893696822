import { TextField } from "@mui/material";
import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import VendorReceiptHistoryVM from "../view_models/vendor_receipt_history_vm";
import Select from "react-select";

export default class SearchBar extends React.Component {

    render(): React.ReactNode {
        return (
            <VMBuilder<VendorReceiptHistoryVM>>
                {(vm) =>
                    <div className="grid grid-cols-2 space-x-2">
                        <TextField 
                        size="small"
                        value={vm.searchBarValue}
                        variant="outlined" 
                        placeholder="Search Kode" 
                        className="bg-white"
                        onChange={(e) => {
                            e.preventDefault();
                            vm.searchBarValue = e.target.value;
                            vm.searchForm();
                        }}/>
                        <Select 
                        placeholder="Pilih Lokasi Penerima"
                        options={vm.locationOptions.map((location) => ({
                            label: location.locationName,
                            value: location.locationId,
                        }))}
                        onChange={(selectedOption) => {
                            if (!selectedOption) return;
                            vm.changeSelectedLocation(selectedOption.value);
                        }}
                        value={vm.locationOptions.map((location) => ({
                            label: location.locationName,
                            value: location.locationId,
                        })).find((option) => option.value === vm.selectedLocationId)}
                        />
                    </div>
                }
            </VMBuilder>
        )
    }
}