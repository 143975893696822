import React from "react";
import OutletQueuePreviewViewModel from "../view_models/outlet_queue_preview_view_model";
import { VMBuilder } from "../../../commons/base/providers/builder";

export default class OutletQueueDisplayWidget extends React.Component {
    render(): React.ReactNode {
        return <VMBuilder<OutletQueuePreviewViewModel>>
            {
                (vm) => <div className="flex justify-center items-center h-[100%]">
                    <div>
                        <p className="text-[24px] font-bold text-center">
                            Panggilan Order Telah Selesai
                        </p>
                        <p className="text-[120px] font-extrabold text-center mb-[42px]"> 
                            {
                                vm.latestReadyToPickupNumber
                            }
                        </p>
                        <p className="text-[20px] font-bold">
                            Pesanan Selesai
                        </p>
                        <p className="text-[32px] font-bold mb-[32px]">
                            {
                                vm.readyToPickupNumbersLabel
                            }
                        </p>
                        <p className="text-[20px] font-bold">
                            Sedang diproses
                        </p>
                        <p className="text-[32px] font-bold mb-[32px]">
                            {
                                vm.onCookingNumbersLabel
                            }
                        </p>
                    </div>
                </div>
            }
        </VMBuilder>;
    }
}
