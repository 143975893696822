import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import AllOutletStockDashboardVM from '../../view_models/all_outlet_stock_dashboard_vm';
import FilterSelectionModal from './filter_selection_modal';

export default class FilterList extends React.Component {

    render(): React.ReactNode {
        return (
        <VMBuilder<AllOutletStockDashboardVM>>
            {(vm) => (
            <div className="flex items-center py-4 px-5 bg-slate-100">
                <span className="font-bold mr-5">Filter Item: </span>
                {
                    vm.byMatCodeFilters.map((el) => <Badge text={el} />)
                }
                <FilterSelectionModal/>
            </div>
            )}
        </VMBuilder>
        );    
    }
}

class Badge extends React.Component<{
    text: string
}> {

    render(): React.ReactNode {
        return (
        <span className="rounded py-1 px-2 mr-2 font-semibold bg-gray-500 text-white">
            {this.props.text}
        </span>
        );
    }
}