import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsTransferViewModel from "../view_models/adjustment_forms_transfer_view_model";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

interface AdjustmentFormsTransferFilterWidgetProps {
    onChange: (selectedOption: { value: number; label: string } | null) => void;
}

export default class AdjustmentFormsTransferFilterWidget extends React.Component<AdjustmentFormsTransferFilterWidgetProps> {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsTransferViewModel>>
                {(vm) => (
                    <div className="flex flex-wrap items-center mb-5 space-y-3 md:space-y-0">
                        {/* Dropdown select */}
                        <div className="relative mr-2">
                            <Select
                                options={vm.locationOptions.map((option) => ({
                                    value: option.locationId,
                                    label: option.locationName,
                                }))}
                                placeholder={"Pilih Lokasi Pengirim"}
                                isClearable
                                isSearchable
                                onChange={this.props.onChange}
                            />
                        </div>
                        {/* Search Code */}
                        <div className="relative mr-2">
                            <input
                                type="text"
                                placeholder="Cari Kode ..."
                                className="border border-gray-300 rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={(e) => {
                                    const code = e.target.value;
                                    const filteredTransferList =
                                        vm.transferList.filter((data) =>
                                            data.code
                                                .toLowerCase()
                                                .includes(code.toLowerCase())
                                        );
                                    vm.setFilteredTransferList(
                                        filteredTransferList
                                    );
                                }}
                            />
                            <FontAwesomeIcon
                                icon={faSearch}
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                            />
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
