import OutletOrderPreviewViewModel from "./view_models/outlet_order_preview_view_model";
import LoadingStateView from "../../commons/ui/loading/loading_state_view";
import ErrorStateView from "../../commons/ui/error/error_state_view";
import OutletOrderDisplayWidget from "./widgets/outlet_order_display_widget";
import OutletOrderOutletSelectorWidget from "./widgets/outlet_order_outlet_selector_widget";
import { VMProvider } from "../../commons/base/providers/provider";
import React from "react";

export default class OutletOrderPreviewScreen extends React.Component {
    render() {
        return (
            <VMProvider create={() => new OutletOrderPreviewViewModel()}>
                {(vm) => {
                    if (vm.isLoading) {
                        return (
                            <LoadingStateView backgroundType="semi_transparent" />
                        );
                    } else if (vm.errorMessage.length > 0) {
                        return (
                            <ErrorStateView
                                message={vm.errorMessage}
                                action={() => vm.retryAction()}
                            />
                        );
                    }

                    return vm.selectedOutlet.length > 0 ? (
                        <OutletOrderDisplayWidget />
                    ) : (
                        <OutletOrderOutletSelectorWidget />
                    );
                }}
            </VMProvider>
        );
    }
}
