import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsSoViewModel from "../view_models/adjustment_forms_so_view_model";
import Select from "react-select";

export default class AdjustmentFormsFilterWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsSoViewModel>>
                {(vm) => (
                    <div className="flex flex-wrap items-center mb-5 space-y-3 md:space-y-0">
                        {/* Dropdown select */}
                        <div className="relative mr-2">
                            <Select
                                options={vm.locationOptions.map((option) => ({
                                    value: option.locationId,
                                    label: option.locationName,
                                }))}
                                placeholder={"Pilih Lokasi SO"}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(selectedOption) => {
                                    vm.setFilterLocationId(
                                        selectedOption?.value
                                    );
                                    vm.fetchSOList(vm.filterLocationId);
                                }}
                            />
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
