import React from "react";
import { SoldOutPerOutletModel } from "../models/sold_out_model";

interface Props {
    soldOutData: SoldOutPerOutletModel[];
}

export default class SoldOutOtherMenuTableWidget extends React.Component<Props> {
    render(): React.ReactNode {
        return (
            <table className="table-fixed w-full text-center border-collapse">
                <thead>
                    <tr className="bg-gray-300">
                        <th className="p-2 border border-gray-400 w-1/5 break-words">
                            OUTLET
                        </th>
                        <th className="p-2 border border-gray-400 w-4/5 break-words">
                            MENU LAINNYA
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.soldOutData.map((data, i) => (
                        <tr
                            className="bg-white border border-gray-400 align-top"
                            key={i}
                        >
                            <td className="border border-gray-400 p-1 w-1/5 text-xs">
                                <div className="flex flex-wrap justify-center p-0.5">
                                    <span className="p-1 m-1 text-xs break-words overflow-x-auto">
                                        {data.outletName}
                                    </span>
                                </div>
                            </td>
                            <td className="border border-gray-400">
                                <div className="flex flex-wrap justify-start p-0.5">
                                    {data.otherMenus.map((menu, j) => (
                                        <span
                                            key={j}
                                            className="p-1 rounded m-1 text-xs"
                                        >
                                            {menu}
                                        </span>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}
