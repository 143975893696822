import React from "react";
import Scaffold from "../../commons/scaffold/scaffold";
import NavigateButton from "../../commons/ui/navigate_button/navigate_button";

export default class AdjustmentFormsHomeScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <Scaffold>
                <div className="bg-slate-500 min-h-screen p-5 mt-10 relative">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pt-4">
                        <NavigateButton
                            title="Adjust Form Produksi"
                            path="/adjustment-forms/production"
                        />
                        <NavigateButton
                            title="Adjust Form SO"
                            path="/adjustment-forms/so"
                        />
                        <NavigateButton
                            title="Adjust Form Waste"
                            path="/adjustment-forms/waste"
                        />
                        <NavigateButton
                            title="Adjust Form Penerimaan Vendor"
                            path="/adjustment-forms/vendor-receipt"
                        />
                        <NavigateButton
                            title="Adjust Form Transfer"
                            path="/adjustment-forms/transfer"
                        />
                        <NavigateButton
                            title="Adjust Form Transfer ke Vendor"
                            path="/adjustment-forms/to-vendor"
                        />
                    </div>
                </div>
            </Scaffold>
        );
    }
}
