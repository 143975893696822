import "./home.css";

import React from "react";
import Scaffold from "../commons/scaffold/scaffold";
import { HomeFeatureButton } from "./widgets/home_feature_button";

export default class InventoryHomeScreen extends React.Component {
    render() {
        return (
            <Scaffold>
                <div id="HomeScreenContainer">
                    <HomeFeatureButton
                        title="View History SO"
                        path="/so-view"
                    />
                    <HomeFeatureButton
                        title="View History Waste"
                        path="/waste-view"
                    />
                    <HomeFeatureButton
                        title="Kelengkapan Form SO dan Waste"
                        path="/completeness-so-waste"
                    />
                    <HomeFeatureButton
                        title="Transfer History"
                        path="/transfer-history"
                    />
                    <HomeFeatureButton
                        title="Riwayat Penerimaan Vendor"
                        path="/receipt-history"
                    />
                    <HomeFeatureButton
                        title="Formulir Adjustment"
                        path="/adjustment-forms"
                    />
                </div>
            </Scaffold>
        );
    }
}
