import React from "react";
import { Modal } from "@mui/material";
import { VMBuilder } from "../../../commons/base/providers/builder";
import AllOutletStockDashboardVM from '../../view_models/all_outlet_stock_dashboard_vm';

export default class FilterSelectionModal extends React.Component {

    renderItemList(vm: AllOutletStockDashboardVM) {
        const list: JSX.Element[] = [];

        vm.filteredData.forEach((data) => {
            data.items.forEach((item) => {
                if (vm.byMatCodeFilters.indexOf(item.matCode) !== -1) {
                    vm.selected.set(item.matCode, item.name);
                }
            });
        });

        vm.selected.forEach((name, matCode) => {
            list.push(
                <div className="flex justify-between my-3">
                    <span className="rounded p-2 mx-4 w-full bg-slate-50">
                        {matCode} {name}
                    </span>
                    <button 
                    onClick={() => {vm.deleteFilter(matCode)}}
                    className="rounded p-2 mr-4 bg-red-200"> X </button>
                </div>
            );
        });

        return list;
    }

    openItemSelection() {
        const modal = document.getElementById("item-selection-modal");
        if (modal) {
            modal.style.display = "block";
        }
    }

    render(): React.ReactNode {
        return (
        <VMBuilder<AllOutletStockDashboardVM>>
            {(vm) => {
                if (vm.isModalOpen) {
                    return (
                        <Modal 
                        open={vm.isModalOpen}
                        >
                            <div className="h-screen flex justify-center items-center">
                                <div className="bg-white w-1/2">
                                    <h1 className="m-4 text-left text-lg">Filter Item</h1>
                                    <div>
                                        { this.renderItemList(vm) }
                                        <div className="flex justify-between my-3">
                                            <button 
                                            className="rounded mx-4 w-full bg-slate-200"
                                            onClick={() => this.openItemSelection()}
                                            >
                                                + Tambah Item
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-end p-4">
                                        <button 
                                        onClick={() => {vm.handleClose()}}
                                        className="bg-gray-200 px-2 py-1 mr-4">Cancel</button>
                                        <button 
                                        onClick={() => {vm.applyFilter()}}
                                        className="bg-blue-500 text-white px-2 py-1">Simpan Filter</button>
                                    </div>
                                </div>
                                <ItemSelection items={vm.selection} onSelected={vm.handleItemSelectionSave.bind(vm)} />
                            </div>
                        </Modal>
                    )
                } else
                    return (
                        <button 
                        className="bg-[#24A0ED] py-1 px-2 ml-3 text-white font-bold rounded"
                        onClick={() => {
                            vm.handleOpen();
                        }}
                        >
                            {vm.byMatCodeFilters.length > 0 
                            ? "Edit Filter"
                            : "Buat Filter"}
                        </button>
                    )
            }}
        </VMBuilder>
        )
    }
}


interface ItemSelectionProps {
    items: Map<string, string>
    onSelected: (matCode: string, name: string) => void
}

class ItemSelection extends React.Component<ItemSelectionProps, {
    selectionItems: Map<string, string>
}> {

    filteredSelectionItems = new Map<string, string>();

    constructor(props: ItemSelectionProps) {
        super(props);
        this.state = {
            selectionItems: props.items
        }
    }

    handleClose() {
        const modal = document.getElementById("item-selection-modal");
        if (modal) {
            modal.style.display = "none";
        }
    }

    renderItems() {
        const itemList: JSX.Element[] = [];
        this.state.selectionItems.forEach((name, matCode) => {
            itemList.push(
                <button
                className="block my-2 px-4 py-2 bg-blue-50 w-full"
                onClick={() => {
                    this.props.onSelected(matCode, name);
                    this.handleClose();
                }}
                >
                    {matCode} {name}
                </button>
            );
        });
        return itemList;
    }

    render(): React.ReactNode {
        return (
            <div id="item-selection-modal" className="hidden absolute backdrop-brightness-50 size-full">
                <div className="size-full flex justify-center items-center">
                    <div className="w-1/3 p-5 h-fit bg-white">
                        <h1 className="text-xl font-bold mb-5">Pilih Item</h1>
                        <div>
                            <input 
                            id="cari-item"
                            className="w-full p-2 border rounded" 
                            placeholder="Cari Item"
                            onChange={(e) => {
                                const value = e.target.value;
                                this.filteredSelectionItems.clear();
                                this.props.items.forEach((name, matCode) => {
                                    if (name && `${matCode} ${name}`.includes(value)) {
                                        this.filteredSelectionItems.set(matCode, name);
                                    }
                                });
                                this.setState({
                                    selectionItems: this.filteredSelectionItems
                                });
                            }}/>
                        </div>
                        <div className="h-[300px] overflow-y-scroll">
                            {this.renderItems()}
                        </div>
                        <div className="mt-5 flex justify-end">
                            <button
                            className="bg-gray-200 p-2 mr-4"
                            onClick={() => this.handleClose()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}