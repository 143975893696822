import refresh_circle_button from "../../assets/images/refresh_circle_button.svg";

import "../create_order.css";

import ViewController from "../../commons/base/view_controller";
import SizedDiv from "../../commons/ui/sized_div";
import GerobakOrderMenuSectionWidget from "../widgets/create_order_menu_section_widget";
import Scaffold from "../../commons/scaffold/scaffold";
import FormButton from "../../commons/ui/form_button";
import CreateOrderPopUpContainerWidget from "../widgets/create_order_pop_up_container_widget";
import GerobakOrderState from "./view_models/gerobak_order_state";
import GerobakOrderViewModel from "./view_models/gerobak_order_view_model";
import RadioFormWidget from "../../commons/ui/radio_form/radio_form_widget";
import FormErrorMessage from "../../commons/ui/form_error_message";

export default class GerobakOrderScreen extends ViewController<{}, GerobakOrderState, GerobakOrderViewModel> {
    constructor(props: {}) {
        super(props, new GerobakOrderViewModel());
    }

    render() {
        return <>
            <Scaffold
                isShowLoading={this.vmState.isLoading || this.vmState.isResetLoading}
                loadingBackgroundType="semi_transparent"
                showSnackbar={this.vmState.showSnackbarModel}
                loadingType="uena_logo"
                showErrorView={this.vmState.showErrorViewModel}
            >
                {
                    this.vmState.isResetLoading
                        ? <div style={{backgroundColor: "#434755", height: "100%", width: "100%"}} />
                        : <div id="CreateOrderScreenContainer">
                            <div>
                                <div id="CreateOrderFormContainer">
                                    {
                                        this.vmState.contentModel &&
                                        <>
                                            <p className="FormTitle">Formulir Pemesanan</p>
                                            <SizedDiv height={12} />

                                            {/* CUSTOMER NAME SECTION */}
                                            <p className="FormLabel">Nama Customer</p>
                                            <input 
                                                className="FormTextInput"
                                                value={this.vmState.name}
                                                onChange={(event) => this.viewModel.changeName(event.target.value)}
                                                placeholder="Masukkan nama customer disini"
                                            />
                                            <SizedDiv height={12} />

                                            {/* PAYMENT METHOD SECTION */}
                                            <p className="FormLabel">Metode Pembayaran</p>
                                            <RadioFormWidget
                                                items={this.vmState.contentModel.paymentMethods}
                                                selectedId={this.vmState.selectedPaymentMethodId}
                                                onSelectItem={(id) => this.viewModel.changeSelectedPaymentMethod(id)}
                                            />
                                            <FormErrorMessage isShowing={this.vmState.isPaymentMethodError} message="Metode pembayaran WAJIB diisi!!" />
                                            <SizedDiv height={16} />

                                            {/* ORDER SECTION */}
                                            <GerobakOrderMenuSectionWidget 
                                                refreshId={this.vmState.refreshId}
                                                productCollections={this.vmState.contentModel.gerobakProducts}
                                                productAndQuantity={this.vmState.productAndQuantity}
                                                selectedFilter={this.vmState.selectedFilter}
                                                onCategoryTickerSelected={
                                                    (index, isSelected) => this.viewModel.changeSelectedCategoryTicker({
                                                        index, 
                                                        isActive: isSelected
                                                    })
                                                }
                                                onProductQuantityOrNotesChanged={
                                                    (id, quantity, model, isVoucherRequired) => this.viewModel.changeProductQuantity({ id, quantity, model, isVoucherRequired })
                                                }
                                                screenWidth={this.state.width}
                                            />
                                        </>
                                    }
                                </div>
                                {
                                    !this.vmState.isLoading &&
                                    <button onClick={() => this.viewModel.resetSelectedProduct()}>
                                        <img src={refresh_circle_button} alt="" />
                                        <p>Reset<br />Menu</p>
                                    </button>
                                }
                            </div>
                            <div id="CreateOrderBottomSection">
                                <div>
                                    <p>Total Harga</p>
                                    <p>{this.vmState.totalPurchase}</p>
                                </div>
                                <div />
                                <FormButton 
                                    title="Submit"
                                    backgroundColor="#FFCC33"
                                    isLoading={this.vmState.isSubmitButtonLoading}
                                    action={() => this.viewModel.validateAbleToBeSubmitted(
                                        () => this.viewModel.showPopUp(() => this.viewModel.submitForm())
                                    )}
                                />
                            </div>
                        </div>
                }
            </Scaffold>
            {
                this.vmState.popUpModel && 
                <CreateOrderPopUpContainerWidget 
                    isDeliveredByDriver={false}
                    popUpModel={this.vmState.popUpModel} 
                    productList={this.vmState.productList} 
                    closePopUp={() => this.viewModel.closePopUp()}
                    additionalItems={this.vmState.orderVerificationResponse?.additionalItems}
                />
            }
        </>;
    }
}