import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import BomDashboardViewModel from "../view_models/bom_dashboard_view_model";

export default class BomDashboardPaginationWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<BomDashboardViewModel>>
                {(vm) => (
                    <div className="flex justify-center items-center mt-4">
                        {(() => {
                            const { currentPage, totalPage } = vm.paging || {
                                currentPage: 1,
                                totalPage: 1,
                            };
                            const maxPagesToShow = 7;
                            const halfRange = Math.floor(maxPagesToShow / 2);
                            let startPage = Math.max(
                                1,
                                currentPage - halfRange
                            );
                            let endPage = Math.min(
                                totalPage,
                                currentPage + halfRange
                            );

                            // Adjust if not enough pages before or after the current page
                            if (endPage - startPage + 1 < maxPagesToShow) {
                                if (currentPage <= halfRange) {
                                    endPage = Math.min(
                                        totalPage,
                                        endPage +
                                            (maxPagesToShow -
                                                (endPage - startPage + 1))
                                    );
                                } else {
                                    startPage = Math.max(
                                        1,
                                        startPage -
                                            (maxPagesToShow -
                                                (endPage - startPage + 1))
                                    );
                                }
                            }

                            return (
                                <>
                                    {startPage > 1 && (
                                        <>
                                            <button
                                                className="px-3 py-1 mx-1 rounded bg-white text-gray-700 border border-gray-300 hover:bg-blue-500 hover:text-white"
                                                onClick={() =>
                                                    vm.handleChangePage(1)
                                                }
                                            >
                                                1
                                            </button>
                                            {startPage > 2 && (
                                                <span className="px-3 py-1 mx-1 text-gray-700">
                                                    ...
                                                </span>
                                            )}
                                        </>
                                    )}

                                    {Array.from(
                                        {
                                            length: endPage - startPage + 1,
                                        },
                                        (_, i) => startPage + i
                                    ).map((page) => (
                                        <button
                                            key={page}
                                            className={`px-3 py-1 mx-1 rounded ${
                                                Number(
                                                    vm.paging?.currentPage
                                                ) === page
                                                    ? "bg-blue-500 text-white"
                                                    : "bg-white text-gray-700 border border-gray-300"
                                            } hover:bg-blue-500 hover:text-white`}
                                            onClick={() =>
                                                vm.handleChangePage(page)
                                            }
                                        >
                                            {page}
                                        </button>
                                    ))}

                                    {endPage < totalPage && (
                                        <>
                                            {endPage < totalPage - 1 && (
                                                <span className="px-3 py-1 mx-1 text-gray-700">
                                                    ...
                                                </span>
                                            )}
                                            <button
                                                className="px-3 py-1 mx-1 rounded bg-white text-gray-700 border border-gray-300 hover:bg-blue-500 hover:text-white"
                                                onClick={() =>
                                                    vm.handleChangePage(
                                                        totalPage
                                                    )
                                                }
                                            >
                                                {totalPage}
                                            </button>
                                        </>
                                    )}
                                </>
                            );
                        })()}
                    </div>
                )}
            </VMBuilder>
        );
    }
}
