 import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdhocCrmScreen from "../adhoc_crm/adhoc_crm_screen";
import CreateOrderScreen from "../create_order/cs/create_order_screen";
import HomeScreen from "../home/home_screen";
import ServiceAreasMapScreen from "../service_areas_map/service_areas_map_screen";
import NotificationWebFormScreen from "../notification_web/screens/notification_web_form_screen";
import NotificationWebDisplayScreen from "../notification_web/screens/notification_web_display_screen";
import WebRedirectFormScreen from "../redirect/screens/web_redirect_form_screen";
import WebRedirectDisplayScreen from "../redirect/screens/web_redirect_display_screen";
import CrmHomeScreen from "../home/crm_home_screen";
import MobileQrisScreen from "../mobile_app/qris/mobile_qris_screen";
import DownloadAppScreen from "../download_app/download_app_screen";
import CashierOrderScreen from "../create_order/cashier/cashier_order_screen";
import UenaWebsiteScreen from "../website/uena_website_screen";
import TncScreen from "../mobile_app/tnc/tnc_screen";
import ReferralScreen from "../mobile_app/referral/referral_screen";
import DriverSignInScreen from "../driver/sign_in/driver_sign_in_screen";
import DriverRootScreen from "../driver/root/driver_root_screen";
import DriverOrderListScreen from "../driver/order_list/driver_order_list_screen";
import DriverOrderDetailScreen from "../driver/order_detail/driver_order_detail_screen";
import GerobakOrderScreen from "../create_order/gerobak/gerobak_order_screen";
import FranchiseLandingScreen from "../franchise/franchise_landing_screen";
import CompletionOrderScreen from "../completion/completion_order_screen";
import SoScreen from "../inventory_view/so/so_screen";
import WasteScreen from "../inventory_view/waste/waste_screen";
import OutletQueuePreviewScreen from "../outlet/outlet_queue_preview/outlet_queue_preview_screen";
import UenaDeleteAccountScreen from "../website/uena_delete_account_screen";
import OutletOrderPreviewScreen from "../outlet/outlet_order_preview/outlet_order_preview_screen";
import OutletHomeScreen from "../home/outlet_home_screen";
import InventoryHomeScreen from "../home/inventory_home_screen";
import CompletenessScreen from "../inventory_view/completeness/completeness_screen";
import TransferHistoryScreen from "../inventory_view/transfer_history/transfer_history_screen";
import UploadMapWeeklyCsvScreen from "../inventory_view/upload_map_csv/upload_map_csv_screen";
import VendorReceiptHistoryScreen from "../inventory_view/vendor_receipt_history/vendor_receipt_history_screen";
import MenuPackagingScreen from "../inventory_view/menu_packaging/menu_packaging_screen";
import DOIDashboardScreen from '../doi_live_stock_dashboard/doi_dashboard_screen';
import StockDashboardScreen from "../doi_live_stock_dashboard/stock_dashboard_screen";
import AllOutletStockScreen from "../doi_live_stock_dashboard/all_outlet_stock_dashboard";
import MasterItemDashboardScreen from "../inventory_view/master_item_dashboard/master_item_dashboard_screen";
import AdjustmentFormsHomeScreen from "../inventory_view/adjustment_forms/adjustment_forms_home_screen";
import AdjustmentFormsProductionScreen from "../inventory_view/adjustment_forms/production/adjustment_forms_production_screen";
import AdjustmentFormsSoScreen from "../inventory_view/adjustment_forms/so/adjustment_forms_so_screen";
import AdjustmentFormsWasteScreen from "../inventory_view/adjustment_forms/waste/adjustment_forms_waste_screen";
import AdjustmentFormsVendorReceiptScreen from "../inventory_view/adjustment_forms/vendor_receipt/adjustment_forms_vendor_receipt_screen";
import AdjustmentFormsTransferScreen from "../inventory_view/adjustment_forms/transfer/adjustment_forms_transfer_screen";
import AdjustmentFormsToVendorScreen from "../inventory_view/adjustment_forms/to_vendor/adjustment_forms_to_vendor_screen";
import BomDashboardScreen from "../inventory_view/new_bom_dashboard/bom_dashboard_screen";
import SoldOutLiveDashboardScreen from "../sold_out_live_dashboard/sold_out_live_dashboard_screen";

export class UenaRouter extends React.Component {
    render() {
        return(
            <Router>
                <div className="App">
                    <Routes>
                        {
                            (process.env.REACT_APP_ENV === "staging" 
                                || process.env.REACT_APP_ENV === "production"
                                || process.env.REACT_APP_ENV === "local"
                            ) &&
                            <>
                                <Route path="/menu-packaging" element={<MenuPackagingScreen />} />
                                <Route path="/transfer-history" element={<TransferHistoryScreen />} />
                                <Route path="/completeness-so-waste" element={<CompletenessScreen />} />
                                <Route path="/outlet_queue" element={<OutletQueuePreviewScreen />} />
                                <Route path="/outlet_order" element={<OutletOrderPreviewScreen />} />
                                <Route path="/homepage" element={<UenaWebsiteScreen />} />
                                <Route path="/adhoc-crm" element={<AdhocCrmScreen />} />
                                <Route path="/crm" element={<CrmHomeScreen />} />
                                <Route path="/completion-order" element={<CompletionOrderScreen />} />
                                <Route path="/create/order" element={<CreateOrderScreen />} />
                                <Route path="/create/order-cashier" element={<CashierOrderScreen />} />
                                <Route path="/download" element={<DownloadAppScreen />} />
                                <Route path="/notif" element={<NotificationWebDisplayScreen />} />
                                <Route path="/notification-web-form" element={<NotificationWebFormScreen />}/>
                                <Route path="/qris" element={<MobileQrisScreen />} />
                                <Route path="/redirect" element={<WebRedirectDisplayScreen />} />
                                <Route path="/redirect-form" element={<WebRedirectFormScreen />} />
                                <Route path="/referral-detail" element={<ReferralScreen />} />
                                <Route path="/service-area" element={<ServiceAreasMapScreen />} />
                                <Route path="/so-view" element={<SoScreen />} />
                                <Route path="/waste-view" element={<WasteScreen />} />
                                <Route path="/" element={<HomeScreen />} />
                                <Route path="/*" element={<HomeScreen />} />
                                <Route path="/delete_account" element={<UenaDeleteAccountScreen/> } />
                                <Route path="/receipt-history" element={<VendorReceiptHistoryScreen />} />
                                <Route path="/map-csv/upload" element={<UploadMapWeeklyCsvScreen />} />
                                <Route path="/bom" element={<BomDashboardScreen />} />
                                <Route path="/master-item" element={<MasterItemDashboardScreen />} />
                                <Route path="/vendor-history" element={<UenaDeleteAccountScreen/> } />
                                <Route path="/dashboard-doi" element={<DOIDashboardScreen />} />
                                <Route path="/dashboard-stock" element={<StockDashboardScreen/>} />
                                <Route path="/all-outlet-stock" element={<AllOutletStockScreen/>} />
                                <Route path="/adjustment-forms" element={<AdjustmentFormsHomeScreen />} />
                                <Route path="/adjustment-forms/production" element={<AdjustmentFormsProductionScreen />} />
                                <Route path="/adjustment-forms/so" element={<AdjustmentFormsSoScreen />} />
                                <Route path="/adjustment-forms/waste" element={<AdjustmentFormsWasteScreen />} />
                                <Route path="/adjustment-forms/vendor-receipt" element={<AdjustmentFormsVendorReceiptScreen />} />
                                <Route path="/adjustment-forms/transfer" element={<AdjustmentFormsTransferScreen />} />
                                <Route path="/adjustment-forms/to-vendor" element={<AdjustmentFormsToVendorScreen />} />
                                <Route path="/dashboard_sold_out" element={<SoldOutLiveDashboardScreen />} />
                            </>
                        }
                        {
                            (process.env.REACT_APP_ENV === "driver" || process.env.REACT_APP_ENV === "driver-staging") &&
                            <>
                                <Route path="/order_detail" element={<DriverOrderDetailScreen />} />
                                <Route path="/order_list" element={<DriverOrderListScreen />} />
                                <Route path="/sign_in" element={<DriverSignInScreen />} />
                                <Route path="/" element={<DriverRootScreen />} />
                                <Route path="/*" element={<DriverRootScreen />} />
                            </>
                        }
                        {
                            process.env.REACT_APP_ENV === "landing" &&
                            <>
                                <Route path="/download" element={<DownloadAppScreen />} />
                                <Route path="/notif" element={<NotificationWebDisplayScreen />} />
                                <Route path="/qris" element={<MobileQrisScreen />} />
                                <Route path="/redirect" element={<WebRedirectDisplayScreen />} />
                                <Route path="/referral-detail" element={<ReferralScreen />} />
                                <Route path="/terms-and-condition" element={<TncScreen />} />
                                <Route path="/home" element={<UenaWebsiteScreen />} />
                                <Route path="/tnc" element={<TncScreen />} />
                                <Route path="/franchise" element={<FranchiseLandingScreen />}/>
                                <Route path="/" element={<UenaWebsiteScreen />} />
                                <Route path="/*" element={<UenaWebsiteScreen />} />
                                <Route path="/delete_account" element={<UenaDeleteAccountScreen/>} />
                            </>
                        }
                        {
                            (process.env.REACT_APP_ENV === "order" || process.env.REACT_APP_ENV === "order-staging") &&
                            <>
                                <Route path="/outlet_queue" element={<OutletQueuePreviewScreen />} />
                                {/* <Route path="/create/order" element={<CreateOrderScreen />} /> */}
                                {/* <Route path="/create/order-cashier" element={<CashierOrderScreen />} /> */}
                                <Route path="/gerobak" element={<GerobakOrderScreen />} />
                                <Route path="/service-area" element={<ServiceAreasMapScreen />} />
                                <Route path="/completion-order" element={<CompletionOrderScreen />} />
                                {/* <Route path="/so-view" element={<SoScreen />} />
                                <Route path="/waste-view" element={<WasteScreen />} /> */}
                                <Route path="/" element={<HomeScreen />} />
                                <Route path="/*" element={<HomeScreen />} />
                                <Route path="/dashboard_sold_out" element={<SoldOutLiveDashboardScreen />} />
                            </>
                        }
                        {
                            (process.env.REACT_APP_ENV === "outlet" || process.env.REACT_APP_ENV === "outlet-staging") &&
                            <>
                                <Route path="/outlet_queue" element={<OutletQueuePreviewScreen />} />
                                <Route path="/outlet_order" element={<OutletOrderPreviewScreen />} />
                                <Route path="/" element={<OutletHomeScreen />} />
                                <Route path="/*" element={<OutletHomeScreen />} />
                            </>
                        }
                        {
                            (process.env.REACT_APP_ENV === "inventory" || process.env.REACT_APP_ENV === "inventory-staging") &&
                            <>
                                <Route path="/menu-packaging" element={<MenuPackagingScreen />} />
                                <Route path="/transfer-history" element={<TransferHistoryScreen />} />
                                <Route path="/completeness-so-waste" element={<CompletenessScreen />} />
                                <Route path="/so-view" element={<SoScreen />} />
                                <Route path="/waste-view" element={<WasteScreen />} />
                                <Route path="/map-csv/upload" element={<UploadMapWeeklyCsvScreen />} />
                                <Route path="/bom" element={<BomDashboardScreen />} />
                                <Route path="/master-item" element={<MasterItemDashboardScreen />} />
                                <Route path="/receipt-history" element={<VendorReceiptHistoryScreen />} />
                                <Route path="/dashboard-doi" element={<DOIDashboardScreen />} />
                                <Route path="/dashboard-stock" element={<StockDashboardScreen/>} />
                                <Route path="/all-outlet-stock" element={<AllOutletStockScreen/>} />
                                <Route path="/adjustment-forms" element={<AdjustmentFormsHomeScreen/>} />
                                <Route path="/adjustment-forms/production" element={<AdjustmentFormsProductionScreen/>} />
                                <Route path="/adjustment-forms/so" element={<AdjustmentFormsSoScreen/>} />
                                <Route path="/adjustment-forms/waste" element={<AdjustmentFormsWasteScreen/>} />
                                <Route path="/adjustment-forms/vendor-receipt" element={<AdjustmentFormsVendorReceiptScreen/>} />
                                <Route path="/adjustment-forms/transfer" element={<AdjustmentFormsTransferScreen/>} />
                                <Route path="/adjustment-forms/to-vendor" element={<AdjustmentFormsToVendorScreen />} />
                                <Route path="/" element={<InventoryHomeScreen />} />
                                <Route path="/*" element={<InventoryHomeScreen />} />
                            </>
                        }
                        {
                            process.env.REACT_APP_ENV === "crm" &&
                            <>
                                <Route path="/adhoc-crm" element={<AdhocCrmScreen />} />
                                <Route path="/notification-web-form" element={<NotificationWebFormScreen />}/>
                                <Route path="/redirect-form" element={<WebRedirectFormScreen />} />
                                <Route path="/" element={<CrmHomeScreen />} />
                                <Route path="/*" element={<CrmHomeScreen />} />
                                <Route path="/redirect" element={<WebRedirectDisplayScreen />} />
                                <Route path="/notif" element={<NotificationWebDisplayScreen />} />
                            </>
                        }
                    </Routes>
                </div>
            </Router>
        );
    }
}