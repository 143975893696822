import React from "react";
import { VMProvider } from "../../../commons/base/providers/provider";
import AdjustmentFormsTransferViewModel from "./view_models/adjustment_forms_transfer_view_model";
import Scaffold from "../../../commons/scaffold/scaffold";
import AdjustmentFormsTransferTableWidget from "./widgets/adjustment_forms_transfer_table_widget";
import AdjustmentFormsTransferFilterWidget from "./widgets/adjustment_forms_transfer_filter_widget";
import AdjustmentFormsTransferModalWidget from "./widgets/adjustment_forms_transfer_modal_widget";

export default class AdjustmentFormsTransferScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new AdjustmentFormsTransferViewModel()}>
                {(vm) => (
                    <Scaffold
                        isShowLoading={vm.isLoading}
                        loadingBackgroundType="semi_transparent"
                        showSnackbar={vm.showSnackbar}
                        showErrorView={vm.showErrorView}
                    >
                        <div className=" bg-slate-500 overflow-x-auto min-h-screen p-5 mt-10 relative">
                            <h1 className="text-white text-3xl text-center font-bold flex-grow m-5">
                                Adjust Form Transfer
                            </h1>

                            {vm.isShowModal && (
                                <AdjustmentFormsTransferModalWidget />
                            )}

                            <AdjustmentFormsTransferFilterWidget
                                onChange={(selectedOption) => {
                                    vm.setFilterSenderLocationId(
                                        selectedOption?.value!
                                    );
                                    vm.fetchTransferList({
                                        senderLocationId:
                                            vm.filterSenderLocationId,
                                    });
                                }}
                            />

                            <AdjustmentFormsTransferTableWidget />
                        </div>
                    </Scaffold>
                )}
            </VMProvider>
        );
    }
}
