import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import BomDashboardViewModel from "../view_models/bom_dashboard_view_model";
import StringUtils from "../../../commons/utils/string_utils";
import Select from "react-select";

export default class BomDashboardFilterWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<BomDashboardViewModel>>
                {(vm) => (
                    <div className="flex flex-wrap items-center mb-5 space-y-3 md:space-y-0">
                        {/* Dropdown select */}
                        <div className="relative mr-2 w-full md:w-1/4">
                            <Select
                                placeholder={"Pilih Tipe Hasil"}
                                options={vm.materialTypes.map((category) => ({
                                    label: StringUtils.snakeCaseToNormal(
                                        category
                                    ),
                                    value: category,
                                }))}
                                onChange={(e) => {
                                    vm.updateTableMaterialType(e?.value);
                                }}
                                isClearable
                                isSearchable
                            />
                        </div>

                        {/* Keyword input */}
                        <div className="relative flex-grow mr-2 w-full md:w-1/2">
                            <input
                                type="text"
                                placeholder="Cari dengan Nama / Material Code"
                                className="px-2 h-9 pr-8 mx-2 block appearance-none w-full bg-white border border-gray-300 rounded-lg shadow leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => {
                                    vm.setFilterKeyword(e.target.value);
                                }}
                                value={vm.filterKeyword}
                            />
                        </div>

                        {/* Search button */}
                        <div className="relative ml-2 w-full md:w-auto">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg w-full md:w-auto"
                                onClick={() => vm.fetchResultList()}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
