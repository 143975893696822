import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsToVendorViewModel from "../view_models/adjustment_forms_to_vendor_view_model";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default class AdjustmentFormsToVendorModalWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsToVendorViewModel>>
                {(vm) => (
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 pt-4 px-4"
                        onClick={() => {
                            vm.resetModal();
                        }}
                    >
                        <div
                            className="flex flex-col bg-white rounded-lg shadow-lg w-full max-w-3xl h-[85vh] overflow-y-auto relative"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {/* Sticky div for date, sender, and receiver */}
                            <div className="sticky top-0 bg-white pt-6 px-6 pb-3 shadow-md">
                                <h2 className="text-2xl font-bold mb-6">
                                    Adjust Pengiriman Barang ke Vendor
                                </h2>

                                {/* Search Bar */}
                                <div className="relative mb-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Cari
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        className="w-full border border-gray-300 rounded-lg mt-1 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        onChange={(e) => {
                                            const search =
                                                e.target.value.toLowerCase();
                                            vm.setSearchQuery(search);
                                        }}
                                        value={vm.searchQuery}
                                    />
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className="absolute right-3 top-2/3 transform -translate-y-1/2 text-gray-400"
                                    />
                                </div>

                                <div className="flex w-full space-x-4 items-center">
                                    {/* Date */}
                                    <div className="w-1/3">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Pilih Tanggal
                                        </label>
                                        <input
                                            type="datetime-local"
                                            id="date"
                                            name="date"
                                            className="block w-full mt-1 px-4 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm appearance-none"
                                            onChange={(e) => {
                                                const date = new Date(
                                                    e.target.value
                                                );
                                                vm.setFormSentAt(
                                                    date.toISOString()
                                                );
                                            }}
                                            value={
                                                vm.formSentAt
                                                    ? new Date(vm.formSentAt)
                                                          .toISOString()
                                                          .slice(0, 16)
                                                    : ""
                                            }
                                        />
                                    </div>

                                    {/* Location From */}
                                    <div className="w-1/3">
                                        <p className="text-center">Pengirim</p>
                                        <Select
                                            options={vm.locationOptions.map(
                                                (option) => ({
                                                    value: option.locationId,
                                                    label: option.locationName,
                                                })
                                            )}
                                            placeholder={"Pilih Lokasi"}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(selectedOption) => {
                                                vm.setNewFromLocationId(
                                                    selectedOption?.value
                                                );
                                            }}
                                            value={vm.locationOptions
                                                .map((option) => ({
                                                    value: option.locationId,
                                                    label: option.locationName,
                                                }))
                                                .find(
                                                    (option) =>
                                                        option.value ===
                                                        vm.newFromLocationId
                                                )}
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    </div>

                                    {/* Location To */}
                                    <div className="w-1/3">
                                        <p className="text-center">Penerima</p>
                                        <Select
                                            options={vm.vendorsOptions.map(
                                                (option) => ({
                                                    value: option.locationId,
                                                    label: option.locationName,
                                                })
                                            )}
                                            placeholder={"Pilih Lokasi"}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(selectedOption) => {
                                                vm.setNewToLocationId(
                                                    selectedOption?.value
                                                );
                                            }}
                                            value={vm.vendorsOptions
                                                .map((option) => ({
                                                    value: option.locationId,
                                                    label: option.locationName,
                                                }))
                                                .find(
                                                    (option) =>
                                                        option.value ===
                                                        vm.newToLocationId
                                                )}
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Mapping data */}
                            <div className="flex-grow space-y-4 px-6 mt-3">
                                {[...vm.transferToVendorDataMap.values()]
                                    .filter((item) =>
                                        item.itemName
                                            ?.toLowerCase()
                                            .includes(vm.searchQuery)
                                    )
                                    .map((item) => (
                                        <div
                                            key={item.itemId}
                                            className="grid grid-cols-12 gap-4 items-center border-b pb-4"
                                        >
                                            {/* Item Name */}
                                            <span className="col-span-4 text-gray-700 font-semibold">
                                                {item.itemName}
                                            </span>

                                            {/* Big Qty */}
                                            <div className="col-span-4">
                                                <label className="block text-sm text-gray-600">
                                                    Satuan Besar{" "}
                                                    {item.bigUnit
                                                        ? `(${item.bigUnit})`
                                                        : ""}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={item.bigQty}
                                                    placeholder="Big Qty"
                                                    className="w-full border rounded-lg px-3 py-2 mt-1"
                                                    onChange={(e) => {
                                                        vm.handleChangeItemData(
                                                            item.uniqueId,
                                                            "bigQty",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                    // Prevent negative
                                                    onInput={(e) => {
                                                        const target =
                                                            e.target as HTMLInputElement;
                                                        if (
                                                            target.value.startsWith(
                                                                "0"
                                                            ) &&
                                                            target.value
                                                                .length > 1
                                                        ) {
                                                            target.value =
                                                                target.value.replace(
                                                                    /^0+/,
                                                                    ""
                                                                );
                                                        }
                                                    }}
                                                />
                                            </div>

                                            {/* Small Qty */}
                                            <div className="col-span-4">
                                                <label className="block text-sm text-gray-600">
                                                    Satuan Kecil{" "}
                                                    {item.smallUnit
                                                        ? `(${item.smallUnit})`
                                                        : ""}
                                                </label>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={item.smallQty}
                                                    placeholder="Small Qty"
                                                    className="w-full border rounded-lg px-3 py-2 mt-1"
                                                    onChange={(e) => {
                                                        vm.handleChangeItemData(
                                                            item.uniqueId,
                                                            "smallQty",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                    // Prevent negative
                                                    onInput={(e) => {
                                                        const target =
                                                            e.target as HTMLInputElement;
                                                        if (
                                                            target.value.startsWith(
                                                                "0"
                                                            ) &&
                                                            target.value
                                                                .length > 1
                                                        ) {
                                                            target.value =
                                                                target.value.replace(
                                                                    /^0+/,
                                                                    ""
                                                                );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            {/* Select Item & Add Btn */}
                            <div className="mt-6 sticky bottom-0 bg-white px-4 py-6 shadow-inner">
                                <div className="w-full flex space-x-4 items-center">
                                    <div className="flex-grow">
                                        <Select
                                            className="basic-single w-full"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="item"
                                            options={vm.itemOptions
                                                .filter(
                                                    (option) =>
                                                        !vm.addedItemIds.includes(
                                                            option.id
                                                        )
                                                )
                                                .map((option) => ({
                                                    value: option.id,
                                                    label: option.item,
                                                }))}
                                            onChange={(selectedOption) => {
                                                vm.setSelectedItemId(
                                                    selectedOption?.value
                                                );
                                            }}
                                            placeholder="Tambah Item ?"
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    </div>

                                    <button
                                        onClick={() => {
                                            if (vm.selectedItemId) {
                                                vm.handleAddItem(
                                                    vm.selectedItemId
                                                );
                                            }
                                        }}
                                        disabled={!vm.selectedItemId}
                                        className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 disabled:bg-gray-500"
                                    >
                                        Masukkan
                                    </button>
                                </div>

                                {/* Submit Btn */}
                                <div className="mt-4">
                                    <button
                                        onClick={() => vm.handleSubmit()} // Handle the form submission here
                                        className="w-full bg-green-500 text-white px-4 py-3 rounded-lg hover:bg-green-600"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
