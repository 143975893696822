import React from "react";
import { VMProvider } from "../commons/base/providers/provider";
import SoldOutLiveDashboardViewModel from "./view_models/sold_out_live_dashboard_view_model";
import Scaffold from "../commons/scaffold/scaffold";
import SizedDiv from "../commons/ui/sized_div";
import SoldOutCountPerLocationWidget from "./widgets/sold_out_count_per_location_widget";
import SoldOutTableWidget from "./widgets/sold_out_table_widget";
import SoldOutModalWidget from "./widgets/sold_out_modal_widget";
import SoldOutOtherMenuTableWidget from "./widgets/sold_out_other_menu_table_widget";
import CheckFormWidget from "../commons/ui/checkbox_form/check_form_widget";

export default class SoldOutLiveDashboardScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new SoldOutLiveDashboardViewModel()}>
                {(vm) => (
                    <Scaffold
                        showSnackbar={vm.showSnackbarModel}
                        isShowLoading={
                            vm.filteredPrioritySoldOutData.length === 0 &&
                            vm.filteredOtherSoldOutData.length === 0
                        }
                    >
                        <div className=" bg-slate-500 overflow-x-auto min-h-screen p-5 mt-7 relative">
                            {vm.isShowingModal && (
                                <SoldOutModalWidget
                                    soldoutLocationCountData={
                                        vm.soldOutLocationCountMap
                                    }
                                    onClose={() => vm.setIsShowingModal(false)}
                                />
                            )}

                            <h1 className="text-white text-3xl text-center font-bold flex-grow m-5">
                                Sold Out Live Dashboard
                            </h1>

                            {vm.isConnectionErrorOccured && (
                                <h1 className="text-red-500 text-3xl text-center font-bold">
                                    KONEKSI INTERNET SEMPAT TERPUTUS, HARAP
                                    REFRESH HALAMAN INI
                                </h1>
                            )}

                            {/* SOLD OUT TABLE SECTION */}
                            <SoldOutTableWidget
                                soldOutData={vm.filteredPrioritySoldOutData}
                            />

                            <SizedDiv height={16} />

                            {/* OTHER MENUS SECTION */}
                            <CheckFormWidget
                                label="Tampilkan Menu Lainnya"
                                isSelected={vm.isShowingOtherMenus}
                                onChanged={(value) =>
                                    vm.setIsShowingOtherMenus(value)
                                }
                            />

                            {vm.isShowingOtherMenus && (
                                <SoldOutOtherMenuTableWidget
                                    soldOutData={vm.filteredOtherSoldOutData}
                                />
                            )}

                            <SizedDiv height={16} />

                            {/* SUMMARY SOLD OUT PER LOCATION SECTION */}
                            <h1 className="text-white text-3xl text-center font-bold flex-grow">
                                Jumlah Lokasi setiap Menu Sold Out
                            </h1>
                            <SizedDiv height={8} />
                            <SoldOutCountPerLocationWidget
                                soldOutCountPerLocationData={
                                    vm.soldOutLocationCountMap
                                }
                            />

                            {/* SHOW POP UP SUMMARY BUTTON */}
                            <div className="fixed bottom-4 right-4">
                                <button
                                    className="px-4 py-4 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                    onClick={() => vm.setIsShowingModal(true)}
                                >
                                    Summary
                                </button>
                            </div>
                        </div>
                    </Scaffold>
                )}
            </VMProvider>
        );
    }
}
