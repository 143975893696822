import TransferHistoryViewModel from "./view_models/transfer_history_view_model";
import LoadingStateView from "../../commons/ui/loading/loading_state_view";
import ErrorStateView from "../../commons/ui/error/error_state_view";
import React from "react";
import { VMProvider } from "../../commons/base/providers/provider";
import TransferHistoryWidget from "./widgets/transfer_history_widget";

export default class TransferHistoryScreen extends React.Component {
    render() {
        return (
            <VMProvider create={() => new TransferHistoryViewModel()}>
                {(vm) => {
                    if (vm.isLoading) {
                        return (
                            <LoadingStateView backgroundType="semi_transparent" />
                        );
                    } else if (vm.errorMessage?.length > 0) {
                        return (
                            <ErrorStateView
                                message={vm.errorMessage}
                                action={() => vm.retryAction()}
                            />
                        );
                    }

                    return <TransferHistoryWidget />;
                }}
            </VMProvider>
        );
    }
}
