import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import BomDashboardViewModel from "../view_models/bom_dashboard_view_model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faHourglass1, faX } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import StringUtils from "../../../commons/utils/string_utils";

export default class BomDashboardModalWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<BomDashboardViewModel>>
                {(vm) => (
                    // Dark BG
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 max-h-screen p-2">
                        {/* Modal */}
                        <div className="relative bg-white rounded-lg shadow-lg mt-10 pt-5 px-5 w-full max-w-4xl max-h-[83vh] overflow-y-auto">
                            <button
                                className="text-red-500 absolute right-3 top-3"
                                onClick={() => vm.closeModal()}
                            >
                                <FontAwesomeIcon icon={faX} />
                            </button>

                            <div className="mb-4">
                                {/* If doing CREATE, show result type, result options, and result yield */}
                                {vm.formAction === "CREATE" && (
                                    <div className="grid grid-cols-3 gap-4">
                                        <div>
                                            <label>Tipe Hasil</label>
                                            <Select
                                                placeholder="Pilih Tipe Hasil"
                                                className="m-2"
                                                options={vm.materialTypes.map(
                                                    (type) => ({
                                                        label: StringUtils.snakeCaseToNormal(
                                                            type
                                                        ),
                                                        value: type,
                                                    })
                                                )}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption?.value) {
                                                        vm.updateModalOptionsByMaterialType(
                                                            selectedOption.value
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <label className="mt-2">
                                                Hasil
                                            </label>
                                            <Select
                                                placeholder="Pilih Hasil"
                                                className="m-2"
                                                isDisabled={
                                                    !vm.formResultMaterialType
                                                }
                                                options={vm.resultItemOptions}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption?.value) {
                                                        vm.onSelectResultItem(
                                                            selectedOption.value
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            {/* Yield */}
                                            <label className="mt-2">
                                                Yield
                                            </label>
                                            <input
                                                type="number"
                                                min={0}
                                                value={vm.formResultYield}
                                                placeholder="Yield"
                                                className="w-full border rounded-lg px-3 py-2 mt-1"
                                                onChange={(e) => {
                                                    vm.setFormResultYield(
                                                        Number(e.target.value)
                                                    );
                                                }}
                                                onInput={(e) => {
                                                    const target =
                                                        e.target as HTMLInputElement;
                                                    if (
                                                        target.value.startsWith(
                                                            "0"
                                                        ) &&
                                                        target.value.length > 1
                                                    ) {
                                                        target.value =
                                                            target.value.replace(
                                                                /^0+/,
                                                                ""
                                                            );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* If doing UPDATE, show result item & yield */}
                                {vm.formAction === "UPDATE" && (
                                    <>
                                        <div>
                                            <h1 className="text-lg font-bold mt-3 mb-1">
                                                {" "}
                                                Hasil :{" "}
                                                {vm.masterItemMap.get(
                                                    vm.formResultItemId ?? 0
                                                )?.item ?? ""}
                                            </h1>
                                        </div>

                                        <div className="grid grid-cols-2 gap-4 items-center">
                                            <div className="flex items-center mt-4">
                                                <label className="mt-2 mr-2">
                                                    Yield
                                                </label>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={vm.formResultYield}
                                                    placeholder="Yield"
                                                    className="w-full border rounded-lg px-3 py-2 mt-1"
                                                    disabled={!vm.formIsEditing}
                                                    onChange={(e) => {
                                                        vm.setFormResultYield(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                    onInput={(e) => {
                                                        const target =
                                                            e.target as HTMLInputElement;
                                                        if (
                                                            target.value.startsWith(
                                                                "0"
                                                            ) &&
                                                            target.value
                                                                .length > 1
                                                        ) {
                                                            target.value =
                                                                target.value.replace(
                                                                    /^0+/,
                                                                    ""
                                                                );
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="flex items-center mt-4">
                                                <label className="mr-2">
                                                    Mode:
                                                </label>
                                                <button
                                                    className={`flex items-center px-4 py-2 rounded-lg w-full justify-center ${
                                                        vm.formIsEditing
                                                            ? "bg-blue-500 text-white"
                                                            : "bg-gray-300 text-black"
                                                    }`}
                                                    onClick={() =>
                                                        vm.setFormIsEditing(
                                                            !vm.formIsEditing
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            vm.formIsEditing
                                                                ? faHourglass1
                                                                : faEye
                                                        }
                                                        className="mr-2"
                                                    />
                                                    {vm.formIsEditing
                                                        ? "Editing"
                                                        : "View Only"}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* BOM List */}
                            <div className="overflow-x-auto">
                                <table className="table-auto w-full text-center border-collapse my-2">
                                    <thead>
                                        <tr className="bg-gray-300">
                                            <th className="p-2 border border-gray-400 w-1/5">
                                                Material Code
                                            </th>
                                            <th className="p-2 border border-gray-400 w-1/5">
                                                Nama Item
                                            </th>
                                            <th className="p-2 border border-gray-400 w-1/5">
                                                BOM
                                            </th>
                                            <th className="p-2 border border-gray-400 w-1/5">
                                                Satuan
                                            </th>
                                            <th className="p-2 border border-gray-400 w-1/5">
                                                Aktif
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...vm.formBomMap.values()].length >
                                            0 &&
                                            [...vm.formBomMap.values()].map(
                                                (bom) => (
                                                    <tr
                                                        className={
                                                            vm.formIsEditing
                                                                ? "bg-white"
                                                                : "bg-gray-100"
                                                        }
                                                    >
                                                        <td className="p-2 border border-gray-400">
                                                            {bom.materialCode}
                                                        </td>
                                                        <td className="p-2 border border-gray-400">
                                                            {bom.masterItemName}
                                                        </td>
                                                        {/* Bom */}
                                                        <td className="px-10 py-2 first-line:p-2 border border-gray-400">
                                                            <input
                                                                type="number"
                                                                min={0}
                                                                value={bom.bom}
                                                                placeholder="BOM"
                                                                className="w-full border rounded-lg p-2 mt-1 text-center"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    vm.handleChangeFormBom(
                                                                        bom.uniqueId,
                                                                        "bom",
                                                                        Number(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    );
                                                                }}
                                                                // Prevent negative
                                                                onInput={(
                                                                    e
                                                                ) => {
                                                                    const target =
                                                                        e.target as HTMLInputElement;
                                                                    if (
                                                                        target.value.startsWith(
                                                                            "0"
                                                                        ) &&
                                                                        target
                                                                            .value
                                                                            .length >
                                                                            1
                                                                    ) {
                                                                        target.value =
                                                                            target.value.replace(
                                                                                /^0+/,
                                                                                ""
                                                                            );
                                                                    }
                                                                }}
                                                                disabled={
                                                                    !vm.formIsEditing
                                                                }
                                                            />
                                                        </td>
                                                        <td className="p-2 border border-gray-400">
                                                            {bom.smallUnit}
                                                        </td>
                                                        {/* Is Active */}
                                                        <td className="p-2 border border-gray-400">
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    bom.isActive
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    vm.handleChangeFormBom(
                                                                        bom.uniqueId,
                                                                        "isActive",
                                                                        e.target
                                                                            .checked
                                                                    );
                                                                }}
                                                                className="form-checkbox h-5 w-5 text-green-500"
                                                                disabled={
                                                                    !vm.formIsEditing
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        {[...vm.formBomMap.values()].length <
                                            1 && (
                                            <tr>
                                                <td
                                                    colSpan={6}
                                                    className="text-center p-4 border border-gray-400"
                                                >
                                                    BOM belum diisi
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {/* Footer with item options & save button */}
                            <div className="mt-6 sticky bottom-0 bg-white px-4 py-6 shadow-inner">
                                <div className="w-full flex space-x-4 items-center">
                                    <div className="flex-grow">
                                        <Select
                                            placeholder="Tambah Item ?"
                                            className="basic-single w-full"
                                            classNamePrefix="select"
                                            isClearable
                                            isSearchable
                                            name="item"
                                            isDisabled={!vm.formIsEditing}
                                            options={vm.materialItemOptions}
                                            onChange={(selectedOption) =>
                                                vm.setFormSelecteAddItemId(
                                                    selectedOption?.value
                                                )
                                            }
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    </div>

                                    <button
                                        onClick={() => {
                                            if (vm.formSelectedAddItemId) {
                                                vm.handleAddFormBom(
                                                    vm.formSelectedAddItemId
                                                );
                                            }
                                        }}
                                        className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 disabled:bg-gray-500"
                                        disabled={!vm.formSelectedAddItemId || !vm.formIsEditing}
                                    >
                                        Masukkan
                                    </button>
                                </div>
                                <div className="mt-4">
                                    <button
                                        className="bg-green-500 disabled:bg-gray-400 text-white px-6 py-2 rounded-lg hover:bg-green-600 w-full"
                                        disabled={!vm.formIsEditing}
                                        onClick={() => vm.handleSubmit()}
                                    >
                                        Simpan Data
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
