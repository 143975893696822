import React from "react";
import { VMBuilder } from "../../../commons/base/providers/builder";
import BomDashboardViewModel from "../view_models/bom_dashboard_view_model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

export default class BomDashboardTableWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<BomDashboardViewModel>>
                {(vm) => (
                    <div className="overflow-x-auto">
                        <table className="table-fixed w-full text-center border-collapse">
                            <thead>
                                <tr className="bg-gray-300">
                                    <th className="p-2 border border-gray-400 w-1/12">
                                        Material Code
                                    </th>
                                    <th className="p-2 border border-gray-400 w-1/12">
                                        Nama
                                    </th>
                                    <th className="p-2 border border-gray-400 w-1/12">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {vm.resultItemList.map((item) => (
                                    <tr className="bg-white hover:bg-gray-100 transition">
                                        <td className="p-2 border border-gray-400">
                                            {item.materialCode}
                                        </td>
                                        <td className="p-2 border border-gray-400">
                                            {item.masterItemName}
                                        </td>
                                        <td className="p-2 border border-gray-400">
                                            <button
                                                className="px-3 py-1 rounded-md bg-purple-500 hover:bg-purple-700 text-white"
                                                onClick={() =>
                                                    vm.onOpenModalForUpdate(
                                                        item.masterItemId,
                                                        item.masterItemYield
                                                    )
                                                }
                                            >
                                                Lihat BOM
                                                <FontAwesomeIcon
                                                    className="mx-3"
                                                    icon={faPencil}
                                                />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
