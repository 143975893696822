import ApiService from "../../commons/api/api_service";
import BaseResponse from "../../commons/api/base_response";
import { Endpoints } from "../../commons/api/endpoints";
import ViewModel from "../../commons/base/view_model";
import { ShowSnackbarModel } from "../../commons/scaffold/scaffold";

export interface OutletCompletionResponse {
    data: OutletCompletionInfoModel[];
}

export interface OutletCompletionInfoModel {
    outlet_name: string;
    kitchen_record_qty: string;
    driver_record_qty: string;
    max_time: string;
    to_customer: string;
    max_cooking_time: string;
}

export class CompletionState {
    errorSnackbar: ShowSnackbarModel | undefined;
    response: OutletCompletionResponse | undefined;
}

export default class CompletionViewModel extends ViewModel<CompletionState> {
    constructor() {
        super(new CompletionState());
        this.loadContent();
    }

    loadContent() {
        this.emit((state) => {
            state.errorSnackbar = undefined;
        });

        ApiService.fetch<{}, BaseResponse<OutletCompletionResponse>>(Endpoints.completionInfo, {
            onSuccess: (response) => {
                this.emit((state) => {
                    state.response = response.data;
                });

                setTimeout(() => this.loadContent(), 20000);
            },
            onFailure: (error, errorCode) => {
                this.emit((state) => {
                    state.errorSnackbar = new ShowSnackbarModel({
                        message: `${errorCode}: ${error}`,
                        type: "error",
                    });
                });

                setTimeout(() => this.loadContent(), 20000);
            },
        });
    }
}