import React from "react";
import { SoldOutLocationCountModel } from "../models/sold_out_model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import SoldOutCountPerLocationWidget from "./sold_out_count_per_location_widget";

interface Props {
    soldoutLocationCountData: SoldOutLocationCountModel[];
    onClose: () => void;
}

export default class SoldOutModalWidget extends React.Component<Props> {
    render(): React.ReactNode {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 max-h-screen p-2">
                <div className="relative bg-white rounded-lg shadow-lg mt-10 w-full max-w-4xl max-h-[83vh] overflow-y-auto p-2">
                    <button
                        className="text-red-500 absolute right-3 top-3"
                        onClick={() => this.props.onClose()}
                    >
                        <FontAwesomeIcon icon={faX} />
                    </button>
                    <h1 className="text-center font-bold flex-grow mb-2">
                        Jumlah Lokasi setiap Menu Sold Out
                    </h1>
                    <SoldOutCountPerLocationWidget
                        soldOutCountPerLocationData={
                            this.props.soldoutLocationCountData
                        }
                    />
                </div>
            </div>
        );
    }
}
