import "./home.css";

import React from "react";
import Scaffold from "../commons/scaffold/scaffold";
import { HomeFeatureButton } from "./widgets/home_feature_button";

export default class OutletHomeScreen extends React.Component {
    render() {
        return (
            <Scaffold>
                <div id="HomeScreenContainer">
                    <HomeFeatureButton
                        title="Nomor Antrian Outlet"
                        path="/outlet_queue"
                    />
                    <HomeFeatureButton
                        title="Preview Order Outlet"
                        path="/outlet_order"
                    />
                </div>
            </Scaffold>
        );
    }
}
