export default class LocationUtils {
    static convertToCoordinate(string: string): {
        lng: number;
        lat: number;
    }[] {
        return string.split(", ").map((value) => {
            const valueInArray = value.split(" ");

            return {
                lng: +valueInArray[0],
                lat: +valueInArray[1]
            }
        });
    }

    static calculateDistance(
        args: {
            lat1: number,
            lat2: number,
            long1: number,
            long2: number
        }
    ) {
        const R = 6371;
        const dLat = this._degToRad(args.lat2 - args.lat1);
        const dLong = this._degToRad(args.long2 - args.long1);
        const a = Math.sin(dLat / 2.0) * Math.sin(dLat / 2.0) +
            Math.cos(this._degToRad(args.lat1)) * Math.cos(this._degToRad(args.lat2)) *
            Math.sin(dLong / 2.0) * Math.sin(dLong / 2.0);
        const c = 2.0 * Math.atan2(Math.sqrt(a), Math.sqrt(1.0 - a));

        return R * c * 1000;
    }

    static _degToRad(deg: number) {
        return deg * (Math.PI / 180);
    }
}