import React from "react";
import { VMProvider } from "../commons/base/providers/provider";
import DOIDashboardVM from "./view_models/doi_dashboard_vm";
import DOITable from "./widgets/doi_table";
import Scaffold from "../commons/scaffold/scaffold";

export default class DOIDashboardScreen extends React.Component {
    
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new DOIDashboardVM()}>
                {(vm) => {
                    return (
                        <Scaffold
                        isShowLoading={vm.isLoading}
                        loadingBackgroundType="solid"
                        loadingBackgroundColor="#6B2B90"
                        loadingType="uena_logo"
                        showTopAppBar={false}>
                            <div className="p-4">
                                <h1 className="text-slate-800 text-3xl mb-5 p-4 bg-yellow-100 font-extrabold">ITEMS LOW DOI</h1>
                                <div className="bg-slate-50">
                                    <DOITable />
                                </div>
                            </div>
                        </Scaffold>
                    )
                }}
            </VMProvider>
        )
    }
}