import React from "react";
import { VMProvider } from "../../commons/base/providers/provider";
import Scaffold from "../../commons/scaffold/scaffold";
import BomDashboardViewModel from "./view_models/bom_dashboard_view_model";
import BomDashboardFilterWidget from "./widgets/bom_dashboard_filter_widget";
import BomDashboardTableWidget from "./widgets/bom_dashboard_table_widget";
import BomDashboardModalWidget from "./widgets/bom_dashboard_modal_widget";
import BomDashboardPaginationWidget from "./widgets/bom_dashboard_pagination_widget";

export default class BomDashboardScreen extends React.Component {
    render(): React.ReactNode {
        return (
            <VMProvider create={() => new BomDashboardViewModel()}>
                {(vm) => (
                    <Scaffold
                        isShowLoading={vm.isLoading}
                        loadingBackgroundType="semi_transparent"
                        showErrorView={vm.errorModel}
                        showSnackbar={vm.snackbarModel}
                    >
                        <div className=" bg-slate-500 overflow-x-auto min-h-screen p-5 mt-10 relative">
                            <h1 className="text-white text-3xl text-center font-bold flex-grow m-5">
                                Bill of Materials
                            </h1>
                            {vm.isShowModal && <BomDashboardModalWidget />}
                            <BomDashboardFilterWidget />
                            <BomDashboardTableWidget />
                            <BomDashboardPaginationWidget />
                            {/* Create New Button */}
                            <div className="fixed bottom-4 right-4">
                                <button
                                    className="px-4 py-4 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                    onClick={() => vm.onOpenModalForCreate()}
                                >
                                    Create New
                                </button>
                            </div>
                        </div>
                    </Scaffold>
                )}
            </VMProvider>
        );
    }
}
