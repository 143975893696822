import axios from "axios";
import { Endpoints } from "./endpoints";
import { DriverUtils } from "../../driver/driver_utils";

interface UploadImageResponse {
    url?: string;
    message: string;
}

export enum HttpMethod {
    get = "GET",
    post = "POST",
    put = "PUT",
    delete = "DELETE",
}

export default class ApiService {
    static get host() {
        switch (process.env.REACT_APP_ENV) {
            case "production":
            case "driver":
            case "order":
            case "crm":
            case "landing":
            case "outlet":
            case "inventory":
                return "https://bo-api.uenafood.com";
            case "staging":
            case "driver-staging":
            case "outlet-staging":
            case "inventory-staging":
                return "https://bo-api-staging.uenafood.com";
            case "order-staging":
                return "https://bo-api-staging.uenafood.com";
            case "local":
            default:
                return "https://localhost:8000";
        }
    }

    static driverApiFetching<P extends {}, R>(
        endpoint: Endpoints,
        args: {
            httpMethod?: HttpMethod;
            parameters?: P;
            onSuccess: (response: R) => void;
            onFailure: (error: any, errorCode?: number) => void;
        }
    ) {
        const signInValues = DriverUtils.getSignInValues();

        const onSuccess: (response: R) => void = (response) => {
            console.log(response);
            if ((response as any).isUserValid === false) {
                DriverUtils.logOut();
            } else {
                args.onSuccess(response);
            }
        };

        const driverId = (
            (args.parameters as any)?.driverId ?? signInValues?.driverId
        )?.toUpperCase();

        this.fetch(endpoint, {
            httpMethod: args.httpMethod,
            parameters: {
                ...args.parameters,
                driverId,
            },
            onSuccess,
            onFailure: args.onFailure,
        });
    }

    static fetch<P extends {}, R>(
        endpoint: Endpoints,
        args: {
            httpMethod?: HttpMethod;
            parameters?: P;
            onSuccess: (response: R) => void;
            onFailure: (error: any, errorCode?: number) => void;
        }
    ) {
        const timeOutMilis = 300000;
        const timeOutController = new AbortController();
        const timoOutControllerId = setTimeout(
            () => timeOutController.abort(),
            timeOutMilis
        );
        const body = JSON.stringify(args.parameters);

        try {
            fetch(`${this.host}${endpoint}`, {
                method: args.httpMethod ?? HttpMethod.post,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: body,
                signal: timeOutController.signal,
            })
                .then(
                    (response) => {
                        return response.json();
                    },
                    (error) => {
                        args.onFailure(error);
                        clearTimeout(timoOutControllerId);
                    }
                )
                .then(
                    (response) => {
                        args.onSuccess(response);
                    },
                    (error) => {
                        args.onFailure(error);
                        clearTimeout(timoOutControllerId);
                    }
                );
        } catch (error) {
            args.onFailure(error);
            clearTimeout(timoOutControllerId);
        }

        return timeOutController;
    }

    // static fetchWithQueryParams<P extends {}, R>(
    //     endpoint: Endpoints,
    //     args: {
    //         parameters: P
    //         onSuccess: (response: R) => void,
    //         onFailure: (error: any, errorCode?: number) => void
    //     }
    // ) {
    //     const timeOutMilis = 300000;
    //     const timeOutController = new AbortController();
    //     const timoOutControllerId = setTimeout(() => timeOutController.abort(), timeOutMilis);

    //     let queryString = "?";
    //     for (let [key, value] of Object.entries(args.parameters)) {
    //         queryString += `${key}=${value},`;
    //     }
    //     queryString = queryString.slice(0, -1);

    //     try {
    //         fetch(`${this.host}${endpoint}${queryString}`, {
    //             method: HttpMethod.get,
    //             headers: {
    //                 "Content-Type": "application/json; charset=utf-8"
    //             },
    //             signal: timeOutController.signal
    //         })
    //         .then(
    //             response => {
    //                 return response.json();
    //             },
    //             error => {
    //                 args.onFailure(error);
    //                 clearTimeout(timoOutControllerId);
    //             }
    //         )
    //         .then(
    //             response => {
    //                 args.onSuccess(response);
    //             },
    //             error => {
    //                 args.onFailure(error);
    //                 clearTimeout(timoOutControllerId);
    //             }
    //         )
    //     } catch (error) {
    //         args.onFailure(error);
    //         clearTimeout(timoOutControllerId)
    //     }

    //     return timeOutController;
    // }

    static uploadFile(
        endpoint: Endpoints,
        args: {
            file: File;
            bucketName: string;
            folderName: string;
            httpMethod?: HttpMethod;
            onSuccess: (response: UploadImageResponse) => void;
            onFailure: (error: any, errorCode?: number) => void;
        }
    ) {
        const timeOutMilis = 300000;
        const timeOutController = new AbortController();
        const timoOutControllerId = setTimeout(
            () => timeOutController.abort(),
            timeOutMilis
        );

        const formData = new FormData();

        formData.append("image", args.file);
        formData.append("name", args.file.name);
        formData.append("bucketName", args.bucketName);
        formData.append("folderName", args.folderName);

        try {
            axios
                .post(`${this.host}${endpoint}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(
                    (response) => args.onSuccess(response.data),
                    (error) => args.onFailure(error)
                );
        } catch (error) {
            args.onFailure(error);
            clearTimeout(timoOutControllerId);
        }
    }

    static uploadMapCsv<R>(
        endpoint: Endpoints,
        args: {
            data: {
                key: string;
                file: File | undefined;
                week: number;
                year: number;
            };
            onSuccess: (response: R) => void;
            onFailure: (error: any, errorCode?: number) => void;
        }
    ) {
        const timeOutMilis = 300000;
        const timeOutController = new AbortController();
        const timeOutControllerId = setTimeout(
            () => timeOutController.abort(),
            timeOutMilis
        );

        if (!args.data.file) {
            args.onFailure(new Error("No file selected"));
            return;
        }

        const formData = new FormData();
        formData.append(args.data.key, args.data.file);
        formData.append("week", args.data.week.toString());
        formData.append("year", args.data.year.toString());

        axios
            .post(`${this.host}${endpoint}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                signal: timeOutController.signal,
            })
            .then(
                (response) => args.onSuccess(response.data),
                (error) => args.onFailure(error)
            )
            .catch((error) => {
                clearTimeout(timeOutControllerId);
                args.onFailure(error);
            });
    }

    static fetchWithQueryParams<P extends {}, R, Q>(
        endpoint: Endpoints,
        args: {
            httpMethod?: HttpMethod;
            parameters?: P;
            queryParameters?: Q;
            onSuccess: (response: R) => void;
            onFailure: (error: any, errorCode?: number) => void;
        }
    ) {
        const timeOutMilis = 300000;
        const timeOutController = new AbortController();
        const timoOutControllerId = setTimeout(
            () => timeOutController.abort(),
            timeOutMilis
        );
        const body = JSON.stringify(args.parameters);
        const url = new URL(`${this.host}${endpoint}`);
        if (args.queryParameters) {
            url.search = new URLSearchParams(args.queryParameters).toString();
        }

        try {
            fetch(url, {
                method: args.httpMethod ?? HttpMethod.post,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: body,
                signal: timeOutController.signal,
            })
                .then(
                    (response) => {
                        return response.json();
                    },
                    (error) => {
                        args.onFailure(error);
                        clearTimeout(timoOutControllerId);
                    }
                )
                .then(
                    (response) => {
                        args.onSuccess(response);
                    },
                    (error) => {
                        args.onFailure(error);
                        clearTimeout(timoOutControllerId);
                    }
                );
        } catch (error) {
            args.onFailure(error);
            clearTimeout(timoOutControllerId);
        }

        return timeOutController;
    }
}
