import React from "react";
import OutletOrderPreviewViewModel from "../view_models/outlet_order_preview_view_model";
import { VMBuilder } from "../../../commons/base/providers/builder";

export default class OutletOrderOutletSelectorWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<OutletOrderPreviewViewModel>>
                {(vm) => (
                    <div className="pt-[50px] px-[24px] pb-[24px] overflow-y-scroll flex justify-center box-border">
                        <div className="max-w-[300px] w-[100%] flex flex-col gap-y-[10px] box-border">
                            <p className="mb-4 font-bold text-[20px]">
                                Pilih Outlet
                            </p>
                            {vm.outletList.map((value) => (
                                <button
                                    key={value}
                                    className="bg-[#6a4237] rounded-[10px] py-[8px] text-[white]"
                                    onClick={() => vm.selectOutlet(value)}
                                >
                                    {value}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
