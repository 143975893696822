import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsTransferViewModel from "../view_models/adjustment_forms_transfer_view_model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import StringUtils from "../../../../commons/utils/string_utils";

export default class AdjustmentFormsTransferTableWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsTransferViewModel>>
                {(vm) => (
                    <div className="overflow-x-auto">
                        <table className="table-fixed min-w-full border-collapse text-center">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-1.5 border border-gray-300 w-1/10">
                                        Kode Submit
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-3/12">
                                        Asal
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-3/12">
                                        Tujuan
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-3/12">
                                        Tanggal Dikirim
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {vm.filteredTransferList.map((data, index) => (
                                    <tr
                                        key={index}
                                        className="odd:bg-white even:bg-gray-100 cursor-pointer text-center hover:bg-slate-200"
                                        onClick={() => {
                                            vm.fetchTransferDetail(data.formId);
                                            vm.setFormReceivedAt(
                                                data.createdAt
                                            );
                                            vm.setIsShowModal(true);
                                        }}
                                    >
                                        <td className="p-2 border border-gray-300">
                                            {data.code ?? " - "}
                                        </td>
                                        <td className="p-2 border border-gray-300">
                                            <p className="font-bold text-center">
                                                {data.fromLocation}
                                            </p>
                                            <p className="text-center">
                                                {data.senderName}
                                            </p>
                                        </td>
                                        <td className="p-2 border border-gray-300">
                                            <p className="font-bold text-center">
                                                {data.toLocation}
                                            </p>
                                            <p className="text-center">
                                                {data.receiverName}
                                            </p>
                                        </td>
                                        <td className="p-2 border border-gray-300">
                                            {StringUtils.toIndonesianDate(
                                                new Date(data.createdAt)
                                            ) ?? " - "}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
