import React from "react";
import { SoldOutLocationCountModel } from "../models/sold_out_model";
import { MenuCategory, soldOutColors } from "../models/menu_category_enum";

interface Props {
    soldOutCountPerLocationData: SoldOutLocationCountModel[];
}

export default class SoldOutCountPerLocationWidget extends React.Component<Props> {
    render(): React.ReactNode {
        return (
            <div className="flex flex-wrap justify-start">
                {this.props.soldOutCountPerLocationData.map((value, index) => (
                    <span
                        key={index}
                        className="p-1 rounded m-1 text-xs"
                        style={{
                            backgroundColor:
                                soldOutColors[value.category as MenuCategory]
                                    ?.backgroundColor ?? "#FFFFFF",
                            color:
                                soldOutColors[value.category as MenuCategory]
                                    ?.textColor ?? "#000000",
                        }}
                    >
                        {value.name} : {value.count}
                    </span>
                ))}
            </div>
        );
    }
}
