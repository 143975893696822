import { ReactNode } from "react";
import { VMBuilder } from "../../commons/base/providers/builder";
import DOIDashboardVM from "../view_models/doi_dashboard_vm";
import React from "react";

const DOI_THRESHOLD = 2;
export default class DOITable extends React.Component {
    renderTable(vm: DOIDashboardVM) {
        const tableBodyStyle = "table-cell size-0 border border-slate-200 px-0 py-2";

        if (vm.data) {
            const rows = vm.data
            .map((row) => {
                row.itemsLowDOI = row.itemsLowDOI.filter(item => item.doi <= DOI_THRESHOLD);
                return row;
            })
            .sort((A, B) => A.itemsLowDOI.length > B.itemsLowDOI.length ? -1 : 1)
            .map((row, i) => {
                let lowDOISummary = `<div class="grid grid-cols-5 text-left ml-4">`;
                row.itemsLowDOI.forEach((item) => {
                    lowDOISummary += `
                        <div class="flex-row pr-10 pb-2">
                            <b>${item.itemName}</b>
                            <p class="text-red-500">${item.doi.toFixed(1)}</p>
                        </div>`;
                });
                lowDOISummary += `</div>`

                const outlet = row.location.label.replace(" ", "%20");

                return (
                    <div className="table-row" key={i}>
                        <div className={tableBodyStyle + " bg-slate-100 flex items-center justify-center"}>
                            <a className="text-xl block size-full" 
                            href={`/dashboard-stock?outlet=${outlet}`}>
                                {row.location.label}
                            </a>
                        </div>
                        <div className={tableBodyStyle} dangerouslySetInnerHTML={{__html: lowDOISummary}}></div>
                    </div>
                );
            });

            return (
                <div className="table-row-group">
                    {rows}
                </div>
            )
        }
    }

    render(): ReactNode {
        const tableHeadStyle = `table-cell size-auto border border-slate-400 
            px-0 py-2 bg-slate-700 text-slate-50
            font-bold text-xl`;
        return (
            <VMBuilder<DOIDashboardVM>>
                {(vm) => {
                    return (
                        <>
                            <div className="table-fixed text-slate-800 w-full border-2">
                                <div className="table-header-group">
                                    <div className={tableHeadStyle + " w-60"}>location</div>
                                    <div className={tableHeadStyle + " w-screen"}>Items with DOI &le; 2</div>
                                </div>
                                {this.renderTable(vm)}
                            </div>
                        </>
                    )
                }}
            </VMBuilder>
        );        
    }
}