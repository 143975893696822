import React from "react";
import { VMBuilder } from "../../../../commons/base/providers/builder";
import AdjustmentFormsSoViewModel from "../view_models/adjustment_forms_so_view_model";
import StringUtils from "../../../../commons/utils/string_utils";

export default class AdjustmentFormsSoTableWidget extends React.Component {
    render(): React.ReactNode {
        return (
            <VMBuilder<AdjustmentFormsSoViewModel>>
                {(vm) => (
                    <div className="overflow-x-auto">
                        <table className="table-fixed min-w-full border-collapse text-center">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-1.5 border border-gray-300 w-1/3">
                                        Lokasi & Nama Crew
                                    </th>
                                    <th className="p-1.5 border border-gray-300 w-1/3">
                                        Tanggal Submit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {vm.SODataList?.map((data, index) => (
                                    <tr
                                        key={index}
                                        className="odd:bg-white even:bg-gray-100 cursor-pointer text-center hover:bg-slate-200"
                                        onClick={() => {
                                            vm.fetchSODetail(data.formId);
                                            vm.setFormId(data.formId);
                                            vm.setFormLocationId(
                                                data.locationId
                                            );
                                            vm.setIsShowModal(true);
                                        }}
                                    >
                                        <td className="p-2 border border-gray-300">
                                            <p className="font-bold text-center">
                                                {data.location ?? " - "}
                                            </p>
                                            <p className="text-center">
                                                {data.respondentName ?? " - "}
                                            </p>
                                        </td>
                                        <td className="p-2 border border-gray-300">
                                            {StringUtils.toIndonesianDate(
                                                new Date(data.createdDate)
                                            ) ?? " - "}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </VMBuilder>
        );
    }
}
