import React from "react";
import { SoldOutPerOutletModel } from "../models/sold_out_model";
import { soldOutColors } from "../models/menu_category_enum";

interface Props {
    soldOutData: SoldOutPerOutletModel[];
}

export default class SoldOutTableWidget extends React.Component<Props> {
    render(): React.ReactNode {
        return (
            <div className="overflow-x-auto">
                <p className="sm:hidden text-white text-center">
                    Tabel bisa di-slide untuk layar kecil
                </p>
                <table className="table-fixed sm:min-w-full md:w-full text-center border-collapse">
                    <thead>
                        <tr className="bg-gray-300">
                            <th className="p-2 border border-gray-400 w-1/5 break-words">
                                OUTLET
                            </th>
                            <th className="p-2 border border-gray-400 w-2/5 break-words">
                                CHILI OIL
                            </th>
                            <th className="p-2 border border-gray-400 w-2/5 break-words">
                                B1G1
                            </th>
                            <th className="p-2 border border-gray-400 w-2/5 break-words">
                                GEPREK
                            </th>
                            <th className="p-2 border border-gray-400 w-2/5 break-words">
                                MAIN COURSE
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.soldOutData.map((data, index) => (
                            <tr
                                className="bg-white border border-gray-400 align-top"
                                key={index}
                            >
                                <td className="border border-gray-400">
                                    <span className="text-xs break-words">
                                        {data.outletName}
                                    </span>
                                </td>

                                {/* CHILI OIL */}
                                <td className="border border-gray-400 text-start">
                                    <div className="flex flex-wrap justify-start p-0.5">
                                        {data.chiliOilMenus.map((menu, i) => (
                                            <span
                                                key={`${i}-${menu}`}
                                                className="p-1 rounded m-1 text-xs"
                                                style={{
                                                    backgroundColor:
                                                        soldOutColors[
                                                            "CHILI OIL"
                                                        ].backgroundColor,
                                                    color: soldOutColors[
                                                        "CHILI OIL"
                                                    ].textColor,
                                                }}
                                            >
                                                {menu}
                                            </span>
                                        ))}
                                    </div>
                                </td>

                                {/* B1G1 */}
                                <td className="border border-gray-400 text-start">
                                    <div className="flex flex-wrap justify-start p-0.5">
                                        {data.b1g1Menus.map((menu, i) => (
                                            <span
                                                key={`${i}-${menu}`}
                                                className="p-1 rounded m-1 text-xs"
                                                style={{
                                                    backgroundColor:
                                                        soldOutColors.B1G1
                                                            .backgroundColor,
                                                    color: soldOutColors.B1G1
                                                        .textColor,
                                                }}
                                            >
                                                {menu}
                                            </span>
                                        ))}
                                    </div>
                                </td>

                                {/* GEPREK */}
                                <td className="border border-gray-400 text-start">
                                    <div className="flex flex-wrap justify-start p-0.5">
                                        {data.geprekMenus.map((menu, i) => (
                                            <span
                                                key={`${i}-${menu}`}
                                                className="p-1 rounded m-1 text-xs"
                                                style={{
                                                    backgroundColor:
                                                        soldOutColors.GEPREK
                                                            .backgroundColor,
                                                    color: soldOutColors.GEPREK
                                                        .textColor,
                                                }}
                                            >
                                                {menu}
                                            </span>
                                        ))}
                                    </div>
                                </td>

                                {/* MAIN COURSE */}
                                <td className="border border-gray-400 text-start">
                                    <div className="flex flex-wrap justify-start p-0.5">
                                        {data.mainCourseMenus.map((menu, i) => (
                                            <span
                                                key={`${i}-${menu}`}
                                                className="p-1 rounded m-1 text-xs"
                                                style={{
                                                    backgroundColor:
                                                        soldOutColors.MAIN
                                                            .backgroundColor,
                                                    color: soldOutColors.MAIN
                                                        .textColor,
                                                }}
                                            >
                                                {menu}
                                            </span>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
